import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vinca-app',
  templateUrl: './vinca-app.component.html',
  styleUrls: ['./vinca-app.component.css']
})
export class VincaAppComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
