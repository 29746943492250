<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb"> 
  <li><a href="#">Home</a> </li> 
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
   <li>Neuro-cognitive Theories</li> 
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Neuro-cognitive Theories
</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
  <div class="mycontent">
<p>Neurocognitive theories of autism were developed by researchers such as Frith, Prior, Rumsey, and Hermelin and O’Connor. They investigated cognitive abilities including perception, memory and language to find the correlation with the triad of impairments in autism. These theories can help in understanding an individual's neurodevelopmental profile by giving insight into the social and communication impairments of autism. It also explains how an individual might experience the world and respond in the way that they do when they have autism. </p>

<mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title>
       <div class="head3">Theory of Mind  
      </div>
     </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row">
        <div class="column" >
<p>Theory of mind refers to the person's ability to understand other people's mental states such as: beliefs, desires, intentions, imagination, emotions without them telling you about it. Every person has different mental states that influence their behavior. Knowledge of people's mental states allows other people to “explain and predict” behavior.  

  The difficulty in understanding others' minds is a core cognitive feature underlying the social and communication difficulties in autism. Understanding other's minds requires 'thinking about thoughts' which is made possible by language. Impaired theory of mind affects the ability to use language having: </p>

  <ul>
    <li>Perceptual words such as see or look</li> 

    <li> Emotional words: happy, sad, scared, hungry, tired, etc. </li> 
      
  <li>   Desire terms: “want” </li> 
      
<li>   Mind related terms: 'know' 'believe' and 'think'</li>
  </ul>

  <p>Children with autism are literal thinkers and may understand only the literal meaning of the language used to communicate and hence find it difficult to comprehend metaphors, lies, humor, jokes, sarcasm, perspective taking.  </p>

  
<p>
  "Sally-Anne Test” was implemented by various psychologists such as Simon Baron-Cohen, Alan M. Leslie, and Uta Frith, Leslie and Frith, to examine children's cognitive ability to understand that</p> 
  <ol>
    <li>
  people's beliefs are based on their knowledge,</li>
<li> that mental states can differ from reality,</li> 
<li>and that people's behavior can be predicted by their mental states. i.e.,`theory of mind'.</li> 
</ol>
<p>In this test, explained in above image, if the child realizes that Sally will look for the marble in her own basket and not in the box, then the child can understand Sally's perspective and you can say the child has a Theory of Mind. </p>
<p>If the child says that Sally will look for the marble in Anne's box, then you might say the child does not have a Theory of Mind. </p> 
<p>
  Theory of mind dysfunction is prominently observed in people with autism and that might be the reason that they find it difficult to have symbolic play, share their toys in cooperative play, to be compassionate with others, to be sarcastic or understand sarcasm, lie, or deceive others, or relate socially in general and communicate with other people or understand inference in communication. 
</p>
          
      </div>
      <div class="column1" >
        <img src="assets/images/content/aut-img/neuro.png" alt="" class="mycontent-img"> 
      </div>
    </div>    
</div>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title>
       <div class="head3">Theory of Executive Dysfunction  
      </div>
     </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <p>
        “Executive Functions are higher order cognitive processes mediated by the frontal cortex that help us to function as problem solvers.”-Zelazo & Colleagues (1997)</p>  

 <p> Executive functioning is an umbrella term for Planning, working memory, problem solving, higher order reasoning, inhibition, mental flexibility, multi-tasking, initiation, and self-monitoring.</p>  

<p>Theory of executive dysfunction assumes that it is a form of 'frontal lobe' perseveration or inability to shift attention (Ozonoff & Prior, 2007). Individuals with autism are impaired in Executive Functioning, particularly in planning, mental flexibility, inhibition, and self-monitoring. </p>

<ul>
  <li>Poor planning abilities affect the ability to think ahead, formulate strategies and solutions. Due to this, students may make lot of mistakes while doing routine schoolwork or may have poor time management, poor money concepts or find it difficult to determine goals and complete targeted work. </li>
  <li>Poor mental flexibility in people with autism explains their repetitive behavior, strong desire for routines and need for sameness. Inflexible thinking leads to lack of pretend play, inability to think of future situations, limitation in understanding world outside their experiences, limited imagination, inability in telling and understanding lies and absence of or delay in theory of mind. </li>
  <li>Inhibition of pre-potent response (learned responses/tendency to do things a certain way) is found to be impaired in autism. It results in an inability to control one's emotions, behavior, and attention. Due to which the student in classroom may be unable to “think before speaking/doing” and would speak whatever comes to his/her mind or run to the window to see birds/rain and disturb the whole class. </li>

  <li>  Due to poor working memory, students might find it difficult to memorize and retrieve information from memory or retain information while solving questions or imagine the steps and remember them and then using the instructions given or using past information to solve present problem. </li>
</ul>
    </div>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title>
      <div class="head3">Theory of Central Coherence 
      </div>
     </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">

      <p>
        Central coherence is the ability to see the bigger picture, understand the context from the surrounding events, and get the gist/summary of the detailed information. This contextual processing is missing in people with autism. A person with strong central coherence, looking at an endless expanse of trees, would see “the forest.” A person with weak central coherence would see only a whole lot of individual trees. </p>

<p>Most children with autism are reported to spot minor changes in the environment much quicker than others. E.g., change in furniture, new piece of jewelry, Bindi, a small dirt on bedsheet etc. They might concentrate on part of a particular thing e.g., may play with only wheels of the car instead of rolling the car.  </p>

<p>Weakness in central coherence has also been used to explain why children with autism show good memory for details of a story, while failing to recall the overall storyline.  This theory proposes weak information integration, to explain narrow interests and special talents. Hence children with autism show good discrimination and categorization abilities, and yet poor generalization of learning. Most children with autism have a good sense of music and tone attributed to their weak central coherence. </p>
</div>
</mat-expansion-panel>
<br>
 


<br>
 
 
