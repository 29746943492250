import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skill-dev',
  templateUrl: './skill-dev.component.html',
  styleUrls: ['./skill-dev.component.css']
})
export class SkillDevComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
