<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a href="/blog">Blog</a></li>
  <li>Behavioural Management</li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Behavioural Management</span>
  </h2>
  </div>
  </div>
  </div>
  </div>


<div class="mycontent">

<p>People with autism face a lot of difficulties in their regular life due to impairments in social communication, interaction and social imagination. Also, they may show unusual responses to various sensations due to sensory disorders. All these difficulties can be evident in their behavior, and they may therefore have non-typical ways of relating to people, objects and events in their environment.  </p>
<p>We need to understand what exactly "behavior "denotes with the help of the following points: </p>

<ol>
  <li>Behavior is  <b>observable</b>. E.g., A person is rocking his body. This can be observed and hence it's a behavior. </li>

  <li> Behavior is<b> specific</b>. E.g., If a person is angry, we need to specify what exactly he is doing at that	time like if he is screaming or hitting self.</li>  
    
<li>  Behavior is <b>measurable</b> in terms of frequency, duration, intensity. E.g., Naina scratched her head 5 times in 15 minutes.</li>  
    
<li>   Behavior is the result of a person’s current environment and his history or learning. E.g., on hot days in summer, we turn on fan or Air conditioner and on cold days in winter, we keep fans off.</li>   
    
<li>  If a behavior pattern is present, there MUST be a reason for it such as any tangible or intangible gain, escape and avoidance from any situation, sensory difficulties, or medical issues. </li> 

<li>   The person exhibiting the behavior is trying to communicate something. </li>
</ol>

<b>Then what is challenging behavior? </b>

<p>
There can be many behaviors that one may want to teach, maintain, or change in people with autism. But we must choose our battle by prioritizing the child’s current and future needs. We must figure out the challenging behaviors, that are </p> 
<ul>
<li>Interfering in a person's learning process</li> 

<li>Causing injury or harm to self or others </li>

<li>Preventing a person from performing usual life routines and activities that are expected at his level </li>

<li>Adversely affecting the mental peace, health and the general functioning of family members and others in the environment. </li>
</ul>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Functional assessment of behavior 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">

<p>To address any behavioral concerns, it is important to carry out a functional assessment of the behavior to understand why the behavior is happening. Functional assessment of a behavior is done by collecting data for a particular period related to the challenging behavior of a person. This data is about three things  </p>
<ol>
  <li>Antecedent (A), something that happens before the behavior. </li>

  <li> Behavior (B), that which a person says or does, and which is observable, specific, and measurable; and </li>
    
  <li> Consequence (C), that which follows the behavior.  </li>
</ol>

This is often referred to as the ABC of behavior. 

For Example: 

Teacher says “Clap hand” to Raju– Raju claps hand – Teacher says “Very good” 

Here Teacher says “Clap hand” to Raju. This is an Antecedent. 

Raju claps hand. This is a behavior 

The teacher says, “Very good”. This is a Consequence. 

While doing ABC analysis of the behavior, data is maintained in a tabular format for antecedent, behavior, and consequence. Hence, all the important points relating to these are mentioned below: 

<ol>
  <li>Antecedent can refer to some or all of the following: </li>

  <ul>
    <li>When and where does the behavior usually occur?  </li>

  <li> Who are the people present during the situation most of the time? </li> 
      
<li>  What do people say or do before the behavior occurs? </li> 
      
<li>  Are there any other behaviors that take place before this specific behavior? </li>
      
<li>  When, where and with whom the behavior takes place the least number of times? </li>
      
       
  </ul>

  <li>Behavior  </li>
  <ul>
    <li>We need to look at the behavior very carefully and record exactly what the individual does/ says in specific and measurable terms. 

    </li>
  </ul>

  <li>Consequences </li>
  <ul>
    <li>What follows on after the behavior?  </li>

  <li>  What do people in the environment do when the behavior occurs? </li>
      
<li>  Does the person gain anything after the behavior occurs?  
      
<li>  Does the person get out of or avoid anything because of the behavior? </li>
  </ul>
</ol>
All this information helps us in analyzing the function of the behavior. 

<ol>
  <li>Tangible gain - The behavior provides the person with something tangible like food, an action, a toy etc. E.g., At study times, Meena doesn’t sit in one place, so her mother gives her chocolates to sit and do her studies.</li> 

<li>   Intangible gain - The behavior provides the person with attention or comfort. E.g., Prachi is supposed to sleep on her own. But at sleep times, she starts crying and her mother comforts her to sleep. </li> 
    
<li>   Escape and avoidance - The behavior may facilitate the person to escape or avoid something she or he may not want to do. When his mother starts teaching him, Sanju starts screaming and his mother lets him go.</li>  
    
<li>   Sensory - A behavior may be an expression of some sensory difficulty and/or a sensory need. E.g., Sunny starts banging head when he hears any loud noise in home or anywhere nearby. </li>  
    
<li>   There may also be medical reasons for behaviors at times. E.g., Dina is avoiding salty or spicy foods; it could be possible that she has mouth ulcers. It is very important to rule out any medical problems before applying the techniques of behavior modification. </li> 
    
<li>   It is important to note that a particular behavior may be serving different functions at different times. The same behavior of crying may occur for getting something tangible at times and at other times for escape. </li> 
    
    
</ol>
</div>
</mat-expansion-panel>
<br>

<br/>

<div class="head2">
  <h3> Modifying a behavior </h3>
</div>
<hr>

<p>After understanding the function/functions of behavior, we can develop a strategy to teach or maintain or change behavior by manipulating the antecedent or consequence of that behavior and giving an appropriate alternative behavior. A target behavior can be modified by  </p>

<ol>
  <li>
Setting up/ Modifying antecedents </li>

<li>Teaching Replacement Behavior </li>

<li>Modifying the consequences to the behavior</li> 
</ol>


<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Setting up/ Modifying antecedents
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">
    <ul>
      <li> <b> Structure physical environment - </b></li> 

        We can give clarity to the person with autism about what is to be done at which 	place by structuring physical environment as follows: 

<ol>
  <li>Demarcate areas for specific activities such as play area, study area, dining area, sleeping area by visual cues such as toys kept in play area.</li> 

<li>  Give clear visual boundaries to the areas by using furniture or colored tapes. </li>
    
<li>  Use visual cues – an object, a photograph, a picture denoting an activity such as for sorting activity, picture of sorting activity can be used. </li>
    
<li>  Eliminate or reduce whatever can cause sensory discomfort e.g., if the child finds bright lights uncomfortable, then we can use dim lights in the room. </li>
</ol>
<p>All these arrangements will help in providing predictability, clarity and reduce anxiety in the person. </p>

<li><b>Structure Time </b> - We can give clear indications of tasks or activities that are going to happen throughout the day by using visual schedules, calendars, clocks, and timers. </li>

<li>  It's important to work on a person's communication, social and leisure skills and sensory difficulties so that his behaviors can be managed in a better manner.  </li>
  
<li>  Work on your communication as well by use of clear, concrete, and positive language, accurate information, and following through promises. E.g., Rather than saying, "You will drop the glass tumbler", we can say "Keep the glass tumbler on table."  If you promise to take the child to the park in the evening, you must take him to the park in the evening. Never postpone the promises that you made to your child. That’s the way he will understand that whatever is told to him is followed through and the spoken words mean some concrete things. That helps in building a strong bond of trust between parent and the child or the teacher and the child. </li>

    </ul>

   </div>
</mat-expansion-panel>



<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Teaching Replacement Behavior 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">
    <ul>

<li>Teaching replacement behavior is an effective way to increase desired behaviors.  Replacement behavior should be: -  </li>
<ul>
<li>  easier and more efficient than the concern behavior. </li>
  
<li>  It should serve the same function of behavior </li>
  
<li> socially acceptable.</li>
</ul>
  
<li>  E.g., A child starts crying to get his favorite chips. This child can be taught to ask for chips by showing the picture card of chips. This card should be in the reach of the child and always available for communicating his need for chips. When he can communicate that he wants chips and gets immediately after showing the card, then that can help in reducing his crying behavior and increasing his communication.   </li>
  
<li> Teaching other positive alternative behaviors: - </li>
  
<p>Alternative behaviors are positive behaviors taught to the autistic child to replace 	challenging behaviors and to meet long term behavioral goals. Stopping one 	behavior of the child is not an option as the child can learn another behavior to 	get the same result. </p>   
  
<p>   E.g., Child having sound hypersensitivity covers his ears with 	hands when he 	steps out of the house. If he is stopped from covering his ears, he may start 	screaming to overpower the outside sounds. So rather than stopping him from 	covering his ears, he can be taught to use earplugs or headphones to avoid such 	sounds.</p>    
  
<li> There must be consistency in practicing the replacement/alternative behavior so that when an antecedent happens, the replacement/alternative behavior is more likely to be followed.  </li>
  
<li> As your child gets used to the process, acceptable behaviors become habits and the alternative behaviors become stronger over time.  </li>
  
<li>  When your child is exhibiting appropriate behaviors, appreciate them.  </li>

    </ul>

   </div>
</mat-expansion-panel>


<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Modifying the consequences to the behavior  
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">

    <p>All behaviors are acquired through conditioning, and conditioning occurs through interaction with the environment. Behaviors are learnt based on the consequences that immediately occur after the behavior. Most behaviors are strengthened by consequences that are reinforcing and weakened by consequences that are punishing. Hence, by manipulating consequences, we can teach, strengthen and change any behavior using the following procedures: </p>

    <ol>
      <li>Reinforcement  </li>

    <li>  Punishment  </li>
        
  <li>  Extinction </li>
    </ol>

<br>


    <h3> Reinforcement  </h3>

    <p>Reinforcement is the consequence that strengthens a behavior. It increases the possibility of a behavior occurring in the future or increases the frequency of the behavior. E.g., Sudha completes her art activity, and the teacher praises her for that. Sudha now more often does her art activities. Here, the teacher's praise worked as a reinforcement for Sudha's increased behavior of completing art activity. </p>

  <p>  Reinforcer is not just a reward. It is not that it will always be pleasurable. E.g., Seema scratches her mother whenever she speaks on the mobile. Her mother scolds her for that and now Seema scratches her mother more. Here, mother's scolding, which is unpleasurable, increased Seema's scratching behavior.   </p>
      
<p>  The following are some of the reinforcers: </p>

<ul>

  <li>
  Edibles – chips, chocolates, cake, cookies, or any favorite food item of the child. </li>

<li>Drinks – soft drinks, water, juices etc.</li>

<li>Toys - Light and musical toys, building blocks, toy car, bubbles, top etc </li>

<li>Activities that the child likes to get engaged in such as music, art, books etc.</li> 

<li>Things of child's interest like wrappers, strings, empty bottles etc. </li>

<li>Social praise – Hugs, smile, encouraging words like "good job". </li> 

  
</ul>


<p>To make reinforcers effective: </p>
<ul>
  <li>Reinforcers should be given immediately after the behavior occurs. (Immediacy) </li>

  <li> Reinforcer should be given immediately only if behavior occurs. (Contingency). That keeps the importance of that reinforcer. </li> 
    
<li>   If the reinforcer is given at other times, then the importance of that reinforcer will be lost. </li> 
    
<li>   Reinforcer should be such that it gets over immediately after reinforcement. E.g., any eatable that gets finished after eating. </li> 
    
<li>    Over a period, the desire for same reinforcers may reduce and effectiveness of it for behavior modification also may get reduced. Hence it is important to introduce new reinforcers similar to the things that they already like. E.g., If the child likes lemon, he may also like tamarind. </li> 
    
<li>   New reinforcers can be paired with old reinforcers.  </li> 
    
<li>   Make yourself so much reinforcing that just being with you can be a reinforcer.  </li>
</ul>

<br>

<h3>Punishment </h3>
<p>
  Punishment refers to a consequence that reduces or weakens a behavior. Presentation or removal of something immediately after a behavior that decreases the occurrence or frequency of occurrence of any behavior works as a punishment for that behavior. When we use punishment as a word, it generally depicts retaliation for wrongdoing in the form of hitting, scolding, or taking away any benefit or favorite things from the child. But it will be called punishment only if it reduces the behavior. </p>


E.g., 
<ol>
<li> Seeta's mother was engaged in cooking and at that time Seeta spread all her toys in the room. Her mother scolded her a lot. Next day, when her mother was busy cooking, Seeta spread toys and study material as well. </li> 
<p>
Here, Seeta's behavior of messing up room did not reduce though her mother scolded her. Hence, scolding didn't work as a punishment. Instead of that it worked as a reinforcer and increased the behavior. This happened maybe because Seeta was messing up the room to get attention of her mother and when her mother scolded her, she got the attention and so she messed up the room again to get more attention. Therefore, to understand the function of behavior is very important for modifying any behavior. </p>

<li> Ajay completed his worksheet in class time. His teacher praised him and tapped on his back in appreciation. This happened for a few days. After a week, Ajay stopped doing his worksheet in class.</li> 

<p>Here, the teacher's appreciation reduced Ajay's behavior of completing worksheet and that means it worked as a punishment. This happened maybe because Ajay has sensory issues, and he doesn't like anyone touching him and the teacher was touching him for patting his back. </p>
</ol> 
<p>
Hence, punishment is not always an unfavorable or an unpleasant consequence. It is not about retribution or retaliation. Sometimes, pleasant things also reduce behavior and work as punishment as in above example. 
</p>


Some punishment procedures: 
<ol>
<li> <b> Time out </b>- Time out refers to moving the child from a reinforcing environment to a less reinforcing one. E.g., If the child is troubling the classroom, then the teacher makes that child stand in a corner or makes him sit out of the class. But this may not work with the child who finds the isolation more desirable.</li> 

<li> <b> Over Correction </b>- Over Correction refers to punishing the child with an effortful activity related to the targeted behavior for a longer period. E.g., If the child spills food on the floor, then he is punished with the task of cleaning the food that he spilled along with cleaning the entire room. But it may not work if the child needs physical assistance while doing this activity or if the physical work is reinforcing for him or if there is any risk of injury while doing the activity. </li>
  
<li> <b> Response Cost</b> - Response Cost refers to the removal of a reinforcer when undesired behavior occurs. E.g., fine levied for jumping the road signal, taking away favorite toy of the child if he misbehaves or refusal to give favorite food are examples of response cost. But in this punishment, it is difficult to punish without paying attention to the behavior. 
  
   </li>

</ol>


Factors to keep in mind while using punishment for people with autism 
<ul>
  <li>Punishment is said to be tool of a weak teacher. </li> 

  <li>  It is a treatment of last resort, to be used after all other alternative strategies have been implemented. </li>
    
<li> Punishment should be used scientifically by collecting data about how it's affecting behavior I.e., whether it is reducing the behavior or not.</li> 
    
<li>  Safety and dignity of the person is utmost important while applying a punishment procedure. </li>
    
<li>  There are ethical issues regarding the use of punishment procedures. </li>
    
<li>  It is also observed that many times, the effect of punishment is short lived, and behavior reduces only in the presence of punishing agent. </li>
    
<li>  Punishment is not beneficial to the learning environment as the person who is punished may try to avoid the punisher and would not like to learn from him. </li>
    
     
</ul>

<br>
<h3>Extinction </h3>
<p>While opting to modify any behavior, it is important to find out the reinforcers that are strengthening the behavior. As the reinforcer increases the behavior, if we withdraw such reinforcers, behavior may stop occurring. This procedure of withholding reinforcement is called extinction and the concerned behavior is said to be put on extinction. </p> 

 <p> E.g., The teacher used to praise Deepak for eagerly answering the questions in the class. Deepak used to feel very happy and was always eager to answer questions. Gradually, the teacher stopped praising him and then Deepak also stopped answering the questions in the class. </p> 
  
<p> Here, the consequence of teacher’s praise acted as a reinforcer for Deepak’s behavior of answering the questions. When this behavior was no longer reinforced, Deepak's behavior gradually stopped. It means that the teacher had put his behavior of answering on extinction. </p>

While using extinction, the following factors must be considered: 
<ul>
  <li>When we opt to modify any challenging behavior, we need to teach an appropriate alternative behavior serving the same function of that behavior. E.g. If the child keeps on crying for getting chips and we want to put the crying behavior to extinction, by not giving him chips when he cries, then we need to teach him to ask for the chips either verbally or by using picture, sign or object. </li> 

  <li>  Extinction should be generalized in all environments, with all people, all the time and for all functions of behavior I.e., whenever the behavior occurs. E.g. To continue with the above example, if we have put crying behavior on extinction, then it will be on extinction in all the situations in which the child cries. Like if the child cries to get attention or to escape from activity or to satisfy his sensory need, then all these times the crying behavior will be on extinction. 
    
     </li>
</ul>

 <h4>Extinction Burst</h4>
<p>
When a behavior is put on extinction, it may initially increase in frequency, duration, and intensity. It may also result in some new behaviors to satisfy the function of behavior. Even emotional responses of the person, whose behavior is put on extinction, may increase so that the things happen as per his desire. This is called Extinction burst. </p> 
<p>
E.g., The child whose crying behavior is put on extinction, may start crying more often to get chips or may start screaming or hitting to get chips.  </p>
<p>
But if the extinction is continued, then eventually, the concerned behavior reduces, and we can achieve the modification of behavior.</p> 

 <h4>Spontaneous recovery</h4>
<p>
Spontaneous recovery is the event when the behavior which has been modified after extinction recurs after some time. If the extinction process is continued even after the modification, then if spontaneous recovery happens, the behavior will not continue for very long. </p>
   </div>
</mat-expansion-panel>