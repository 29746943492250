<section class="bg-half  d-table w-100" id="home"></section>

<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
  <li>Associated Conditions </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Associated Conditions</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
<div class="mycontent">
  
  <!-- <div class="head2">
  <h2> <b>Associated conditions</b></h2>
</div> -->
<hr>
  <p>
    People with autism may have co-occurrence of other physical or mental health
    conditions. These can be broadly classified in 5 categories:
  </p>
  <ol>
    <li>
      Medical conditions such as epilepsy, gastrointestinal issues, or sleep
      disorders
    </li>
    <li>
      Developmental disorders such as intellectual disability or language delay
    </li>
    <li>
      Mental health conditions such as anxiety disorder, (ADHD),
      obsessive-compulsive disorder, depression
    </li>
    <li>
      Genetic Conditions including fragile X syndrome, tuberous sclerosis
      complex, Down Syndrome
    </li>
    <li>Sensory issues</li>
  </ol>
  <div class="head2">
  <h3>Autism and Medical conditions:</h3>
</div>
<hr>
 
   <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Autism and Epilepsy
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">
    <p>There is an association between autism and epilepsy. Children with autism
      are more likely to have epilepsy and children with epilepsy are more
      likely to have autism. Epilepsy (seizure disorder) affects up to one third
      of the population of people with autism. Also, Electroencephalography (EEG)
      abnormalities are common in the absence of clinical seizures. 
      Currently,
      seizures in an individual with autism are treated through anti-epileptic
      drugs in the same way as seizures in individuals without autism are
      treated.</p>
    </div>
  </mat-expansion-panel>
  <br>
  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title><div class="head3">
        Autism and gastrointestinal issues</div>
      </mat-panel-title>
      
    </mat-expansion-panel-header>
    <div class="mycontent1">
    <p>Gastrointestinal issues including vomiting, diarrhea, constipation, or
      abdominal pain, are more common among people with autism. Another less
      known issue is Pica, a compulsive eating disorder in which people eat
      nonfood items dirt, clay, and flaking paint, glue, hair, and feces. Many
      of them also have gastrointestinal food allergies as well as
      gluten-related disorders (celiac disease, wheat allergy, non-celiac gluten
      sensitivity). Sometimes these issues result in worsening of nonverbal
      behaviors such as agitation, anxiety, aggression, self-injury, sleep
      deprivation.</p>
    </div>
  </mat-expansion-panel>
  <br>

<mat-expansion-panel (opened)="panelOpenState = true"
                     (closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title><div class="head3">
    Autism and Sleep disorder</div>
    </mat-panel-title>
    
  </mat-expansion-panel-header>
  <div class="mycontent1">
  <p>Many of the children and adults with autism suffer from disturbed sleep
    patterns. They may have difficulties in falling and staying asleep or wake
    up too early and have a tough time getting back to sleep. This can also
    affect the stress levels of parents and caregivers as they too suffer due
    to the disturbed sleep patterns of their child.</p>
    <p> In such cases, the use of doctor's prescribed medicines can help. Parents and caregivers also can
    maintain sleep diary to note down observations about daytime and bedtime
    routines, sleeping pattern through day and night. Certain changes like
    cutting back on computer or video game time, physical exercises, changes
    in food habits also can help their child sleep better.</p>
  </div>
</mat-expansion-panel>
 
<br>
 
<div class="head2">
  <h3>Autism and Developmental disorders:</h3>
</div>
<hr>

  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Intellectual disability</div>
     </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
    <p>The most common co-occurring condition with autism is intellectual
      disability. Persons with intellectual disability are observed to have even
      impairment in skills in all areas of development whereas in autism, there
      is an uneven skill development. Hence accurate assessment of intelligence
      quotient (IQ) becomes difficult for people with autism as they have
      difficulty in processing language. Earlier it was estimated at around 80%
      of children with autism has mental retardation just the way that people
      with cerebral palsy or Down syndrome can have mental retardation. Greater
      understanding of the condition now suggests that the number is closer to
      50%. Though there are no medications available for treating intellectual
      disability or autism, early intervention therapies and consistency in
      teaching new skills can make a significant difference in achieving
      independence in daily life.</p>
    </div>
  </mat-expansion-panel>
  <br>

  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Language delay, Speech disorder</div>
     </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
    <p>Language delay is when young children have difficulties in understanding
      and/or using spoken language and in non-verbal communication like body
      language, gestures, and facial expressions. While some people with autism
      never speak, others start speaking extremely late after a lot of
      interventions. Many speak in one or two words or use repetitive words or
      have echolalia. Some use pedantic speech and some struggle with the
      correct use of grammar such as plurals, pronouns, and prepositions. They
      can learn to understand and use language by using signs, objects,
      pictures, by writing and by using various digital applications.</p>
    </div>
  </mat-expansion-panel>

<br>
  
<div class="head2">
  <h3>Autism and Mental health conditions</h3>
</div>
<hr>

  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Anxiety disorder</div>
     </mat-panel-title>
     
   </mat-expansion-panel-header>
   <div class="mycontent1">
   <p>Many children and adults with autism may experience anxiety disorders.
    Anxiety may increase as children approach adolescence. At this time, difficulty in forming friendships and
    negotiating the social world may lead to depression in some people with
    autism. Just because a person with autism has difficulty forming
    appropriate relationships, it does not mean that they are immune to the
    pain that comes from social rejection and isolation.</p>
  </div>
 </mat-expansion-panel>
 <br>

 <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Attention Deficit Hyperactivity Disorder (ADHD)</div>
     </mat-panel-title>
     
   </mat-expansion-panel-header>
   <div class="mycontent1">
   <p>Parents of children with autism often note that their child seems
    hyperactive and 'constantly on the move.' Many children with autism may be
    restless because of an impairment of their imaginative and social skills.
    Because they do not know how to play with their toys and with other
    children meaningfully, they find it difficult to occupy themselves.
    Hyperactivity may be reduced as the child is taught new skills and ways to
    keep himself occupied. It is important to understand that a child who does
    not know how to play with toys and otherwise occupy himself, as a
    non-autistic child would and therefore runs 'aimlessly' around the house,
    is not necessarily being 'hyperactive.'' He is just trying to occupy
    himself in the best way he can, and hyperactivity can also have a medical
    reason. However, for many children with autism, it may more likely result
    from an inability to keep himself or herself occupied.</p>
  </div>
 </mat-expansion-panel>
 <br>

<mat-expansion-panel (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
 <mat-expansion-panel-header>
   <mat-panel-title><div class="head3">
    Obsessive-Compulsive Disorder (OCD)</div>
   </mat-panel-title>
   
 </mat-expansion-panel-header>
 <div class="mycontent1">
 <p>OCD is characterized by recurrent intrusive thoughts or images, and repetitive behaviors. The disorder frequently starts in childhood and adolescence and may be due to restricted interest and repetitive behaviors in autism. This can be treated with medications prescribed by the neurologists</p>
</div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                    (closed)="panelOpenState = false">
 <mat-expansion-panel-header>
   <mat-panel-title><div class="head3">
    Depression</div>
   </mat-panel-title>
   
 </mat-expansion-panel-header>
 <div class="mycontent1">
 <p>Depression rates for people with autism rise with age and intellectual
  ability. Autism often imposes severe limitations on capacity for managing
  activities of daily living, and hence their quality of life gets significantly compromised due to dependence on caregivers or parents.</p>
 
 <p> Though it is difficult to identify depression in people with autism,
  common signs can include loss of interest in favorite activities, a
  noticeable worsening in hygiene, chronic feelings of sadness,
  hopelessness, worthlessness, and irritability. Depression can include
  frequent thoughts about death and/or suicide. Depression in ASD may be
  treated using medications and behavioral therapies.</p>
</div>
</mat-expansion-panel>

<br> 
  
<div class="head2">
  <h3>Autism and Genetic conditions</h3>
</div>
<hr>

  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Fragile X syndrome (FXS)</div>
     </mat-panel-title>
     
   </mat-expansion-panel-header>
   <div class="mycontent1">
   <p>FXS is a genetic condition that causes intellectual disability, behavioral
    and learning challenges, and various physical characteristics. Though FXS
    occurs in both genders, males are more frequently affected than females,
    and generally with greater severity.</p>
  </div>
 </mat-expansion-panel>
 <br>

 <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Tourette Syndrome</div>
     </mat-panel-title>
     
   </mat-expansion-panel-header>
   <div class="mycontent1">
   <p>Tourette Syndrome is a highly heritable neuropsychiatric disorder
    characterized by motor and vocal tics. The tics are usually single and
    random, involve the face, neck,and shoulders. It seems like reaction to an inner state of tension and are temporarily suppressible with medication.  </p>
  </div>
 </mat-expansion-panel>
 <br>
 <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Down Syndrome</div>
     </mat-panel-title>
     
   </mat-expansion-panel-header>
   <div class="mycontent1">
   <p>When autism occurs in someone with Down syndrome, the characteristics of
    autism (social and behavioral challenges, communication difficulties and
    restricted interests) may be observed in addition to the symptoms of Down
    syndrome (intellectual disability, speech, and language delays).</p>
  </div>
 </mat-expansion-panel>

<br>
 
    
<div class="head2">
  <h3>Autism and Sensory issues</h3>
</div>
<hr>
  <p>
    All of us combine our seven senses (sight, smell, sound, touch, taste,
    balance, body in space) to make sense of the environment around us. Persons
    with autism often have trouble learning to integrate and process all the
    information coming from multiple sources of the environment at a time. Their
    senses may be too acute (hypersensitivity) or less responsive
    (hyposensitivity) or there may be a combination. Hence, they may find
    sensory difficulties while doing many of the daily routine activities. They
    may also experience difficulties in motor planning, posture, fine motor
    skills etc.
  </p>
</div>
