import { Component, OnInit } from '@angular/core';
import { UPost } from '../shared/UPost.model';
import { CrudService } from '../shared/crud.service';
import { catchError } from 'rxjs/operators';
import { ACrudService } from 'src/app/Authentication/shared/acrud.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from 'src/app/Authentication/shared/auth.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  data: UPost[];
  sorted: UPost[];
  featuredPost: any
  isFetching: boolean = false
  error: string
  searchText
  featuredPostsorted: any[];
  commenData: any = []




  // isAuthenticated = false;
  // x
  // ProfieData: { uname: any; desc: string; email: string; name: any; created_date: Date; imgurl: Observable<string>; isProfileSet: boolean };
  // isprofileSet: boolean = false
  // username: string
  // private userSub: Subscription;
  // allpostcount: number = 0;
  // isemailverfied: boolean

  // isloading: boolean = false
 



  constructor(private cd: CrudService,
    private acrud: ACrudService,
    // private authService: AuthService,
    private toastr: ToastrService) {
  
      // this.acrud.username.subscribe(d => {
      //   this.username = d
  
      // })
      }
     

  ngOnInit(): void {
    


    // this.isloading = true
    // this.authService.autoLogin();
    // this.authService.isLoggedIn1()
    // this.userSub = this.authService.user.subscribe((user: any) => {
    //   this.isloading = false
  
    //   if (user) {
    //     this.isemailverfied = user.emailVerified
  
    //   }
    //   this.isAuthenticated = !!user;
    //   console.log(!user);
    //   console.log(!!user);
  
    //   console.log(this.isAuthenticated)
    //   if (this.isAuthenticated) {
    //     this.acrud.getProfileFromUid(user.uid).subscribe(
    //       d => {
    //         let x2: {} = {}
  
    //         let x = this.acrud.seprate(d)
  
  
    //         this.ProfieData = x[0]
    //         if (this.ProfieData) {
    //           this.isprofileSet = this.ProfieData.isProfileSet
    //           this.username = this.ProfieData.uname
  
    //         }
  
    //       }
    //     )
  
    //   }
    // });
  
  
  
  



    this.getAllPost()
    this.getFeaturedPost()







  }
  

  getAllPost() {
    this.isFetching = true;
    this.acrud.getAllPost().then((x: any) => {
      this.isFetching = false
      this.data = x
      this.sortDesecending(this.data)

    })
  }
  sortDesecending(data) {
    this.sorted = data.sort((a: any, b: any) =>
      <any>new Date(b.created_date) - <any>new Date(a.created_date)
    )
  }
  onReadMore(index) {

  }

  getFeaturedPost() {
    this.acrud.getFeaturedPost().then(x => {
      let c = 0
      console.log('-----------------Get Featured Post-----------', x);

      this.featuredPost = x
      for (let i in this.featuredPost) {
        let y = this.acrud.seprate(this.featuredPost[i].commentData)
        this.commenData.push(y)
      }

    },
      err => {
        console.log(err)
      })


  }

  public_data() {
    this.isFetching = true;
    this.cd.get_public_post()
     .subscribe (result => {
        this.data = result.map(e => {
          return {
            ...e.payload.doc.data() as {}
          } as UPost

        })
        this.isFetching = false;
        
      },
        err => {
          this.isFetching = false;
          this.error = err

        })
    catchError(error => {
      throw new Error('Error: Getting document:' + error); // throw an Error
    });

    
  }

  // onLogout() {
  //   this.authService.logout();
  // }




}
  
 


