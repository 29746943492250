import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sensory-disorder',
  templateUrl: './sensory-disorder.component.html',
  styleUrls: ['./sensory-disorder.component.css']
})
export class SensoryDisorderComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
