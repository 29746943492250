<section class="bg-half  d-table w-100" id="home"></section>

<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/know-centre">Knowledge Centre</a></li>
  <li>Visual Supports and Structured Teaching</li>
</ul>
<div class="container" data-uw-styling-context="true">
  <div
    class="hero hero--solid hero--secondary hero--titlefix"
    data-uw-styling-context="true"
  >
    <div class="hero_innercontainer" data-uw-styling-context="true">
      <div class="hero_content" data-uw-styling-context="true">
        <h2 class="hero_title" data-uw-styling-context="true">
          <span data-uw-styling-context="true"
            >Visual Supports and Structured Teaching</span
          >
        </h2>
      </div>
    </div>
  </div>
</div>
<div class="mycontent">
  <div class="row-main">
    <div class="column-main"  style="background-color: rgb(248, 218, 231);">
  <p>
    Most children with autism are strong visual learners as they process and
    respond to visual information better than verbal information. Hence, visual
    supports are used to aid children with autism to maintain attention,
    understand spoken language, and sequence and organize their environments.
    Visual support helps in creating appropriate and meaningful environments
    that reduce stress, anxiety, and frustration built up due to communication
    difficulties. This helps to address challenging behaviors in children with
    autism in a proactive manner.
  </p>
  <p> By capitalizing the visual learning strength of children with autism,
    structure teaching techniques were developed by Division TEACCH (Training
    and Education of Autistic and related Communication-handicapped Children) in
    North Carolina. Structured teaching strategies include the creation of
    visually based structured environment that promotes an understanding of
    schedules, activities, and expectations.</p>
 
</div>
 

  <div class="column-main">
  <img src="assets/images/content/aut-img/pyramid.svg" 
  class="mycontent-img" >
  <br> 
  <span class="square" >Structured Teaching Components</span>

</div>
  </div>
  <br>
  <p>
    It helps students
  </p>
  <ul>
    <li>To adapt to the environment</li>
    <li>
      To understand what is expected of him in different environments, at
      different times
    </li>
    <li>To expand their ability to interact with surroundings</li>
    <li>
      To learn new skills and generalize those skills in different environments
      like school, home, and society.
    </li>
    <li>
      To have a sense of autonomy and allow them to make choices and express
      their needs.
    </li>
  </ul>
 
  <br /><br />
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="head3">Physical Structure</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row-main">
        <div class="column-main">
          <p>
            Physical structure is the foundation of structured teaching. It
            refers to the way in which the person's physical environment is set
            up and organized. It helps in minimizing visual and auditory
            distractions. It makes clear to the child the exact boundaries of
            each area, what is to occur in each area, how to navigate to
            different areas.
          </p>
          <p>
            Physical structures can be used in all the environments that the
            child uses including classrooms, home, playground, library, etc.
            With the strategic arrangement of furniture, the environment in the
            classroom can be clearly, visually defined to differentiate areas to
            address leisure skills, vocational skills, self-help skills, and
            sensory development.
          </p>
          <p>
            Visual cues, like colored rugs, labels, color coding, highlighting,
            or placemats, are helpful in differentiating areas used for more
            than one purpose. For example, if the same table is used for
            different things like learning and eating snacks then through visual
            cues, we can distinguish both the activities. By using a tablemat,
            we can help the students know that it's snack time or by use of
            favorite toy it can be conveyed that it's playtime for them.
          </p>
        </div>
        <div class="column-main">
          <div class="row">
            <div class="column1">
              <br />
              <img
                src="assets/images/content/mydown-img/image 14.jpg"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Physical Exercise Area</h5>
            </div>
            <div class="column1">
              <br />
              <img
                src="assets/images/content/mydown-img/image 15.jpg"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Sensory Room</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <br />

  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Visual Schedule</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      Visual schedule is a visual timetable of scheduled tasks or activities.
      <div class="row-main">
        <div class="column-main">
          <ul>
            <li>
              A visual schedule provides predictability about how their whole
              day or part of the day is planned and what they are expected to
              do.
            </li>
            <li>
              makes it easy for them to switch from one activity to another and
              enhances flexibility in the routines.
            </li>
            <li>
              It also gives independence by telling the child how to move
              through the physical space purposefully, independently, and
              calmly.
            </li>
            <li>
              It also helps to increase a student’s motivation to complete less
              preferred activities.
            </li>
            <li>
              Schedules reduce power struggle and decrease the likelihood of
              behavioral issues.
            </li>
            <li>
              Pictures, objects, photos, drawings, or written words can be used
              for presentation of a schedule depending on the child’s comfort
              level. E.g., a picture of children sitting in a circle, or a
              bangle can be a visual cue for circle time. A spoon or a table mat
              can be a visual cue for snack time. Schedules can be prepared for
              the day, week or even for a month as per the requirement of the
              child.
            </li>
          </ul>
        </div>
        <div class="column-main">
          <div class="row">
            <div class="column1">
              <br />
              <img
                src="assets/images/content/mydown-img/image 16.jpg"
                alt=""
                class="responsive"
              />
            </div>

            <div class="column1">
              <img
                src="assets/images/content/mydown-img/image 17.jpg"
                alt=""
                class="responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </div> 
  </mat-expansion-panel>
  <br />

  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title><div class="head3">Work systems</div> </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row-main">
        <div class="column-main">
          <p>
            The work system provides a systematic and organized visual
            information to the students about a task or series of tasks such as
            what is the work to be done, how much is the work, start and finish
            points, what comes next etc. This helps the student in working
            independently without adult directions or prompts. Work systems can
            be applied to various activities like daily living skills, academic
            work, recreation, and leisure.
          </p>
        </div>
        <div class="column-main">
          <div class="row">
            <h3 class="imghead">Different types of work system</h3>
            <div class="column1">
              <img
                src="assets/images/content/aut-img/work1.png"
                alt=""
                class="responsive"
              />
            </div>

            <div class="column1">
              <img
                src="assets/images/content/aut-img/work2.png"
                alt=""
                class="responsive"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>

  <br />
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Routines and visual strategies</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row-main">
        <div class="column-main">
          <p>
            Visual strategies are used to provide information about routine and
            repetitive instructions in visual format to people with autism.
            These are used to replace verbal prompts for instructions such as to
            sit, stand or to keep hands quiet. It minimizes dependence on
            sequential memory about what to do in social situations and in
            behavior management
          </p>
        </div>

        <div class="column-main">
          <div class="row">
            <div class="column">
              <br />
              <img
                src="assets/images/content/aut-img/keepquiet.jpg"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Keep Quiet</h5>
            </div>
            <div class="column">
              <img
                src="assets/images/content/aut-img/hands.png"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Hands Quiet</h5>
            </div>
            <div class="column">
              <img
                src="assets/images/content/aut-img/sitdown.jpg"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Sit Down</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <br />

  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Other visual supports</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <p>
        Other visual supports that can be used for giving information to the
        child are as follows:
      </p>
      <div class="row-main">
        <div class="column-main">
          <li>
            <b>Calendars </b>
            <p>
              can be marked with color-codes or by sticking pictures on dates
              for both preferred events like travel, birthdays, going to movie
              or restaurant and non-preferred situations such as getting a
              haircut, visit to doctor etc. It provides the person with autism
              predictability about all the events he is supposed to know, which
              helps in reducing their anxiety level and prepares them for the
              experience.
            </p>
          </li>
          <br />
        </div>
        <div class="column-main">
          <div class="row">
            <h3 class="imghead">Calendars</h3>
            <div class="column1">
              <img
                src="assets/images/content/mydown-img/image 18.jpg"
                alt=""
                class="responsive"
              />
            </div>

            <div class="column1">
              <img
                src="assets/images/content/mydown-img/image 19.jpg"
                alt=""
                class="responsive"
              />
            </div>
          </div>
        </div>
      </div>

      <br /><br />
      <div class="row-main">
        <div class="column-main">
          <li>
            <b> Choice Boards </b>
            <p>
              It can be created by using a board with various pictures, objects
              or written options for food, activities, emotions. It is used to
              encourage communication and independent decision making throughout
              the day by providing a visual reminder of what activities are
              available.
            </p>
          </li>
        </div>

        <div class="column-main">
          <div class="row">
            <h2 class="imghead">My Choice Board</h2>

            <div class="column">
              <img
                src="assets/images/content/underautism-img/dough.jpg"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Play Dough</h5>
            </div>
            <div class="column">
              <img
                src="assets/images/content/underautism-img/block.jpg"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Play Block</h5>
            </div>
            <div class="column">
              <img
                src="assets/images/content/underautism-img/tab.jpg"
                alt=""
                class="responsive"
              />
              <h5 class="imghead">Play Tab</h5>
            </div>
          </div>
        </div>
      </div>

      <br />
      <li>
        <b> Visual timers and clocks </b>
        <p>
          Use of visual timer or clock helps in understanding when a particular
          activity is going to end and helps in getting prepared for the
          transition.
        </p>
      </li>
      <br />
      <li>
        <b> Social stories </b>
        <p>
          Social stories describe a situation, concept, or social skill using a
          visual format. It provides information on what a person does, feels,
          thinks, acts, in the given situation. It explains the behaviors that
          are part of any social situation and explains the reasons for the
          required behavior. It also clearly states the expected response in the
          given situation using positive language.
        </p>
      </li>

      E.g., Social story for teaching how to handle anxiety issues can be
      written as follows:

      <p>
        Sometimes I feel anxious and tense.
        <br>
         I can ask my teacher for a break
        from class activities.
        <br>
         I can try to calm down by listening to music.
         <br>
          I can go for a walk for 15 minutes. After that I can resume the next class
        activity
      </p>
    </div>
  </mat-expansion-panel>
  <br />

  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Visual structures</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row-main">
        <div class="column-main">
          <p>
            Visual structure is the way any work is presented to the child. It
            should be clear enough for the child to understand about the work in
            terms of what, how much, how to do, how to start and finish the
            task. Hence, for providing visual structure in activities, materials
            can be kept in sequential manner in different baskets or containers.
            Generally, the material is kept left to right, or up to bottom
            manner. So that start and finish points can be better understood
            visually by the student.
          </p>
        </div>
        <div class="column-main">
          <div class="row">
            <div class="column">
              <br>
              <img
                src="assets/images/content/mydown-img/image 20.jpg"
                alt=""
                class="responsive"
              />
              
              <h5 class="imghead">Place Value</h5>
            </div>
              <div class="column">
              <img
                src="assets/images/content/mydown-img/image 21.jpg"
                alt=""
                class="responsive"
              />
              
              <h5 class="imghead">Count and Match</h5>
            </div>

            <div class="column">
              
              <img
                src="assets/images/content/mydown-img/image 22.jpg"
                alt=""
                class="responsive"
              />
              
              <h5 class="imghead">Match colours</h5>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </mat-expansion-panel>
  <br />
</div>
