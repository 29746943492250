/* tslint:disable:variable-name */
import { Component, OnInit,Input } from '@angular/core';
import {Lightbox} from 'ngx-lightbox';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CrudService } from '../Unauthenticated/shared/crud.service';



@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

/**
 * Index Component
 */
export class IndexComponent implements OnInit {
  @Input() buttonList: boolean;
  @Input() isdeveloper: boolean;
  @Input() shopPages: boolean;
  @Input() navClass: string;


  private _album = [];
  acrud: any;

  constructor(private _lightbox: Lightbox,
    private cd: CrudService,
    ) {
    for (let i = 1; i <= 6; i++) {
      const src = '../../../assets/images/personal/' + i + '.jpg';
      const caption = 'Image ' + i + ' caption here';
      const thumb = '../../../assets/images/personal/' + i + '-thumb.jpg';
      const album = {
        src: src,
        caption: caption,
        thumb: thumb
      };

      this._album.push(album);
    }
  }



  open(index: number): void {
    // open lightbox
    this._lightbox.open(this._album, index);
  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  };






  /**
   * Blog Data
   */
  // blogData = [
  //   {
  //     image: 'assets/images/personal/1.png',
  //     title: 'Autism, seeing the world from a different angle',
  //     like: '33',
  //     message: '08',
  //     name: 'Namita Rawat',
  //     date: '13th August, 2019'
  //   },
  //   {
  //     image: 'assets/images/personal/2.png',
  //     title: 'Autistic today, footballer tomorrow',
  //     like: '33',
  //     message: '08',
  //     name: 'Muhammad Imteyaz ',
  //     date: '13th August, 2019'
  //   },
  //   {
  //     image: 'assets/images/personal/3.png',
  //     title: 'Autism is not contagious, but my smile is',
  //     like: '33',
  //     message: '08',
  //     name: 'Shailesh Pandey',
  //     date: '13th August, 2019'
  //   }
  // ];

 


  





  ngOnInit(): void {
    
  }



  
}