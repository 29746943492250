
<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/vinca-app">Vinca App</a></li>
  <li>App Guide </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">App Guide</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
  
<section id="wp-block-themeisle-blocks-advanced-columns-9f8848a6" class="wp-block-themeisle-blocks-advanced-columns alignfull has-2-columns has-desktop-equal-layout has-tablet-equal-layout has-mobile-collapsedRows-layout has-vertical-center"><div class="wp-block-themeisle-blocks-advanced-columns-overlay"></div><div class="innerblocks-wrap">
  <div id="wp-block-themeisle-blocks-advanced-column-cedf80ca" class="wp-block-themeisle-blocks-advanced-column">
  <div style="height:40px" aria-hidden="true" class="wp-block-spacer eplus-wrapper"></div>
  
  
  
  <h1 id="wp-block-themeisle-blocks-advanced-heading-ddc7a054" class="wp-block-themeisle-blocks-advanced-heading wp-block-themeisle-blocks-advanced-heading-ddc7a054">VINCA APP </h1>
  
  
  
  <p class="has-text-align-left has-white-color has-text-color eplus-wrapper">Within every child is a connection to one form or another and a potential waiting to be fulfilled.</p>
  
  
  
  <div class="wp-block-buttons is-content-justification-left eplus-wrapper"><div class="is-style-primary wp-block-button eplus-wrapper eplus-styles-uid-403d97"><a class="wp-block-button__link" href="https://play.google.com/store/apps" target="_blank">DOWNLOAD VINCA </a></div></div>
  </div>
  
  
  
  <div id="wp-block-themeisle-blocks-advanced-column-48eed1bc" class="wp-block-themeisle-blocks-advanced-column"></div>
  </div></section>
