import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funct-assest',
  templateUrl: './funct-assest.component.html',
  styleUrls: ['./funct-assest.component.css']
})
export class FunctAssestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
