
<section class="bg-half d-table w-100"></section>


<!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

            
        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav> -->

<app-blog-header></app-blog-header>





<div class="row">
    <div class="col-md-3 col-sm-12 post-exist" *ngIf="isAuthenticated && xyz[1]!=='home' && xyz[1]!== 'myprofile'">
        <div class="list-group">
            <a class="list-group-item" routerLink="/create-post">Add new Post</a>

            <a class="list-group-item" [routerLink]="['/myposts',postype,id,'edit']">Edit Post</a>

            <a class="list-group-item" data-toggle="modal" data-target="#myModal">Delete Post</a>

            <a class="list-group-item" [ngClass]="{ active: isPrivate == true }" [routerLink]="['/myposts']">My
                Posts</a>
        </div>
        <div class="modal fade" id="myModal" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Please confirm Delete Action</h4>
                    </div>
                    <div class="modal-body">
                        <p>Are you sure you want to Delete Post.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="OnDelete()">Delete</button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="container container-postdetil col-md-9 col-sm-12">

        <div class="row" *ngIf="xyz[1]=='home' &&
      isUnauth &&
      unauthpost && !isFetching;else loading
    ">
            <div class="col-xs-12">
                <h2 class="title">
                    {{ unauthpost.title }}
                </h2>
                '
                <div class="avtar">
                    <!--   <img *ngIf="!isImgLoaded" [src]="'https://i.pinimg.com/originals/10/b2/f6/10b2f6d95195994fca386842dae53bb2.png' "
                class="img-responsive img-circle" /> -->

                    <img alt="" [src]="ProfileImgUrl || 'https://i.pinimg.com/originals/10/b2/f6/10b2f6d95195994fca386842dae53bb2.png'" class="img-responsive img-circle" />

                    <div class="avtar-text">
                        <a class="author-name" (click)="navigateToProfile(unauthpost.uname)">

                            {{ unauthpost.uname }}
                        </a>

                        <p class="category">
                            <b>Category: </b> {{ unauthpost.category }}

                            <span *ngIf="unauthpost.subcategory && unauthpost.subcategory.trim()">
                                <b>Sub </b> {{unauthpost .subcategory}}
                            </span>


                        </p>


                    </div>
                    <p class="date">
                        {{ postDate | date:"medium" }}
                        <!-- <b>Written on : </b> {{ unauthpost.created_date.toDate() | date: "medium" }} -->

                    </p>
                </div>
                <div *ngIf="unauthpost.imgurl;else loading">
                    <img *ngIf="!isImgLoaded" [src]="'https://i.pinimg.com/originals/10/b2/f6/10b2f6d95195994fca386842dae53bb2.png' " class="img-responsive" alt="img" />
                    <img [hidden]=" !isImgLoaded" [src]="unauthpost.imgurl " (load)="isImgLoaded=true " class="img-responsive " alt="">
                    <figcaption>{{ unauthpost.title }}</figcaption>
                </div>
                <!-- <div *ngIf="!unauthpost.imgurl ">
        <img src="https://i.pinimg.com/originals/10/b2/f6/10b2f6d95195994fca386842dae53bb2.png " loading="lazy " class="img-responsive " />
        <figcaption>{{ unauthpost.title }}</figcaption>
    </div> -->
                
                <!-- <p class="desc ">{{ unauthpost.desc }}</p> -->
                <div>
                <p class="desc" [innerHTML]="unauthpost.desc| noSanitize"></p>
            </div>

                <div class="stage " (click)="Like() ">
                    <div class="heart " [ngClass]="likeStatus? 'is-active': '' ">
                        <span>Like {{count}}</span>
                    </div>
                </div>

            </div>
        </div>

        <div class="row" *ngIf="(xyz[3]=='publicposts' || xyz[1]==='featured') && publicpostOfSingleUser as data;else loading7">
            <div class="col-xs-12">
                <h2 class="title">
                    {{ data.title }}
                </h2>
                '
                <div class="avtar">
                    <img alt="" [src]="ProfileImgUrl || 'https://i.pinimg.com/originals/10/b2/f6/10b2f6d95195994fca386842dae53bb2.png'" class="img-responsive img-circle" />
                    <div class="avtar-text">
                        <a class="author-name" (click)="navigateToProfile(data.uname)">
                            {{ data.uname }}
                        </a>

                        <p class="category">
                            <b>Category: </b> {{ data.category }}

                        </p>

                    </div>
                    <p class="date">
                        <b>Written on : </b> {{ data.created_date | date:"medium" }}
                        <!-- <b>Written on : </b> {{ data.created_date.toDate() | date: "medium" }} -->

                    </p>
                </div>
                <div *ngIf="data.imgurl;else loading7">
                    <img alt="" *ngIf="!isImgLoaded" [src]="'https://i.pinimg.com/originals/10/b2/f6/10b2f6d95195994fca386842dae53bb2.png' " class="img-responsive" />
                    <img alt="" [hidden]=" !isImgLoaded" [src]="data.imgurl " (load)="isImgLoaded=true " class="img-responsive ">
                    <figcaption>{{ data.title }}</figcaption>
                </div>

                

                <p class="desc" [innerHTML]="data.desc| noSanitize"></p>


                


            </div>

           <div>
            <div class="stage " (click)="Like() ">
                <div class="heart " [ngClass]="likeStatus? 'is-active': '' ">
                    <span>Like {{count}}</span></div>
            </div>
           </div>


        </div>

        <div class="row " *ngIf="postype=='public'  && public_post as i;else loading2 ">
            <div class="col-md-9 col-xs-12 ">
                <h2 class="title ">{{ i[id]?.title }}</h2>
                '
                <div class="avtar ">
                    <img alt="" src="https://image.shutterstock.com/image-vector/man-character-face-avatar-glasses-260nw-562077406.jpg " class="img-responsive img-circle " />

                    <div class="avtar-text">
                        <a class="author-name" (click)="navigateToProfile(i[id]?.uname)">
                            {{ i[id]?.uname }}</a>
                        <p class="category "><b>Category: </b>{{ i[id]?.category }}</p>
                    </div>
                    <p class="date ">
                        <b>Written on : </b>{{ i[id]?.created_date | date: "medium" }}
                    </p>
                </div>


                <div *ngIf="i[id]?.imgurl;else loading7">
                    <img alt="" *ngIf="!isImgLoaded" [src]="'https://i.pinimg.com/originals/10/b2/f6/10b2f6d95195994fca386842dae53bb2.png' " class="img-responsive" />
                    <img alt=" " [hidden]=" !isImgLoaded" [src]="i[id]?.imgurl " (load)="isImgLoaded=true " class="img-responsive ">
                    <figcaption>{{ i[id]?.title }}</figcaption>
                </div>

                <!--   <img src="{{ i[id]?.imgurl }} " class="img-responsive " />
        <figcaption>{{ i[id]?.title }}</figcaption> -->
                <!--    <p class="desc ">{{ i[id]?.desc }}</p> -->
                <p class="desc " [innerHTML]="i[id]?.desc| noSanitize"></p>


            </div>
        </div>

        <div class="row " *ngIf="postype=='private' && acrud.pr | async as i;else loading3 ">
            <div class="col-xs-12 ">
                <h2 class="title ">{{ i[id]?.title }}</h2>
                '
                <div class="avtar ">
                    <img alt="" src="https://image.shutterstock.com/image-vector/man-character-face-avatar-glasses-260nw-562077406.jpg " class="img-responsive img-circle " />

                    <div class="avtar-text ">
                        <p class="author-name ">{{ i[id]?.uname }}</p>
                        <p class="category "><b>Category: </b>{{ i[id]?.category }}</p>
                    </div>
                    <p class="date ">
                        <b>Written on : </b>{{ i[id]?.created_date | date: "medium" }}
                    </p>
                </div>

                <img src="{{ i[id]?.imgurl }} " class="img-responsive " alt="" />
                <figcaption>{{ i[id]?.title }}</figcaption>
                <!--   <p class="desc ">{{ i[id]?.desc }}</p> -->

                <p class="desc " [innerHTML]="i[id]?.desc| noSanitize"></p>
            </div>
        </div>

        <div class="row " *ngIf="postype=='allpost' && !isFetching && allPost as i;else loading5 ">
            <div class="col-xs-12 ">
                <h2 class="title ">{{ i[id]?.title }}</h2>
                '
                <div class="avtar ">
                    <img alt="" src="https://image.shutterstock.com/image-vector/man-character-face-avatar-glasses-260nw-562077406.jpg " class="img-responsive img-circle " />

                    <div class="avtar-text ">
                        <p class="author-name ">{{ i[id]?.uname }}</p>
                        <p class="category "><b>Category: </b>{{ i[id]?.category }}</p>
                    </div>
                    <p class="date ">
                        <b>Written on : </b>{{ i[id]?.created_date| date: "medium" }}
                    </p>
                </div>

                <img src="{{ i[id]?.imgurl }} " class="img-responsive post-img" alt="img" />
                <figcaption>{{ i[id]?.title }}</figcaption>
                <!-- <p class="desc ">{{ i[id]?.desc }}</p> -->

                <p class="desc " [innerHTML]="i[id]?.desc| noSanitize"></p>


            </div>
        </div>


        <div *ngIf="showComment && !isFetching">

            <hr>
            <form class="create-form " [formGroup]="CommentForm" novalidate (ngSubmit)="onSubmit(CommentForm.value) ">
                <div class="form-group ">
                    <label for="comment ">Leave Your Comment Here:</label>
                    <textarea class="form-control " rows="5 " id="comment " formControlName="comment"></textarea>

                </div>
                <div class="row submit-button-container ">
                    <div class="col-md-4 ">
                        <button class="btn btn-primary submit-button " color="primary " type="submit " [disabled]="!CommentForm.valid ">Post your comment</button>
                    </div>
                </div>
            </form>
        </div>




        <div class="col-md-12 commentsection" *ngIf="Comment_Data ">
            <hr>
            <div *ngFor="let x of Comment_Data ">
                <h4>{{x.uname}}</h4>
                <p class="date">{{x.commentOn| date:"medium"}}</p>
                <p class="data">{{x.comment}}</p>
                <hr>
            </div>

        </div>
    </div>


    <ng-template #loading>
        <div *ngIf="isFetching && isUnauth" class="loading-text ">
            <app-loading-spinner></app-loading-spinner>
        </div>

        <div *ngIf="error || errorkey " class="loading-text ">

            <h3>{{errorkey}}</h3>
            <p> {{error}}</p>
        </div>
    </ng-template>

    <ng-template #loading2>
        <div *ngIf="isFetching && postype==='public' " class="loading-text ">
            <app-loading-spinner>loaduin</app-loading-spinner>
        </div>

        <div *ngIf="error || errorkey " class="loading-text ">

            <h3>{{errorkey}}</h3>
            <p> {{error}}</p>
        </div>
    </ng-template>


    <ng-template #loading3>
        <div *ngIf="isFetching && postype==='private' " class="loading-text ">
            <app-loading-spinner></app-loading-spinner>
        </div>


        <div *ngIf="error || errorkey " class="loading-text ">

            <h3>{{errorkey}}</h3>
            <p> {{error}}</p>
        </div>
    </ng-template>

    <ng-template #loading5>
        <div *ngIf="isFetching && postype==='allpost' " class="loading-text ">
            <app-loading-spinner></app-loading-spinner>
        </div>


        <div *ngIf="error || errorkey " class="loading-text ">

            <h3>{{errorkey}}</h3>
            <p> {{error}}</p>
        </div>
    </ng-template>

    <ng-template #loading7>
        <div *ngIf="!publicpostOfSingleUser && (this.xyz[3]==='publicposts'  || xyz[1]=='featured')" class="loading-text">
            <app-loading-spinner></app-loading-spinner>
        </div>


        <div *ngIf="error || errorkey " class="loading-text ">

            <h3>{{errorkey}}</h3>
            <p> {{error}}</p>
        </div>
    </ng-template>







