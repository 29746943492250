import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ben-aac',
  templateUrl: './ben-aac.component.html',
  styleUrls: ['./ben-aac.component.css']
})
export class BenAacComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
