<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a href="/blog">Blog</a></li>
  <li>Legal Rights and Provisions</li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Legal Rights and Provisions</span>
  </h2>
  </div>
  </div>
  </div>
  </div>


<div class="mycontent">

  <p>
    The National Trust for Welfare of Persons with Autism, Cerebral Palsy, Mental Retardation and Multiple Disabilities Act, 1999 was constituted by the Central Government as a legal entity and was set up in the year 2000. </p>

It  
<p>
The RPWD Act was enacted in December 2016. It promotes and protects the rights and dignity of people with disabilities in various aspects of life – educational, social, legal, economic, cultural and political. It applies to government, non-government and private organizations. It has mandates and timelines for establishments to ensure accessibility of infrastructure and services.  </p> 

More details about the law can be found through the following link: 
<a href="https://legislative.gov.in/sites/default/files/A2016-49_1.pdf" target="_blank" >A2016-49_1.pdf (legislative.gov.in)</a>

 
</div>