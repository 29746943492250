import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-therapeutics',
  templateUrl: './therapeutics.component.html',
  styleUrls: ['./therapeutics.component.css']
})
export class TherapeuticsComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
