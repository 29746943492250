<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
    <li><a href="#">Home</a></li>
    <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
    <li>Identifying Autism </li>
</ul>

<div class="container" data-uw-styling-context="true">
    <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
        <div class="hero_innercontainer" data-uw-styling-context="true">
            <div class="hero_content" data-uw-styling-context="true">
                <h2 class="hero_title" data-uw-styling-context="true">
                    <span data-uw-styling-context="true">Identifying Autism
                    </span>
                </h2>
            </div>
        </div>
    </div>
</div>

<div class="mycontent">

    <b>Developmental Milestones </b>

    <p>Developmental milestones represent physical or behavioral signs of development in infants and children. It
        includes physical (Gross and fine motor), social, emotional, cognitive, and communication skills that kids need
        to learn as they develop and grow.As each child is different, their pace of development also can be different,
        but these milestones can be used as a checklist to understand what an average child can do around a particular
        age, especially helpful for new parents staying in nuclear families. </p>

    <p> Learn more about major developmental milestones by age of the child from birth to 6 years of age i.e. crucial
        years of the child development. </p>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Gross Motor Skills
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">


            <p>Gross motor skills involve movements of the large muscles of the arms, legs, and torso. These are
                required for everyday physical activities like walking, running, throwing, lifting, kicking, etc. </p>

            <b>0-3 months </b>

            <ul>
                <li>Starts looking up when kept on stomach </li>

                <li> Starts to get hands in midline </li>

                <li> Tries to prop up on forearms when placed on stomach </li>

                <li> Kicking both legs and moving both arms equally while on back </li>
            </ul>

            <b>3-6 months </b>
            <ul>
                <li>When held up, can hold head stead </li>

                <li> When on stomach, begins to lift head up from side to side with chest off the floor, by propping up
                    on wrists
                </li>
                <li> Begins to roll on either side </li>
            </ul>



            <b>6-9 months </b>
            <ul>
                <li>Rolls front to back to front </li>

                <li> Sits without support when made to sit </li>

                <li> Comes to sit from supine position with support </li>

                <li> Can assume quadruped position </li>

                <li> Starts to crawl and masters it by 10 months </li>

            </ul>


            <b>9-12 months </b>

            <ul>
                <li>Pulls to stand holding on to objects </li>

                <li> Starts walking sideways with support </li>
            </ul>

            <b>12-15 months </b>
            <ul>
                <li>Stands alone momentarily </li>

                <li> Walks holding onto objects </li>
            </ul>



            <b>15-18 months </b>
            <ul>
                <li>Walks alone </li>

                <li> Attempts to jump on the same spot but feet do not go off the ground </li>
            </ul>

            <b>18-22 months </b>
            <ul>
                <li>Starts to kick a big ball </li>
            </ul>

            <b>22-24 months </b>
            <ul>
                <li>Can bend down to pick a toy </li>

                <li> Can start, run, and stop without falling and can avoid obstacles </li>

                <li> Learns to climb up stairs with support by placing both feet on the same step </li>

                <li> Climbs on chair </li>
            </ul>



            <b>24-36 months </b>
            <ul>
                <li>Starts to go up and down stairs with alternate feet </li>

                <li> Starts to jump down from a height e.g., Bottom step </li>

                <li> Learns to stand on one foot </li>

                <li> Can walk short distance along a straight line and can tip toe </li>

                <li> Can throw a ball forward </li>

                <li> Starts to pedal a tricycle. </li>
            </ul>




            <b>3 to 4 Years Old </b>
            <ul>
                <li>Starts to hop on the same foot 4-6 times </li>

                <li> Can climb stairs up without support using alternate legs </li>

                <li> Can catch a large, bounced ball </li>

                <li> Can kick a ball towards an intended target </li>

                <li> Can throw a ball over head </li>

                <li> Can pedal & ride a tricycle </li>
            </ul>


            <b>4 to 5 Years Old </b>
            <ul>
                <li>Can jump forward with both feet </li>

                <li> Can hop on one leg without touching the other leg on the floor </li>

                <li> Can run well </li>

                <li> Can climb downstairs using alternate leg without support </li>

                <li> Can run & stop suddenly </li>
            </ul>


            <b>5 to 6 Years Old </b>
            <ul>
                <li>Can walk backward heel to toe </li>

                <li> Walks a balance bean </li>

                <li> Can catch a ball thrown from 3 feet </li>

                <li> Balances on either foot for ten seconds with good control </li>
            </ul>

        </div>
    </mat-expansion-panel>
    <br>




    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Fine Motor Skills
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">
            <p>
                Fine motor skills are involved in smaller movements that occur in the wrists, hands, fingers, feet, and
                toes. These skills are required to manipulate smaller objects with the hands and fingers, for example,
                grasping, holding and for many aspects of self-care like brushing, bathing, dressing etc.


            </p>


            <b>0-3 months </b>
            <ul>
                <li>Keeps hands open (not fisted) sometimes at 1 month and most of the times by 3 months </li>
            </ul>


            <b>3-6 months </b>
            <ul>
                <li>Plays with own hands and take them to mouth </li>

                <li> Starts to reach out and attempts to hold objects and people </li>

                <li> Attempts to hold larger objects using both hands </li>
            </ul>


            <b>6-9 months </b>
            <ul>
                <li>Bangs objects in play </li>

                <li> Transfers objects from one hand to another using fingers and palm </li>

                <li> Starts to grasp small objects using thumb and 2 fingers </li>
            </ul>


            <b>9-12 months </b>
            <ul>
                <li>Grip becomes more refined </li>

                <li> Starts to pick up and release small objects using thumb and index finger </li>
            </ul>

            <b>12-15 months </b>
            <ul>
                <li>Probes objects using index finger

                    Releases objects from hand voluntarily </li>
            </ul>


            <b>15-18 months </b>
            <ul>
                <li>Starts to hold pencil or a stick (in any way) and scribble </li>

                <li> Grip improves </li>

                <li> Can point using just index finger </li>

            </ul>



            <b>18-24 months </b>
            <ul>
                <li>Starts showing hand preference to hold a pencil to scribble </li>

                <li> Starts to put shapes in a simple puzzle having circle, rectangle, and triangle </li>

                <li> Can build a tower of three blocks </li>
            </ul>

            <b>24-36 months </b>
            <ul>
                <li>Holds the top or middle end of a pencil or stick with fingers and draws </li>

                <li> Tries to remove the lid of a jar </li>

                <li> Can stack and line 8-10 blocks </li>

                <li> By 3 years, starts to imitate simple shapes like horizontal lines, vertical line, and circle </li>

                <li> Can string a few large beads </li>

                <li> Can fold and snip a paper </li>

                <li> Starts to participate in coloring and painting </li>
            </ul>


            <b>3 to 4 Years Old </b>
            <ul>
                <li>Can build a tower of 8 or more blocks </li>

                <li> String beads and lace a string through holes </li>

                <li> Can match 6 to 7 simple shapes </li>

                <li> Can hold a crayon/ pencil with three fingers (tripod) </li>
            </ul>

            <b>4-5 Years Old </b>
            <ul>
                <li>Can use a crayon/pencil using three fingers (tripod) </li>

                <li> Can cut shapes Square and circle </li>

                <li> Can draw a simple human figure with a head & 2-3 other body part </li>

                <li> Can write few alphabets </li>

                <li> Can build a pyramid and complete simple construction games </li>
            </ul>

            <b>5 to 6 Years Old </b>
            <ul>
                <li>Can draw figures, pictures with windows, door, and curtains </li>

                <li> Can color between lines </li>

                <li> Cuts on a line with scissors </li>

                <li> Can write alphabets and few words </li>

                <li> Hand dominance gets established </li>
            </ul>


        </div>
    </mat-expansion-panel>
    <br>








    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Language Skills
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">

            <b>0- 3 months </b>
            <ul>
                <li>Relaxes when held </li>

                <li> Makes different sounds to indicate when happy, irritable, or hungry </li>

                <li> Alerts when talked to, slows down movements to listen and attend </li>

                <li> Shows recognition by watching face when caregiver speaks </li>
            </ul>


            <b>3 -6 months </b>
            <ul>
                <li>Vocalizes vowels "aa, uu" </li>

                <li> Laughs aloud </li>

                <li> Responds by making sounds when caregivers talk </li>
            </ul>


            <b>6-9 months </b>
            <ul>
                <li>Makes vowels-consonants sounds ("ga, de") </li>

                <li> Uses gestures (shakes head in protest, lifts arms to be picked up) </li>
            </ul>

            <b>9-12 months </b>
            <ul>
                <li>Repeats syllables ("da-da") </li>

                <li> Understands names of familiar people (mummy, daddy, sister) and objects in home and surroundings
                </li>

                <li> Can use gestures along with sounds and eye gaze </li>

                <li> Follows communication through actions, context, and intonation </li>
            </ul>


            <b>12-15 months </b>
            <ul>
                <li>Uses one to two words meaningfully </li>

                <li> Uses arm or hand to point at people or objects </li>

                <li> Understands verbs/action words (come, take, stop) and names of objects </li>

                <li> Waves "bye" or uses other common gesture in response to command </li>
            </ul>

            <b>15-18 months </b>
            <ul>
                <li>Uses index finger to point </li>

                <li> Understands one command (bring shoes) and starts using varying intonation patterns e.g., "dolly? -
                    questioning is this my doll? "dolly!“-demanding the doll </li>

                <li> Has a vocabulary of 10-15 words </li>
            </ul>


            <b>18-22 months </b>
            <ul>
                <li>Uses more than 5 words meaningfully </li>

                <li> Most speech is understood by the caregiver </li>

                <li> Can follow more than one single step commands </li>
            </ul>


            <b>22-26 months </b>
            <ul>
                <li>Has a vocabulary of at least 50 words </li>

                <li> Uses combination of words and gestures to communicate desires</li>

                <li> Listens to brief stories or when caregivers narrate an event (going to store, going to neighbors)
                </li>

                <li> Use negatives like 'no' or 'not' </li>

                <li> Ask questions like "where?", "what? </li>
            </ul>

            <b>26-30 months </b>
            <ul>
                <li>Strangers understand some of child's communication </li>

                <li> Uses two words phrases like "give water", "mama apple" </li>

                <li> Answers simple questions like "Is mummy home?" -> "yes" or "no" </li>

                <li> Understands instructions out of context "let's go wash your hands" </li>
            </ul>

            <b>30-36 months </b>
            <ul>
                <li>Uses sentences with 2-3 words to communicate and has a vocabulary of 100-200 words</li>

                <li> Uses pronouns, verbs, and plurals </li>

                <li> Strangers understand most of child's communication </li>

                <li> Understands one preposition other than 'in' such as 'under' or 'on top' </li>

                <li> Understands two step commands ("go to the kitchen, bring me your plate", "go to your room, and
                    bring your book")</li>

                <li> Understands simple stories from books </li>

                <li> Understands many different concepts e.g., categories/ shapes/ color etc. </li>

                <li> Begins to narrate small events/stories </li>
            </ul>

            <b>3-4 Years Old </b>
            <ul>
                <li>Speaks in 3-4 word sentences which is understood by caregivers and by strangers </li>

                <li> Asks questions- like 'who' Can answer simple questions- 'who, what, where </li>

                <li> Can tell own age </li>

                <li> Understands most nouns and vocabulary of 900-1000 words </li>

                <li> Grammatically sentences are more complex and correct </li>
            </ul>




            <b>4-5 Years Old </b>
            <ul>
                <li>Uses sentences of 4-8 words that are mostly grammatically correct. </li>

                <li> Asks why questions </li>


                <li> Sings nursery rhymes and narrates a short story! </li>


                <li> Speech is totally understood by family and strangers </li>


                <li>Says first and last name </li>


                <li> Understands complex prepositional concepts, like behind, in front of, next to and other concepts
                    like "real, imaginary" "same, different" </li>


                <li> Able to link two ideas together e.g. "I am not allowed the sweet because I was naughty". They use
                    because, so, but, and then etc. </li>


                <li>Able to follow stories and complex questions (e.g., what would happen if there was no rain?) </li>


                <li> Follow 3 step directions </li>
            </ul>

            <b>5-6 Years Old </b>
            <ul>
                <li>Correctly repeats a story and elaborates stories </li>

                <li> Speaks fluently and pronounces words more dearly and asks 'why' and 'how' questions and uses
                    correct grammar in most cases. </li>

                <li> Uses past tense and future tense correctly. Can understand 6000 words </li>

                <li> Can start and continue a conversation with children and adults </li>

                <li> Can follow multi-steps instructions and groups instructions </li>

                <li> Understands and remembers stories told or read </li>

                <li> Narrates complete events/ stories in sequence with theme, character, action, emotion, and result
                </li>

                <li> Talks about feelings and uses language in social situations to solve conflicts (to negotiate and
                    problem solve) </li>

                <li> Follows multistep unrelated commands </li>

                <li> Answers more complex "who, what, where, when" questions </li>

                <li> Follows and participates in conversations. Can have a vocabulary of 2500 plus words </li>

                <li> Enjoys jokes and riddles and understands sarcasm </li>

                <li> Can give rhyming words after examples </li>
            </ul>







        </div>
    </mat-expansion-panel>
<br>




    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Play, social and cognitive skills
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">
            <p>Play skills are crucial for children for social, psychological, and physical development that can affect
                their lives and relationships as adults. Social skills are required to communicate effectively with
                others to maintain and improve interpersonal relationships. Cognitive skills are a determining factor of
                an individual's learning ability and are necessary for reading, writing, math, and for understanding the
                world around them. </p>

            <b>0-3 months </b>
            <ul>
                <li>Engages when approached playfully by caregivers (moves limbs) </li>

                <li> Looks and follows face/objects </li>

                <li> Smiles back to caregiver's playful approaches </li>
            </ul>



            <b>3-6 months </b>
            <ul>
                <li>Makes sounds in response to play </li>

                <li> Grasps toys/objects with interest (not reflex) </li>

                <li> Shakes toys/objects in play </li>

                <li> Brings toys/ objects, toes to mouth </li>

                <li> Looks at own hands </li>

                <li> Shows desire to engage with people by looking, smiling, reaching, vocalizing </li>

                <li> Shows preference and recognition of caregivers by reaching, smiling, inspecting their faces </li>

                <li> Plays with toys, people and objects, and fingers </li>
            </ul>

            <b>7-9 months </b>
            <ul>
                <li>Responds to interactive play like "peek-a-boo" </li>

                <li> Throws, bangs toys/objects </li>

                <li> Reacts when caregiver leaves, relaxes when she reunites </li>
            </ul>




            <b>9-12 months </b>
            <ul>
                <li>Looks for toys/objects if they disappear </li>

                <li> Inspects toys/objects with curiosity, looks at some detail </li>

                <li> Imitates gestures during play (clapping hands, make face) </li>

                <li> Shows recognition of strangers in some way. May turn away from strangers in anxiety, caution,
                    shyness, or fear (persists beyond 12 months) </li>
            </ul>



            <b>12-18 months </b>

            <ul>
                <li>Initiates game "peek-a-boo" </li>

                <li> Inspects how toys/objects work (how wheels turn, doll moves, bells ring, lights turn on) </li>

                <li> Fills and empties containers </li>

                <li> Imitates others' behaviors (waving back, scribbling, washing hands, stacking clothes in imitation)
                </li>

                <li> Spontaneously seeks to share enjoyment and interest with others (cuddles caregiver, kisses,
                    inspects toy together) </li>

                <li> Likes looking at books </li>
            </ul>



            <b>18-24 months </b>
            <ul>
                <li>Turns more than one page at a time </li>

                <li> Turns single page by 2 years </li>

                <li> Opens doorknobs and cupboards </li>

                <li> By 2 Years, enjoys imitating domestic activities like sweeping, washing </li>

                <li> Starts to participate and enjoy in simple pretend play </li>
            </ul>

            <b>24-36 months </b>

            <ul>
                <li>Pretend play now involves combining ideas and using different objects e.g., dolls, cars </li>

                <li> Initiates specific interactions with specific people, can play in a small group and starts to
                    understand turn taking </li>

                <li> Can associate objects/toys meaningfully- what would this mean? </li>

                <li> Can sit and look at picture books and attend to small stories Imitates adult actions- role playing
                </li>

                <li> Can tell his name </li>

                <li> Names body parts, can identify some colors, items clothing </li>

                <li> Begins to understand concepts like size, shapes, numbers </li>
            </ul>


            <b>3 to 4 years </b>
            <ul>
                <li>Has some understanding of turn-taking, sharing toys </li>

                <li> Engages in complex pretend play at home with two or more ideas and starts to involve other people
                    in their play-e.g., Pretending to cook and serving tea to mother </li>

                <li> Likes to help adults with simple household activities </li>

                <li> Enjoys playing with siblings </li>

                <li> Begins to make friends </li>
            </ul>




            <b>4 to 5 Years </b>

            <ul>
                <li>Cooperative play with other children </li>

                <li> Pretend play continues to be complex can act scenarios from school/home, soldiers/warriors, etc.
                </li>

                <li> Can now understand that other people have emotions </li>

                <li> Has special friends </li>
            </ul>

            <b>5 to 6 Years </b>
            <ul>
                <li>Cooperates with friends most of the time </li>

                <li> Understands rules and fair play </li>

                <li> Spends a lot of time in construction games </li>

                <li> Can play games with rules, takes turns for length of time for e.g., football and cricket </li>

                <li> Chooses own friends </li>

                <li> Begins to understand and articulate what other people may be thinking and what is on their mind
                    (e.g.: "if I don't share my toys, my friends will not like me")</li>

                <li> Is tender and protective towards younger children and can comfort a friend who is upset </li>
            </ul>





        </div>
    </mat-expansion-panel>
<br>


    <h2>Activities of daily living </h2>


    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Eating
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">
            <b>4-8 months </b>
            <ul>
                <li>Adjusts feeding times to the family's schedule; usually takes three or four feeding per day, each 6
                    to 8 ounces, depending on sleep schedule </li>

                <li> Shows interest in feeding activities; reaches for cup and spoon while being fed </li>

                <li> Begins to accept small amount of solid foods, such as cereal and pureed vegetables when placed well
                    back on tongue (if placed on tip, infant will push it back out) </li>

                <li> Closes mouth firmly or turns head away when hunger is satisfied </li>
            </ul>

            <b>8-12 months </b>
            <ul>
                <li>Eats three meals a day plus mid-morning or mid-afternoon snacks, such as juice and crackers </li>

                <li> Enjoys drinking from a cup; holds own cup: will even tilt head backward to get the last drop </li>

                <li> Begins to eat finger foods </li>
            </ul>


            <b>1-2 Years Old </b>
            <ul>
                <li>Sometimes described as a picky or fussy eater; often has strong likes and dislikes (which should be
                    respected); may go on food jags (only eating certain foods, such as peanut butter and jelly
                    sandwiches, macaroni, and cheese) </li>

                <li> Likes simple, "recognizable" food; dislikes mixtures; wants foods served in familiar ways </li>

                <li> Learns table manners by imitating adults and older children </li>
            </ul>

            <b>2-3 Years old </b>
            <ul>
                <li>Feeds self independently if hungry. Uses spoon in semi-adult fashion; may spear with fork </li>

                <li> Can pour milk and juice with fewer spills, can serve individual portions from a serving dish with
                    some prompts ("fill it up to the line"; "take only two spoonful" </li>
            </ul>

        </div>
    </mat-expansion-panel>
<br>


    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Toileting, bathing, dressing
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">
            <b>4-8 months </b>
            <ul>
                <li>Pulls off own socks; plays with strings, buttons, and Velcro closures on clothing </li>

                <li> Has one bowel movement per day generally </li>
            </ul>

            <b>8-12 months </b>
            <ul>
                <li>Loves to let water drip from sponge or washcloth </li>

                <li> Shows great interest in pulling off hats, taking off shoes and socks</li>

                <li> Fusses when diaper needs changing; may pull off soiled or wet diaper </li>
            </ul>

            <b>1-2 Years Old </b>
            <ul>
                <li>Enjoys bath if allowed ample playtime (must never be left alone); may object to being washed; tries
                    to wash self </li>

                <li> Tries to help when being dressed; needs Simple, manageable clothing, can usually undress self </li>

                <li> Shows signs of readiness for bowel training (some children may have already mastered bowel control)
                </li>
            </ul>



            <b>2-3 Years old </b>
            <ul>
                <li>Achieves almost complete control of bowel and bladder (boys may continue to have wet-pants days)
                </li>

                <li> Takes care of own toilet needs during the daytime </li>

                <li> Better at undressing than dressing, though capable of putting on some articles of clothing </li>
            </ul>



        </div>
    </mat-expansion-panel>
<br>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Sleeping
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">

            <b>4-8 months </b>
            <ul>
                <li>No longer wakes up for a late-night feeding </li>

                <li> Sleeps eleven to thirteen hours through the night </li>
            </ul>


            <b>8-12 months </b>
            <ul>
                <li>Willing to go to bed, may not fall asleep immediately, but will play or walk about in crib, then
                    fall asleep on top of covers </li>

                <li> Plays alone and quietly for fifteen to thirty minutes after awakening; then begins to make
                    demanding noises, signaling the need to be up and about </li>

                <li> Takes one afternoon nap most days </li>
            </ul>

            <b>1-2 Years Old </b>
            <ul>
                <li>Amount of nighttime sleep varies (between nine and twelve hours) </li>

                <li> Takes a while to fall asleep, especially if overtly tired; may sing, talk to self, bounce on bed,
                    call for parents, make and remake the bed again (ways of "winding down") </li>
            </ul>




            <b>2-3 Years old </b>
            <ul>
                <li>Usually sleeps ten to twelve hours at night, wakes up at about 7 to 8 am </li>

                <li> Can get ready for bed independently. Does not require many earlier bedtime rituals like patting,
                    rocking but still needs a bedtime story, a song and tucking-in. </li>

                <li> May have dreams that cause the child to wake up with a startle </li>
            </ul>

            <b>3-4 Years Old </b>
            <ul>
                <li>Likes to do things independently Indicates toilet needs but needs help </li>

                <li> Can wash hands independently </li>

                <li> Can finger feed herself and use spoon </li>

                <li> Can unzip pants </li>

                <li> Can pull Velcro on shoes to remove shoes </li>

                <li> Begins to feed self but is clumsy </li>

                <li> Begins to unbutton </li>
            </ul>


            <b>4-5 Years Old </b>
            <ul>
                <li>Can unbutton, button, and zip independently </li>

                <li> Can get ready to go to school with some supervision </li>

                <li> Can brush teeth and comb hair and wash and dry hands </li>

                <li> Can pour milk/water into a cup </li>

                <li> Can use a spoon to feed self </li>

                <li> Can wear shoes with Velcro </li>
            </ul>

            <b>5-6 Years Old </b>
            <ul>
                <li>Can dress and feed independently with minimal adult supervision </li>

                <li> Can now wash herself after using the toilet </li>

                <li> Begins to bath and dress independently with some supervision </li>

                <li> Can wear shoes with laces </li>
            </ul>

        </div>
    </mat-expansion-panel>
<br>


    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="head3">Academics

                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="mycontent1">
            <b>3 to 4 Years Old </b>
            <ul>
                <li>Identifies at least 2 colors </li>

                <li> Can draw horizontal line, vertical line, and a circle </li>

                <li> Can recognize her written name </li>

                <li> Can identify common shapes such as circle, square, triangle </li>
            </ul>

            <b>4 to 5 Years Old </b>
            <ul>
                <li>Can copy a cross, square, and can trace over a line </li>

                <li>
                    Counts 1-10 by rote </li>


                <li>
                    Can draw a simple person with a head and 2-3 other features </li>


                <li>
                    Child starts trying to write a few letters of alphabet </li>
            </ul>

            <b>5 to 6 Years Old </b>
            <ul>
                <li>Names 6 basic colors and 3 basic shapes </li>

                <li>
                    Can write alphabets within lines and numbers in boxes </li>


                <li>
                    Can count to 50 by rote </li>


                <li>
                    Draws human figure with many parts </li>
            </ul>

            <b><i>Reference: Dr. Anjali Joshi, Occupational Therapist </i></b>
        </div>
    </mat-expansion-panel>
<br>

        </div>