<section class="bg-half d-table w-100"></section>

<!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">

                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div> 
      </div>
    
</nav> -->


<app-blog-header></app-blog-header>
            







<div class="col-md-10 col-sm-12 col-xs-12 center-block">
    <div *ngIf="!isFetching && d4;else loading">
        <form class="create-form" [formGroup]="exampleForm" novalidate (ngSubmit)="onSubmit(exampleForm.value)">

            <div class="col-md-4 col-sm-3 col-xs-12">
                <label for="comment">Thumbnail Image:</label>
                <input type="file" multiple (change)="detectFiles($event) " accept="image/x-png,image/gif,image/jpeg" class="form-control" formControlName="imgurl">
                <img id="blah" height="200" width="200" class="img-rounded" [src]="imageSrc || 'http://placehold.it/180'" alt="your image" />

                <div>{{ uploadPercent | async }}</div>
                <a href="{{downloadURL}}">your url is View your Image here</a>
            </div>
            <br>

            <div class="col-md-8 col-sm-9 col-xs-12">


                <div class="form-group ">
                    <label for="comment">Title:</label>
                    <div class="input-style">
                        <input placeholder="Title" class="form-control" formControlName="title">
                    </div>
                    <ng-container *ngFor="let validation of validation_messages.title">
                        <div class="help-block has-error" *ngIf="exampleForm.get('title').hasError(validation.type) && (exampleForm.get('title').dirty || exampleForm.get('title').touched)">
                            {{validation.message}}</div>
                    </ng-container>
                </div>


                <div class="form-group">
                    <label for="comment">Description:</label>
                    <div>
                        <quill-editor
                          [styles]="{ height: '100vh' }"
                          formControlName="desc"
                          [modules]="modules"
                        ></quill-editor>
                      </div>
                    <!-- <textarea class="form-control" rows="5" id="comment" formControlName="desc"></textarea>
                    <ng-container *ngFor="let validation of validation_messages.desc">
                        <div class="help-block has-error" *ngIf="exampleForm.get('desc').hasError(validation.type) && (exampleForm.get('desc').dirty || exampleForm.get('desc').touched)">
                            {{validation.message}}</div>
                    </ng-container> -->
                </div>

                <div class="form-group">
                    <label for="sel1">Category list:</label>
                    <select class="form-control" formControlName="category" #t (change)="onChange(t.value)">
                        <option *ngFor="let value of values">{{value}} </option>
                    </select>

                    <ng-container *ngFor="let validation of validation_messages.category">
                        <div class="help-block has-error" *ngIf="exampleForm.get('category').hasError(validation.type) && (exampleForm.get('category').dirty || exampleForm.get('category').touched)">
                            {{validation.message}}</div>
                    </ng-container>


                </div>
                <div class="form-group">
                    <div class="input-style" *ngIf="selected == 'Other'">
                        <input placeholder="Other category" class="form-control" formControlName="subcategory">
                    </div>
                </div>


                <div class="form-group">
                    <label for="comment">Name:</label>
                    <div class="input-style">
                        <input placeholder="Name" class="form-control" formControlName="name">
                    </div>
                    <ng-container *ngFor="let validation of validation_messages.name">
                        <div class="help-block has-error" *ngIf="exampleForm.get('name').hasError(validation.type) && (exampleForm.get('name').dirty || exampleForm.get('name').touched)">
                            {{validation.message}}</div>
                    </ng-container>
                </div>

                <div class="form-group ">
                    <label for="comment">Keep your post Public:</label>
                    <div class="input-style">
                        <label class="md-check">
                            <input type="radio" value="true" name="privacy" formControlName="privacy" checked="true">
                            Yes keep my post Public
                        </label>
                        <br>
                        <label class="md-check">
                            <input type="radio" value="false" name="privacy" formControlName="privacy">
                            No keep it private
                        </label>
                    </div>
                    <ng-container *ngFor="let validation of validation_messages.title">
                        <div class="help-block has-error" *ngIf="exampleForm.get('title').hasError(validation.type) && (exampleForm.get('title').dirty || exampleForm.get('title').touched)">
                            {{validation.message}}</div>
                    </ng-container>
                </div>
                <div class="row submit-button-container">
                    <div class="col-md-4">
                        <button class="btn btn-primary submit-button" color="primary" type="submit" [disabled]="!exampleForm.valid">Create</button>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <ng-template #loading>
        <div *ngIf="isFetching && !error" class="loading-text">
            <app-loading-spinner></app-loading-spinner>
        </div>

        <div *ngIf="error" class="loading-text">


            <p> {{error}}</p>
        </div>
    </ng-template>
</div>
<!-- <a class="btn btn-primary submit-button" (click)="somedata()" color="primary" type="submit">Create</a> -->