<section class="bg-half  d-table w-100" id="home"></section>

<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/know-centre">Knowledge Centre</a></li>
  <li>Vocational Training </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Vocational Training</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
 <div class="mycontent"> 
  
    
<p>Vocational training is a training program to teach a particular set of skills required for a particular occupation. Pre-vocational and vocational training help the youngster with autism develop such a skill. This training helps them to get employment that can give them a sense of self-esteem, independence, confidence, dignity, and a sense of accomplishment.
  Pre-vocational training can be started from age of 14 years, after the functional assessment of the child, his strengths, and interests. It basically focuses on independent life skills and basic skills required for occupations, such as matching, sorting, matching to jigs, measurements, money, and time concepts, assembly, and packaging, housekeeping work, etc.
  When the youngster gets proficient in pre-vocational skills, he starts getting trained to work without supervision and to work in  different work environments. He is trained to:
  </p>
  <ul>
    <li>	Initiate and respond to interactions with familiar and unfamiliar people,</li>
      <li>	understand and follow unwritten rules,</li>
      <li>	maintain an acceptable level of hygiene and grooming,</li>
      <li>	Recognize and manage anxiety and other responses to sensory stimulation.
      </li>
  </ul>
 </div>