<nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

            
        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    <!--      <a routerLink="/login">login</a> -->
                </li>
                <li routerLinkActive="active">
                    <!--    <a routerLink="/signup">Sign-up</a> -->
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav>

