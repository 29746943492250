<section class="bg-half  d-table w-100" id="home"></section>

<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
  <li>Causes and Prevalence </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Causes & Prevalence</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
<div class="mycontent">
  
    <!-- <div class="head2">
      <h2><b>Causes & Prevalence</b></h2>
    </div>
    <hr> -->
    <br>
    <div class="head2">
      <h3>Causes of Autism</h3>
    </div>
    <hr>
    <p>
      The exact cause of autism is still unknown. But research suggests that
      autism develops from a combination of genetic and non-genetic, or
      environmental, influences. As there are different levels of severity and
      combinations of symptoms in autism, there are probably multiple causes.
      <br>
      Timing of exposure during the child's development (before, during, or
      after birth) may also play a role in the development or final presentation
      of the disorder.
    </p>
    <ul>
      <li>
        <b> Genetic factors -</b> Research has shown that autism may develop due
        to genetic changes in an early embryo or the sperm and/or egg that
        combine to create the embryo. Also, among identical twins, if one child
        has autism, then the other will be affected about 75% of the time. Among
        non-identical twins, if one child has autism, then the other has it
        about 3% of the time.
      </li>
      <li>
        <b>Environmental factors -</b> These include prenatal risk factors such
        as teratogens, pesticides, and maternal antibodies; some perinatal and
        obstetric conditions; and a range of postnatal factors, such as
        gastrointestinal or immune system abnormalities, allergies, and exposure
        of children to drugs, vaccines, infection, certain foods, or heavy
        metals.
      </li>
      <li>
        It is well established that Autism is NOT caused by bad parenting,
        neglect, unhappy home environment, both parents working, poor handling
        by the mother, stress, or any other psychological factors. There is
        nothing a parent or caregiver can do to cause the disorder to occur.
      </li>
    </ul>
    <br>
    <div class="head2">
      <h3>Prevalence</h3>
    </div>
    <hr>
    <div class="row">
      <div class="column" >
    <ul>
      <li>
        According to estimates from Centers for Disease Control's  Autism and Developmental Disabilities
        Monitoring (ADDM) Network 2018 data, about 1 in 44 children in the
        United State have been identified with autism spectrum disorder.
      </li>
      <li>
        ASD is reported to occur in all racial, ethnic, and socioeconomic
        groups. Family income, lifestyle and educational levels of parents do
        not affect the chance of autism's occurrence.
      </li>
      <li>ASD is more than 4 times more common among boys than among girls.</li>
      <li>
        About 1 in 6 (17%) children aged 3-17 years were diagnosed with a
        developmental disability, as reported by parents, during a study period
        of 2009-2017. These included autism, attention-deficit/hyperactivity
        disorder, blindness, and cerebral palsy, among others.
      </li>
    </ul>

    <p>
      The number of people getting a diagnosis of autism is increasing.
      Previously, when autism was not well defined, many of the people with
      autism used to get diagnosis of mental retardation, schizophrenia, or
      attention deficit hyperactivity disorder (ADHD) But as the awareness about
      autism has increased over a period of time, more of these children receive
      autism as a primary diagnosis and hence there is an increase in diagnosis
      of autism, on both 'ends'of the spectrum.
    </p>

    <b>Reference:</b>
    <a href="https://www.cdc.gov/ncbddd/autism/data.html " target="_blank">
      https://www.cdc.gov/ncbddd/autism/data.html</a
    >
  </div>
  <div class="column1" >
    <img src="assets/images/content/mydown-img/pranav1.jpeg"  >
    
  </div>
</div>
    
    
  </div>

