import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { CrudService } from 'src/app/core/components/Unauthenticated/shared/crud.service';
import { ACrudService } from '../shared/acrud.service';
import { AuthService } from 'src/app/Authentication/shared/auth.service';


@Component({
  selector: 'app-blog-header',
  templateUrl: './blog-header.component.html',
  styleUrls: ['./blog-header.component.css']
})
export class BlogHeaderComponent implements OnInit {







  isAuthenticated = false;
  
  ProfieData: { uname: any; desc: string; email: string; name: any; created_date: Date; imgurl: Observable<string>; isProfileSet: boolean };
  isprofileSet: boolean = false
  
  private userSub: Subscription;
  allpostcount: number = 0;
  isemailverfied: boolean

  isloading: boolean = false
  
  username: any;
  











  constructor(
    private cd: CrudService,
    private acrud: ACrudService,
    private authService: AuthService,
    private toastr: ToastrService

  ) { 


    this.acrud.username.subscribe(d => {
      this.username = d

    })


  }

  ngOnInit(): void {







    this.isloading = true
    this.authService.autoLogin();
    this.authService.isLoggedIn1()
    this.userSub = this.authService.user.subscribe((user: any) => {
      this.isloading = false
  
      if (user) {
        this.isemailverfied = user.emailVerified
  
      }
      this.isAuthenticated = !!user;
      console.log(!user);
      console.log(!!user);
  
      console.log(this.isAuthenticated)
      if (this.isAuthenticated) {
        this.acrud.getProfileFromUid(user.uid).subscribe(
          d => {
            let x2: {} = {}
  
            let x = this.acrud.seprate(d)
  
  
            this.ProfieData = x[0]
            if (this.ProfieData) {
              this.isprofileSet = this.ProfieData.isProfileSet
              this.username = this.ProfieData.uname
  
            }
  
          }
        )
  
      }
    });










  }









  onLogout() {
    this.authService.logout();
  }



}
