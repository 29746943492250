<section class="bg-half  d-table w-100" id="home"></section>


<!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

            
        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav> -->


<app-blog-header></app-blog-header>








<div class="container">



    <div class="row">
        <div class="col-md-5 col-xs-12 col-md-push-7">


            <div *ngIf="(featuredPost  | filter:searchText ) as result">
                <h3 class="Featured">Featured Postss</h3>
                <div *ngIf="!isFetching && result?.length>0">
                    <div class="card row card-featured" *ngFor="let i of result ;let c= index ">
                        <a [routerLink]="['/featured',c]">
                            <div class="img img-col">
                                <img src="{{i.imgurl}} " style="width:100%" class="img-responsive" alt="">
                            </div>
                            <div class="title main-content">
                                <h3 class="title">{{ i.title}}</h3>
                                <!--   <p> {{i.desc}}</p> -->
                                <p [innerHTML]="i.desc | noSanitize"></p>

                                <div class="author-date">
                                    <p class="author-name">By {{i.name}}</p>
                                    <p class="date">On {{i.created_date | date:'mediumDate' }}</p>
                                </div>

                                <div class="likecomment">
                                    <i style="font-size:21px" class="fa fa-heart"><span>{{i.likestatus.count}}</span></i>
                                    <i style='font-size:21px' class='fa fa-comment-o'>{{commenData[c].length}}</i>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>

                <div class="text-left no-result">
                    <h4 *ngIf="result.length === 0">No Results Found</h4>
                </div>

            </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 col-md-pull-5">

            <div class="search-hero search-input-wrapper fadeInUp hidden-xs hidden-sm">
                <label for="search1"></label>
                <input class="form-control" type="text" id="search1" name="search1" [(ngModel)]="searchText" autocomplete="off" placeholder="   Search by Title or Author name">
                <div class="search-button"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                    </svg></div>
            </div>
            <div *ngIf="(sorted | filter:searchText) as result ">
                <h3 class="Featured hidden-md hidden-lg">Latest Posts</h3>
                <div *ngIf="!isFetching && result?.length>0">




                    <div class="card row" *ngFor="let i of result;let c= index ">
                        <a [routerLink]="[c]">

                            <div class="col-md-4 col-xs-12 img-col col-md-push-8">
                                <img src="{{ i.imgurl }} " alt="Avatar " style="width: 100%;" class="img-responsive" alt="img" />


                            </div>
                            <div class="col-md-8 col-xs-12 main-content col-md-pull-4">
                                <h3 class="title">
                                    {{ i.title }}
                                </h3>

                                <p class="desc desc1" [innerHTML]="i.desc| noSanitize"></p>
                            </div>
                            <div class="author-date col-md-6 col-xs-12">
                                <p class="author-name">
                                    By : {{ i.name }}
                                </p>
                                <p class="date">
                                    On : {{ i.created_date | date }}
                                </p>
                            </div>
                        </a>

                        
                         <!-- <div class="col-md-4 col-xs-5 img-col">
                            <img src="{{i.imgurl}}" alt="Avatar" style="width:100%" class="img-responsive" alt="">
                         </div> -->
                    </div>
                </div>
                <div class="loading-text text-left no-result">
                    <h4 *ngIf="result.length === 0">No Results Found</h4>
                </div>
            </div>


        </div>





        <!-- <ng-template #loading>
        <div *ngIf="isFetching && !error" class="loading-text">
            <div class="loader"></div>
            <p> loading Contet...</p>
        </div>



        <div *ngIf="error && !isFetching" class="error-text">
            <h4>{{error}}</h4>

        </div>
    </ng-template>
    <div class="col-sm-4 col-xs-12">
    </div> -->

        <div *ngIf="isFetching && !error" class="loading-text">
            <app-loading-spinner></app-loading-spinner>
        </div>

        <div *ngIf="error && !isFetching" class="error-text">
            <h4>{{error}}</h4>

        </div>
    </div>
</div>


<router-outlet></router-outlet>
