import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ACrudService } from 'src/app/Authentication/shared/acrud.service';
import { CrudService } from 'src/app/core/components/Unauthenticated/shared/crud.service';
import { UPost } from 'src/app/core/components/Unauthenticated/shared/UPost.model';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit { 

  // @Input() blogData: Array<{
  //   image: string;
  //   title: string;
  //   like: string;
  //   message: string;
  //   name: string;
  //   date: string;
  // }>;

  data: UPost[];
  sorted: UPost[];
  featuredPost: any
  isFetching: boolean = false
  error: string
  searchText
  featuredPostsorted: any[];
  commenData: any = []


  x
  ProfieData: { uname: any; desc: string; email: string; name: any; created_date: Date; imgurl: Observable<string>; isProfileSet: boolean };
  isprofileSet: boolean = false
  username: string
  

  constructor(
    private cd: CrudService,
    private acrud: ACrudService,
    
    private toastr: ToastrService



  ) { 
    this.acrud.username.subscribe(d => {
      this.username = d

    })
   
  }

  ngOnInit(): void {

    this.getAllPost();
  }

  getAllPost() {
    this.isFetching = true;
    this.acrud.getAllPost().then((x: any ) => {
      this.isFetching = false
      this.data = x
      this.sortDesecending(this.data)

    })
  }
  sortDesecending(data) {
    this.sorted = data.sort((a: any, b: any) =>
      <any>new Date(b.created_date) - <any>new Date(a.created_date)
    )
  }
  onReadMore(index) {

  }

 

}

