<section class="bg-half d-table w-100"></section>




<!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

            
        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav> -->


<app-blog-header></app-blog-header>



<div class="container">




    <div class="col-md-12 col-sm-9 col-xs-12">
        <form class="create-form" [formGroup]="ProfileForm" novalidate (ngSubmit)="onSubmit(ProfileForm.value)">

            <div class="col-md-4 col-sm-3 col-xs-12">
                <input type="file" multiple (change)="detectFiles($event) " accept="image/x-png,image/gif,image/jpeg" class="form-control" formControlName="imgurl">
                <img alt="" id="blah" height="200" width="200" class="img-rounded" [src]="imageSrc || 'http://placehold.it/180'" alt="your image" />

                <div>{{ uploadPercent | async }}</div>
                <a href="{{downloadURL}}"> Click Here To View Your Image</a>
            </div>
            <br>
            <div class="col-md-8 col-xs-12">


                <div class="form-group ">
                    <label for="comment">Username:</label>
                    <div class="input-style">
                        <input placeholder="User Name" class="form-control" formControlName="uname" (focus)="clearError()" (blur)="checkUsername ($event.target.valueOf())">
                    </div>
                    <ng-container *ngFor="let validation of validation_messages.uname">
                        <div class="help-block has-error" *ngIf="ProfileForm.get('uname').hasError(validation.type) && (ProfileForm.get('uname').dirty || ProfileForm.get('uname').touched)">
                            {{validation.message}}</div>
                    </ng-container>
                    <ng-container *ngIf="erroUsername && !oldusername">
                        <div class="help-block has-error" *ngIf="ProfileForm.get('uname') && (ProfileForm.get('uname').dirty || ProfileForm.get('uname').touched)">
                            {{erroUsername}}</div>
                    </ng-container>
                </div>


                <div class="form-group ">
                    <label for="comment">Email:</label>
                    <div class="input-style">
                        <input placeholder="User Name" class="form-control" formControlName="email">
                    </div>
                    <ng-container *ngFor="let validation of validation_messages.email">
                        <div class="help-block has-error" *ngIf="ProfileForm.get('email').hasError(validation.type) && (ProfileForm.get('email').dirty || ProfileForm.get('email').touched)">
                            {{validation.message}}</div>
                    </ng-container>
                </div>





                <div class="form-group">
                    <label for="comment">Name:</label>
                    <div class="input-style">
                        <input placeholder="Name" class="form-control" formControlName="name">
                    </div>
                    <ng-container *ngFor="let validation of validation_messages.name">
                        <div class="help-block has-error" *ngIf="ProfileForm.get('name').hasError(validation.type) && (ProfileForm.get('name').dirty || ProfileForm.get('name').touched)">
                            {{validation.message}}</div>
                    </ng-container>
                </div>

                <div class="form-group">
                    <label for="comment">Description:</label>
                    <textarea class="form-control" rows="5" id="comment" formControlName="desc"></textarea>

                </div>

                <div class="row submit-button-container">
                    <div class="col-md-4">
                        <button class="btn btn-primary submit-button" color="primary" type="submit" [disabled]="!ProfileForm.valid || erroUsername">Submit</button>
                    </div>
                </div>
            </div>
        </form>

    </div>
</div>
<br>