<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
  <li>FAQ's </li>
</ul>

<div class="container">
  <div class="hero hero--solid hero--secondary hero--titlefix">
    <div class="hero_innercontainer">
      <div class="hero_content">
        <h2 class="hero_title">
          <span>FAQ's</span>
        </h2>
      </div>
    </div>
  </div>
</div>


<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q1. How do I know whether my child is autistic?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    There are no medical tests that can detect autism, a sensitive and experienced doctor, developmental pediatrician,
    child psychologist, child psychiatrist, pediatric neurologist can diagnose autism. Identification and diagnosis of
    autism of the child is based on parent’s interview, child’s developmental history, speech and language assessment,
    educational and psychological testing, and observation of the child’s behavior. For more information on a diagnosis
    of autism <a  routerLink="/ident-autism" >click here.</a>
  </div>
</mat-expansion-panel>

<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q2. Why my child has autism?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>The exact cause of autism is still unknown. But research suggests that autism develops from a combination
      of genetic and non-genetic, or environmental, influences. It is observed that, autism runs in families, so certain
      combinations of genes may increase a child’s risk of having autism. As there are distinct levels of severity and
      combinations of symptoms in autism, there are probably multiple causes. </p>
    <p>Timing of exposure to the following factors during the child's development (before, during, or after birth) may
      also play a role in the development or final presentation of the disorder. </p>

    <ul>
      <li> <b> Genetic factors -</b> Research has shown that autism may develop due to genetic changes in an early
        embryo or the sperm and/or egg that combine to create the embryo. Also, among identical twins, if one child has
        autism, then the other will be affected about 75% of the time. Among non-identical twins, if one child has
        autism, then the other has it about 3% of the time.</li>

      <li> <b> Environmental factors -</b> These include prenatal risk factors such as teratogens, pesticides, and
        maternal antibodies; some perinatal and obstetric conditions; and a range of postnatal factors, such as
        gastrointestinal or immune system abnormalities, allergies, and exposure of children to drugs, vaccines,
        infection, certain foods, or heavy metals.</li>

      <li> Pregnant women who consume certain drugs or chemicals, like alcohol or anti-seizure medications, are more
        likely to have autistic children. Other risk factors include maternal metabolic conditions such
        as diabetes and obesity. Research has also linked autism to untreated phenylketonuria (also called PKU,
        a metabolic disorder caused by the absence of an enzyme) and rubella (German measles). </li>

      <li> <b> It is well established that Autism is NOT caused by bad parenting, neglect, unhappy home environment,
          both parents working, poor handling by the mother, stress, or any other psychological factors. There is
          nothing a parent or caregiver can do to cause the disorder to occur. </b></li>

    </ul>

  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q3. What to do if I suspect autism?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    If you find any of the <a routerLink="">red flags</a>  in your child’s development, consult your paediatrician or developmental
    paediatrician immediately to get the diagnosis. As the early age of development is very crucial, the sooner a child
    gets<a routerLink="">early intervention </a> services, the greater the chance for learning and progress.  Find more information on early
    intervention here.
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q4. What to do after diagnosis of Autism?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>As a parent, even before the diagnosis you may have feeling that your child has autism, and you may try to find
      information on internet about it. Other parents who may not have idea about such diagnosis, may find it shocking
      when they get to know about it. As a parent, you may go through a range of emotions like sadness, anxiety, shock,
      anger, frustration and all your emotions are valid. But now is the time to trust yourself and your child for
      taking the way ahead to help your child who is in dire need for interventions. The diagnosis will just define the
      condition of your child and will help in understanding his needs in his life journey. </p>

    <p>
      You need to process all the information calmly, so that you can do the best for your child. Please remember, you
      are not alone. There are lot of children and their parents who are going through the same struggle as yours. So,
      the best thing you can do is to just love and support your child, start finding the therapies and supports that
      will enable them to learn and grow and finding a support network that can help you in this journey.
    </p>

    <p>
      You can educate yourself so that you feel empowered to help your child. There are a lot of parent support groups
      and organisations that can help you to prepare yourself.</p>

    <p> We at <a href="aacunfolded.com" target="_blank">aacunfolded.com</a> are trying to provide all the information
      with life cycle approach to parents. Right from understanding autism to details of various therapies and how to
      teach your child and provide support for all his life is explained in various sections of our website. </p>

  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q5. How can I find out if my child got autism genetically?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Genetic testing cannot diagnose someone with autism, but it could help a family understand how or why someone has
      autism. If you are interested in genetic testing, you can do it by getting a referral from your doctor for a
      medical geneticist. The medical geneticist and genetic counsellor can help you determine which genetic tests are
      appropriate for your child based on your family history, child's physical features, and his medical and
      developmental history. Genetic testing is done usually through a blood test, cheek swab and sometimes a urine
      test. If a genetic cause is found, you can find out if the mutation was inherited from one, both or neither
      parent.</p>
    <p>But please remember, if you get to know from which parent genetic mutation is inherited, that should not result
      into blame game. Both the parents must accept the result and focus on the interventions of the child as that’s
      most important. The genetic testing hardly changes medical or interventions management of the child. </p>

  </div>
</mat-expansion-panel>

<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q6. What is the treatment for autism?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Autism is a neurological condition and not an illness that can be treated with medicines and cured forever. </p>

    <p> The person with autism needs to be taught using intervention and management strategies after considering his
      unique learning style which can help him to reach his maximum potential. The focus of teaching must be on the core
      areas of difficulty in autism, such as communication and social understanding. We also need to teach play and
      leisure skills, self-help skills for happiness and independence of the person with autism. </p>

    <p> Interventions that we choose for our child must be appropriate, consistent and autism friendly. The learned
      skills in the therapy sessions must be generalised in daily life and that can help in child’s significant
      progress. Autism remains lifelong and hence, we need to teach every skill to independence and in such a way that
      it can help them in their future for employment and social life. There are various types of interventions
      available but following are the most proven <a routerLink="/interventions">interventions</a>  which are developed with the lifelong perspective.
    </p>

    <ul>

      <li><b>Applied Behavior Analysis (ABA)</b></li>
      <p>ABA therapy applies our understanding of how behavior works to real situations. It emphasizes on one-to-one
        sessions in discrete trial training (DTT) to develop cognitive, social, behavioral, fine motor, play, social and
        self-help skills.</p>

      <p> The methods are used to support people with autism to increase helpful behaviors, to teach new skills, to
        maintain learnt behaviors, to generalize those behaviors in daily life situations. The technique involves
        structured presentation of tasks from most simple to more complex tasks to the child. The whole task is
        presented
        by breaking it down into small sub-skills, and then teaching each sub-skill intensely, one at a time. It
        requires
        repeated practices with provision of prompts wherever required and fading of prompts gradually to ensure
        independence. In ABA, rewards or reinforcements are used to help shape and maintain desired behaviors and
        skills.
      </p>


      <li><b> Verbal Behavior Analysis (VBA)</b></li>

      VBA therapy teaches communication and language by connecting words with their purposes. It teaches why we use
      words and how they are useful in making requests and communicating ideas.

      <li><b>Picture Exchange Communication System (PECS) </b></li>
      <p>PECS can be used by people of all age groups. PECS teaches that communication is an exchange of messages
        between two people. The act of exchanging objects or pictures in social settings encourages the child for social
        communication. Pictures of things used in an individual’s daily routine like food, drinks, toilet, clothes,
        places, family can be placed in picture book. The parent or therapist can teach the child how to use this
        picture book to communicate needs, thoughts, or emotions. The child can get immediate response by receiving
        desired thing which motivates him for more communication efforts. </p>
      <p>
        Apart from the interventions, accepting autism as a way of life and using <a routerLink="/vis-teaching"> structure teaching</a> method can help the
        person with autism in learning various life skills to live life happily.
      </p>
    </ul>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q7. Is there a cure for autism?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      As the cause of autism is still unknown, at present, there is no cure for autism in the form of medication, pills,
      injections, or surgeries. Though there are lot of people who advertise on internet that they have found treatment
      for autism and due to urge of parents to find solution for the problem of their children, these treatments are
      getting a lot of attention. But beware that these treatments are not scientifically proven and hence be cautious
      while choosing any of these treatments. </p>

    <p> As a parent you want to do whatever is in your capacity to help your child overcome his difficulties and it’s
      valid that you opt for such treatments. But irrespective of whatever other treatments you may choose for your
      child, keep teaching them with the structured training <a routerLink="/vis-teaching">program </a> along with the prescribed therapies that can
      maximize skill development and help in achieving their full potential. </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q8. How common is autism?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Autism is not a rare or uncommon disorder. It is the third most common developmental disorder. According to
      estimates from Centers for Disease Control’s Autism and Developmental Disabilities Monitoring (ADDM) Network 2018
      data, about 1 in 44 children in the United State have been identified with autism spectrum disorder and the
      overall incidence of autism is believed to be consistent around the globe. </p>

    <p> Autism is present in all racial, ethnic, and socioeconomic groups. Family income, lifestyle and educational
      levels of parents do not affect the chance of autism's occurrence. It is more than 4 times more common among boys
      than among girls. Previously, when autism was not well defined, many of the people with autism used to get
      diagnosis of mental retardation, schizophrenia, or attention deficit hyperactivity disorder (ADHD). But as the
      awareness about autism has increased over a period, more of these children receive autism as a primary diagnosis
      and hence there is an increase in diagnosis of autism, on both 'ends' of the spectrum. </p>
  </div>
</mat-expansion-panel>
<br>


<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q9. How to measure the severity level of autism in my child?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Autism affects everyone differently, and the extent to which it may create challenges in daily life is also
      different for each person. A health care provider will look at many factors to choose a severity level. The level
      is based on communication skills and types of restrictive, repetitive behaviors. The American Psychiatric
      Association's Diagnostic and Statistical Manual, Fifth Edition <a routerLink="dsm">(DSM-5)</a>  provides standardized criteria to help
      diagnose ASD and also has defined severity levels of autism as follows:
    </p>

    <table>
      <tr>
        <th>Severity Level for ASD</th>
        <th>Social Communication</th>
        <th>Restricted Interests & Repetitive Behaviors</th>
      </tr>
      <tr>
        <td>Level 3 - Requiring very substantial support</td>
        <td>
          Severe deficits in verbal and nonverbal social communication skills
          cause severe impairments in functioning, very limited initiation of
          social interactions and minimal response to social overtures from
          others.
        </td>
        <td>
          Preoccupations, fixated rituals and/or repetitive behaviors markedly
          interfere with functioning in all spheres. Marked distress when
          rituals or routines are interrupted; very difficult to redirect from
          fixated interest or returns to it quickly.
        </td>
      </tr>
      <tr>
        <td>Level 2 - Requiring substantial support</td>
        <td>
          Marked deficits in verbal and nonverbal social communication skills;
          social impairments apparent even with supports in place; limited
          initiation of social interactions and reduced or abnormal response to
          social overtures from others.
        </td>
        <td>
          Rituals and repetitive behaviors (RRBs) and/or preoccupations or
          fixated interests appear frequently enough to be obvious to the casual
          observer and interfere with functioning in a variety of contexts.
          Distress or frustration is apparent when RRB's are interrupted;
          difficult to redirect from fixated interest.
        </td>
      </tr>
      <tr>
        <td>Level 1 - Requiring support</td>
        <td>
          Without supports in place, deficits in social communication cause
          noticeable impairments. Has difficulty initiating social interactions
          and demonstrates clear examples of atypical or unsuccessful responses
          to social overtures of others. May appear to have decreased interest
          in social interactions.
        </td>
        <td>
          RRB's cause significant interference with functioning in one or more
          contexts. Resists attempts by others to interrupt RRB's or to be
          redirected from fixated interest.
        </td>
      </tr>
    </table>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q10. Will my child ever speak?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      People with ASD may have major difficulties with both verbal and nonverbal communication. They may also find it
      hard to interact socially. There is a huge variation in the development of speech among people with autism as some
      people never speak, while some start speaking late or those who begin to speak on time, their language does not
      develop age appropriately. Hence, it is very difficult to predict when and whether the child will ever speak
      before proper assessment and application of required therapies.
    </p>


    <p> We need to understand that speech is one of the media of communication and communication is the most important
      part as compared to speech. So, we need to work on their communication abilities so that they can express their
      needs, wants, desires, and emotions to us and also understand our communication with them. Here, speech therapists
      can help in figuring out the best ways to improve communication may be by introducing alternatives to speech by
      using picture symbols, sign language, applications on mobile or computers. </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q11. Can my child attend a regular school?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      The Universal Declaration of Human Rights states “everyone has the right to education which shall be free, at
      least in the elementary and fundamental stages”. Going to school, being with peer group and learning various
      age-appropriate skills are the basic rights of any child. In India, as per Right to Education Act, 2009, children
      with disabilities will be educated in the mainstream schools and hence, there are lot of autistic children who
      have been integrated into regular schools. In the United States, the Individuals with Disabilities Education Act
      (IDEA) requires that your district provide the "Least Restrictive Environment" for a child's education. That means
      the district must consider options such as inclusion before placing the child in a more specialized setting. </p>

    <p> As many children with autism have different learning styles, they need to be taught in customised manner. They
      may need accommodations in the teaching style as well as in the learning environment. Simple changes in existing
      classroom, use of accommodation techniques and environmental adaptations can make teaching friendly and effective
      for them as per their specific requirements. For example, by providing a flexible timeframe for children studying
      particular subjects, engage the entire class by providing visuals, telling stories and offering activities that
      explore the curriculum, adjustments in the standard curriculum to the learning needs of the students. </p>

    <p> As per the skill level and environmental requirements, there are various educational opportunities for students
      with autism such as a general education classroom, a resource classroom, a special education classroom, or an
      autism-only setting. Some learn better in an inclusive class, while others are better in segregated situations. As
      a parent, we must understand the need of our child while selecting the school and always encourage the child to
      participate at any level. </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q12. 	Can my child live an independent life? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Autism is a “spectrum” disorder because of the wide variation in the type and severity of symptoms people with autism experience. Each person with autism has different strengths, challenges and therefore the ways of their learning, thinking and problem-solving can range from highly skilled to severely challenged. Appropriate interventions and consistent efforts in teaching can help them learn and progress in various skills. But still some people with autism may require significant support in their daily lives, while others may need less support and, in some cases, they can live entirely independently.</p>
  </div>
</mat-expansion-panel>

<br>
<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q13.	Do people with autism also have Intellectual Disability (mental retardation)? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Intellectual disability is a disability characterized by significant limitations, both in intellectual functioning and in adaptive behavior, as expressed in conceptual, social, and practical adaptive skills, the disability originating before the age of 18. The level of intellectual disability is measured in terms of intellectual quotient (IQ) of the person having difficulties in achieving age-appropriate standards of behavior. But analyzing intelligence of person on the spectrum is very difficult because they may have certain skills but are not able to use them in a test and so the score in the IQ test can vary at different times. </p>
<p>
A study (Kurzius-Spencer et al., 2018) estimated that around 30% of children on the autism spectrum in the US also have an intellectual disability. It is observed that children with autism and intellectual disability are at greater risk of some behavioral problems.</p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q14.	How to choose intervention if my child has intellectual impairment along with autism? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>While determining intervention, it is primarily important to understand the unique learning style and thinking ways of individual with autism. As it is observed that people with autism are detailed thinkers and visual learners and learn better if provided with structure and predictability along with use of behavioural principles while teaching. Before, determining intervention, the professional must conduct functional assessment of the individual to understand the current skills levels in various developmental areas and accordingly prepare customised intervention plan for the person. The intervention can be mix of educational, behavioural, communication interventions as per the strengths and needs of each specific child </p>
<p>
      If a child is diagnosed with both autism and intellectual disability, therapists believe that working on functional communication skills may be a useful early intervention strategy for both autism and intellectual disability. If a child can communicate wants, needs, and emotions, then his behavior and quality of life often improves. </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q15.	Do people with autism prefer to be alone? Can they make friends?
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>Autism affects person’s ability to communicate with others, understand spoken language and non-verbal cues, and develop social skills. Individuals with autism find it difficult to have joint attention e.g., in spontaneously sharing their interest or in taking interest in other person’s interaction. Hence, it seems that they like to be aloof or want to remain alone. Whereas people with autism do form friendships with both neurotypical and autistic peers, but their interactions sometimes look different from those among neurotypical people due to the nature of deficits in their verbal and non-verbal communication. Many autistic people struggle in making friendship with their peers as they do not understand how to initiate the conversation and how to maintain the relationship. Also, it is difficult for them to follow unwritten social rules which are dynamic in nature and hence, their behavior seems odd, inappropriate, or repetitive in social situations. However, with the guidance of a supportive adult, children on the spectrum can learn ways to interact with other children appropriately. </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q16.	Can adults with autism get married? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Yes, autistic adults can get married. There is still a misunderstanding that autistic people can’t feel emotions or their apathy to connect with other people. But on the contrary, people with autism not only can feel other’s emotions, and can be affectionate, fall in love, and make a long-term commitment like marriage with adult of their choice. Diagnosis of Autism cannot legally prevent someone from marrying, signing contracts, or otherwise making binding commitments. </p> 
      <p>
Of course, it is not simple as the marriage involves lot of physical, mental, and emotional adjustments, responsibilities of carrying on day-to-day works and planning for future. It involves companionship which requires a healthy communication and understanding between two adults. Hence, before deciding upon marriage, there are some factors that may need to be considered by the person with autism and his/her family: 
    </p>
    <ul>
      <li>The ability of the person to take on the responsibilities that are integral to marriage may depend on the level of their functioning in daily life. They might need someone to help with aspects of life they struggle with.</li> 

      <li>  The partner understands the needs of autistic spouse. </li>
        
      <li>  Financial status with respects to employment or family support to take care of current and future needs of family.  </li>
    </ul>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q17.	What are chances of Autism in the next child born to us? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Different types of family studies have evaluated the likelihood of a second person within a kin group having autism (e.g., siblings, child-parent, first cousins, etc.) If there is one child with autism in the family, the risks of having another child with autism is much higher than in the general population. Currently, there is no genetic counseling available to tell families with surety if their second child will have autism or not. Also, there is no test available that can detect autism in the fetus.  </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q18. 	Do people with autism have savant skills?  
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Some people with autism have special abilities that cannot be duplicated by most other human beings. Some are extraordinary in drawing pictures, solving puzzles, playing musical instruments, memorizing train or bus schedules, solving math problems, can read beyond their developmental age or have ‘photographic’ memory for places or things. But all children with autism do not have these savant skills. It’s just like there are neurotypical kids with above average skills than their peer groups. Hence, such extraordinary skills cannot be taught to every child with autism, and they have to be taught as per their needs and abilities with focus on core areas of difficulties like communication, social skills and basic daily living skills.  </p>

  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q19.	Why are playing behaviours of my child different? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Play skills are extremely important for the child’s personal wellbeing, for holistic development, and to foster inclusion. Play boosts imagination, intelligence, language, social skills, and motor abilities in the children. Play skills of neurotypical child starts developing from solitary play, parallel play, and associative play to co-operative play where they learn skills like turn taking, waiting, sharing toys, following rules.  </p>

    <p> Many parents of children with autism observe that their child with autism is not interested in toys in conventional ways, whereas they play with the toys differently. As many people with autism have a deficit in imagination, they are unable to think of things that they can do in their free time. They show little or no interest in toys or show focused interest in unusual objects such as strings, brooms, pebbles, wrappers, or any household objects or activities like scribbling, drawing, or tearing paper.  </p>

  <p> They also have difficulties in accepting change in their routines or activities and so many of them spend their time doing the same activities or playing with same toys or engage in simply running around or jumping about. We can observe that many children with autism are busy in lining up or stacking similar objects like pencils, blocks, toy cars, glasses, bottles, rolling cars back and forth in their play time. Some may use toys and other objects purely for sensory stimulation like banging toys to get sound and pressure, putting toys in the mouth to explore the texture, or keep looking at light that flickers for visual stimulation. Though some children with autism learn basic pretend play behaviours, the play remains limited as they may use repetitive stories. </p>  
<p>
Though they are happily involved in their play activities in their own ways, if we want them to learn various social skills, play can be an initial point of entry in their world. <a routerLink="/vis-teaching">Click here to know how to develop play skills.</a>   </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q20.	Why is my child so hyperactive? How to reduce his hyperactivity? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Many children with autism display behaviors such as restlessness, poor attention span, and hyperactivity. They find it difficult to sit at one place for long time and pay focused attention on tasks or activities. But these are more of the symptoms of ADHD which can be a co-morbid condition with autism. </p> 

      <p> As the child with autism has an impairment in his social and imaginative skills, they find it difficult to engage themselves in play activities independently or with other children. So, they might be opting for the things that they can do independently like running or jumping or just manipulating different toys and objects at home to keep themselves occupied in their own way.</p>  

      <p> If we work on development of different skills such as academic skills, play skills, daily living skills, leisure skills, and let them know how to use their spare time by using schedule, then this restlessness or 'hyperactivity' can be reduced over a period of time. Also, occupational therapist can provide calming activities including motor activities, activities to improve attention and concentration which can help the child to increase sitting tolerance, attention span and compliance. </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q21.	Does sugar intakes increase hyperactivity? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Many parents say that sugar, food colorings and artificial flavoring make their kids hyperactive, but majority of the studies so far haven't found a connection between child’s diet and behavior, and even the medical industry maintains that there is no known link between sugar and hyperactivity. So, this issue is yet to be decided though research. But there is another reason parents should be concerned with the amount of added sugars child consumes.  When kids eat more of sugar-sweetened foods and beverages, they have little desire for healthier foods their growing bodies need, such as fruits, vegetables, whole grains, protein, and dairy. So instead of eliminating sweet food from diet, you can offer it strategically. For example, fresh fruits along with a small bowl of ice cream and child will eat fruits willingly. 
    </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q22.	Why does my child keep playing with his fingers/flap hands/rocking/spinning around? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      Many parents complain that their children keep playing with fingers or keep on flapping hands or keep on rocking or spinning in circles or any repetitive movements or sounds which are termed as stimming or self-stimulating behaviours by the therapists. But actually, it will be more appropriate if we call it as “self-soothing” or coping mechanisms for individuals on the spectrum.</p>  

    <p>  As you are reading this, are you jiggling your foot or rocking a bit or tapping a pen? Everyone stims and it is referred as fidgeting but in reality, it helps us in concentrating on the work we are doing or shows that we are engrossed in something that interests us. People with autism may do it for the same reason, but it may differ in quantity, intensity, and type. </p>  
      
  <p>  To understand and to learn from the environment, we use our seven senses (sight, smell, sound, touch, taste, balance, body in space). But many individuals with autism have difficulties in integrating and processing all the information coming from multiple sources in the environment by using their seven senses as they might have a condition known as sensory dysfunction. They may have hypersensitivity or hyposensitivity or a combination of both and that is why they may find the environment too overwhelming or confusing. Here, self-stimulatory behaviours such as finger flipping, rocking, mouthing, spinning etc. come as natural responses or methods of coping with the sensory difficulties, stress, feelings of overwhelm, boredom, or even excitement. </p>
      
<p>  Also, stimming may be a manifestation of restricted or repetitive behaviors—a core autism characteristic which is mentioned in DSM-5 (The Diagnostic and Statistical Manual of Mental Disorders (5th ed.; DSM–5; American Psychiatric Association, 2013).  </p>
      
<p>  While the reason behind stimming could be managed or eliminated by making changes in the environment, telling a child to stop stimming without doing such accommodations is not going to help. To cope up with the stress or sensory need the child may opt for another way to stim that may be more challenging to manage. The rights of any individual needing accommodations to deal with own difficulties should be supported by society. </p>
      
<p>  So, shouldn’t we intervene stimming? </p>
      
    <p>  It is important to intervene if stimming is self-harming or harming others, or when it interferes with the child’s happiness, well-being, learning or other important activities. </p>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q23.	Why does my autistic child get meltdowns? How to prevent meltdowns? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      A meltdown is an intense response to overwhelming situations by the person. It could cause loss of control of behaviour that can be shown through verbal expressions like yelling or crying, or it can be physical action like kicking, head banging or biting or both ways. When parents/caregivers experience such meltdowns, they often wonder why the child is having meltdown. Meltdowns can be triggered by different reasons. Hence, it is the most important thing to find the reason or function of behaviour. Maintaining the records of behaviour and analysing them <a routerLink="">(A-B-C analysis)</a>  regularly can help in finding the exact triggers. The following are some of the common causes of meltdowns: </p>

<ul>
  <li> <b>Difficulty in communication –</b> One of the main features of autism is difficulties in social communication and as the child can’t express his thoughts to whom he wants to communicate, either because he is non-verbal or because the words don't come out right, it can cause frustration. Hence, it is said that, every behaviour is a communication. Even some situations like feeling hungry or thirsty or hot, can be overwhelming if they can’t convey that to the other person.  </li>

  <li>
  <b> Sensory overload – </b> Many children with autism have difficulties in integrating all the senses to process the environment 
  <a routerLink="">(Sensory Processing Disorder)</a> . Hence in chaotic places like store or railway station or classroom, they can feel struck by the noises, colors, lights, and activity going on all around them. 
  </li>

  <li>
  <b>  Information overload –</b>  Autistic person may have difficulty in processing language if information is coming from different directions or coming too quickly, and hence they might react with a meltdown. 
  </li>

  <li>
  <b>  Learning issues – </b> A learning disorder could cause frustration and lead to meltdowns. 
  </li>

  <li>
  <b>  Change in routines – </b> Autistic people like to follow consistent and predictable routines and structures and any sudden change in routine without their knowledge can be very stressful for them and they can have meltdown. E.g., change in school route, change in eating or play time, could cause feelings of anxiety that may trigger a meltdown.  

However, meltdowns are not the only way that an individual with autism can show they are overwhelmed, they even show it through refusal to interact, or withdrawal from challenging situations. Parents and caregivers always think, can we prevent the meltdowns? Yes, the best meltdown cure is prevention.  
  </li>

  <li>
  <b>  Recognize the signs – </b> If you understand the signs of meltdowns, you can provide an opportunity to the autistic person for calming down. Keeping track of the following signs can help in understanding that the person may get meltdowns:-  
    <ul>
      <li>Emotional state, like looking frustrated, agitated, or overwhelmed, </li>

    <li> Signs of stress like headache, deceased concentration, fatigue, clenched jaw, </li>
        
  <li>  Sudden increase self-calming behaviours or stimming </li>
    </ul> 
  </li>

<li>
<b> Intervening early – </b>It’s important to take precautionary action to reduce the stress of the person. E.g., if you start feeling that the person is getting overwhelmed due to the crowded place, it’s better to leave that place before the situation gets stressful for him.  If you think that stimming may help, let the person do it. If at noisy place, wearing earplugs would help, let him wear that.</li>  

  <li> <b>Teaching coping skills – </b>We can teach the coping skills through activities like going for walk, relaxation exercises, like deep breathing or imagery when the child is calm and is in learning mode. If the child gets relaxed with deep pressure, you can teach them to ask for deep pressure, through massages, heavy clothing, bear hugs, or tight squeezes. These activities will help them calm down even before the meltdown happens. </li>

<li> <b>Always carry self-calming tools – </b>While going out of home with your child, always carry a bag with his most favored and self-calming tools so that it can be handy when he needs it. These tools can be anything like toys, weighted jacket or the string that he likes to play with. </li>

<li><b> Use schedules and structures – </b>Visual schedules and structured are useful for the people with autism as schedules give predictability of events that are going to happen for the particular period as mentioned in the schedules. It can be for part of the day or for whole day or for more period as per the requirements of the individual. If followed diligently, schedule also prepares the person to accept changes in the routines he is accustomed with. This helps in reducing stress that might have developed due to unpredictable changes in routines.</li> 

<li> <b>Work on communication – </b>Difficulty in communication can be the cause of the meltdowns of the person with autism. Hence, working on communication skills is utmost important which can be taught through PECS, signs or AACs (Alternative and augmentative communication).</li> 

<li><b> Teaching replacement behaviour – </b>Alternative behaviors are positive behaviors taught to the autistic child to replace challenging behaviors and to meet long term behavioral goals. Stopping one behavior of the child is not an option as the child can learn another behavior to get the same result. E.g., Child having sound hypersensitivity covers his ears with hands when he steps out of the house. If he is stopped from covering his ears, he may start screaming to overpower the outside sounds. So rather than stopping him from covering his ears, he can be taught to use earplugs or headphones to avoid such sounds. </li> 

<li>The journey of autism requires lot of empathy and patience. The person may be experiencing severe feelings of panic, frustration, confusion, or general distress. So keeping yourself calm and helping the person to overcome his difficulties is necessary. </li>

</ul>

  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q24.	How to calm an autistic child during a meltdown?  
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      As discussed above, it is important to identify the reason or function of behaviour. By identifying the trigger, the meltdowns can be prevented if worked on the function of behaviour. Maintaining the records of behaviour and analysing them 
      <a routerLink="">(A-B-C analysis)</a> regularly can help in finding the exact triggers. </p>

    <p>  The signs of imminent meltdown can be noticed by the parent or caregiver through the autistic person’s signature pattern of thoughts, behaviors and actions such as increase in the volume of speech, the restless gestures, refusing help or needing excessive reassurance. The early warning signs may include knowledge that the person has probably come to the end of his ability to tolerate sensory, cognitive, and social experiences. If such signs are observed and early actions are taken to divert the behavior to soothe the person, then meltdowns can be prevented from occurring.</p> 
      
  <p>   But when the child is having a meltdown, it is the priority to calm them down. As each child is different, there are differences in their behaviours and functions of behaviour so there cannot be an easy formula for handling meltdowns, but certain strategies can be useful such as –  </p>

  <li>
    Stay calm – Parents can get anxious when their child is going through meltdowns and that anxiety can be transferred over to the child which may worsen the meltdown. So, it is important that you must address your own anxiety when your child is in a meltdown, so that you can handle the meltdown calmly and give your child the care that he or she needs. Also remember, the way you will react while handling your child in the meltdown, the people around you will learn the way of handling such situation.  </li>

    <li> Validate the emotion – Affirm and validate the depth of emotion of the person having meltdown and explain that the feeling will eventually go. It is essential to be empathetic and make sure they know they are not alone. Maintain respect and keep in mind that a person in meltdown may be experiencing some trauma from losing control of themselves. It can be an embarrassing and frustrating experience for them. So let them rest and give them time to calm down and relax. If the person needs alone time, just let them be alone in their calming area.  </li>

    <li> Use minimal speech – As the autistic person may have difficulty in processing language and at the time of meltdown, they may find it more difficult to take multiple instructions and behave accordingly. Hence, try to use minimal speech for calming the person. Even try to minimize eye contact for both of you.  </li>

  <li> Safety first – Meltdowns can be verbal or physical or both. So, the safety of the child and people around is crucial. If possible, create a safe calming area in home, so that at the time of meltdown, you can guide the child to be in that area his safety. If possible, be alongside the person rather than facing each other. Also, try to keep other people away as it can be overwhelming if other people keep on watching the situation. </li> 

<li>Use calming or distracting activity – You can suggest the child to use calming or distracting activity such as an iPad, or fidget toys or calming music or his special interest. Suggest him about some forthcoming interesting activity or an enjoyable memory. But do not force the child to use if he is not accepting it willingly as it can add up his stress.</li>  

<li>Self-regulation – Body-based regulations like stimming, jumping, etc., often help as coping mechanisms for sensory imbalances. An alternative option is to engage in a physical activity (if he previously knows it) that constructively releases emotional energy, such as physical exercises or machine exercises like treadmill walk, cycling etc. If physical contact is okay, tactile, comforting techniques like hugs, weighted blankets, a favorite plush, or a cozy sweater can create a comfortable and safe environment. </li>

<li>Sensory toolkit – While going out, you can carry few sensory toys which can keep the mind of your child occupied when they are overwhelmed. If the child generally gets upset due to sensory overload in the environment, then you can carry sensory tools such as Noise-cancelling headphones, Sunglasses, Weighted blanket/lap pads, Snacks that are chewy or crunchy, Scented hand lotion, Hand wipes  </li>

<li>Always praise compliance and calmness of the child. </li>

<li>The meltdown may help the person in releasing compressed stress and re-setting the emotion regulation system. So, wait patiently until the emotional storm has passed. Throughout this process, maintaining your self-regulation and creating an understanding and supportive presence is important.  </li>

<li>After a meltdown, your child might feel embarrassed or exhausted. So rather than discussing about the incidence, help the child to recover by giving him time and space to relax or if he wants, engage him in a calming and familiar activity. </li>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q25.	Can speech therapy help my child? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      People with ASD may have major difficulties with both verbal and nonverbal communication. The child has a difficulty in whole communication process, which includes both understanding of language, as well as expressing themselves through speech or any other mode. There is a huge variation in the development of speech among people with autism as some people never speak, while some start speaking late or those who begin to speak on time, their language does not develop age appropriately. They may also find it hard to interact socially.</p>  

    <p>  As speech is one of the medium of communication and communication is the most important part as compared to speech, we have to work on their communication abilities so that they can express their needs, wants, desires, and emotions to us and also understand our communication with them. </p>  

  <p>  Speech therapist can help in assessment and treatment of communication problems and speech disorders such as articulation disorder, fluency disorder, receptive and expressive language disorder, cognitive-communication disorders. But as the inability to speak in autistic child is not due to a physical problem, it is essential for the speech therapist to understand autism and also the individual child. Speech therapists can help in figuring out the best ways to improve communication may be by introducing alternatives to speech by using picture symbols, sign language, applications on mobile or computers.</p>  
  </div>
</mat-expansion-panel>
<br>


<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q26.	When can we tell our child that he/she has autism? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      The parents who are in dilemma about whether they can let their child know that he/she has autism, often try to find answer to “when to disclose?”. Actually, there is no single right answer to this question as this decision needs to be made depending on the individual child with respect to his age, cognitive ability, social awareness, environment, the personal family circumstances, as well as the parent’s readiness to have the conversation. Also, it should be an ongoing process and not a one-time conversation. Knowing your child well and monitoring his overall developmental progress will help you in choosing the right time to reveal his diagnosis. Sometimes the child begins to realize that he is different and may ask you question about “being different”. Not providing an answer at this time could increase the child’s anxiety and make the topic and information more shadowy.</p>  

      <p>  While revealing your child's autism diagnosis, stay positive. The parent or caregiver herself or himself has come to some level of comfort about the diagnosis, since that will determine how the child will perceive his autism. Please remember, the disclosure should never be conveyed in worrying or disappointing manner which may do irreparable damage to your child's self-esteem. You may wish to begin the conversation by highlighting your child's positive traits, e.g., “Have you ever noticed how much more organized you are than most of your classmates?”, or how the child is good at a particular thing than others. Also try to establish the concept that differences between people are normal, acceptable, and beneficial. And as the people have different skills, when they work together, these different skills prove to be highly complementary.</p> 
<b>
  You can also take help of a professional in the beginning stages of disclosure, and you can be the support and comfort the child needs in this process. 
</b>
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q27.	What lies in future for people with autism when they grow up? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      People with autism vary in skills levels as it’s a spectrum disorder. Hence, the future may not be the same for all of them. The children who can cope up in mainstream school /college, can get jobs and can live independent lives. They may find it difficult to sustain the demands of a job and work environment. Those who can work in vocational centres or sheltered or supported workplaces can earn their livelihoods. Some advocate and speak for themselves and for other people with autism in the community. Some people get married, and some choose to be single. But many a times, people on the spectrum always need support in life and may never be fully independent. </p>

    <p> Though inclusion is mandatory in India, still there are few schools which provide an environment or the attitude to accommodate the people on the autism spectrum. Even employment opportunities are fewer. Also, there are not enough and appropriate lifespan services like residential facilities or hostels or care centres for later life for people with autism where they can lead a life of dignity and respect. Lot of parental organizations and NGOs are trying to provide such services but their reach is mainly in cities and hence people living in remote places find it difficult to get these services. Hence, they need support from people around them including the family, friends, neighbours and teachers to lead a happy and meaningful life. </p>

  <p> Also, when the child turns 18 years of age, the parent may have to appoint legal guardian for managing the adult’s life or taking legal decisions for his betterment.The parent has to understand and accept these facts, and according to their child’s requirements must make financial and physical support arrangements for their future. </p> 
  </div>
</mat-expansion-panel>
<br>

<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>

    <mat-panel-title>
      <div class="head3">
        Q28. 	What will happen to my child after me? 
      </div>
    </mat-panel-title>

  </mat-expansion-panel-header>
  <div class="mycontent1">
    <p>
      There are limited social security measures in India for people with disabilities and hence 'what after us?' is the greatest fear of every parent of a child with autism. As the people with autism may need life-long support in their daily activities, they require assistance of trained people who understand autism and in India there are few hostels/ residential facilities which provide such services and obviously wherever these services are available, they are very costly and required to be financed by the parent of the child/adult with autism. </p>

    <p> If the family members are supportive enough to provide respectable life to the person with autism, then the person can even stay at home. You can even appoint some person to take care of the person with autism at home. The parents have to make financial arrangements with whole life approach of their child as there are hardly any financial facilities or insurance covers available for people with autism. The legal will made by the parents can convey the arrangements they have made for their child with autism and other children and hence, making legal will can be very helpful. Also, the details about your child’s medical history, current medications, health issues, behavioural issues, likes and dislikes can be listed down in in a document by the parent for future reference of the family members or for residential facilities where he will stay afterwards. </p>

  <p>If you make all these arrangements beforehand, then the fear “what after us?’’ will be addressed by you and you can be of free mind about your child’s future. </p>
  </div>
</mat-expansion-panel>
<br>



 


