<section class="bg-half  d-table w-100" id="home"></section>








<!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

            <div class="footer-icons">
                <ul class="navbar-nav ">
                    <li class="nav-item inline-block">
                        <a href="" aria-label="Twitter" class="nav-link text-twitter"><i
                                class="fa fa-twitter" style="font-size: 24px; color: black;"></i></a></li>
                  <li class="nav-item inline-block">
                    <a href="" aria-label="Facebook" class="nav-link text-facebook"><i
                      class="fa fa-facebook" style="font-size: 24px; color: black;"></i></a></li>
                    <li class="nav-item inline-block">
                        <a href="https://www.instagram.com/mehul_kothari05/" aria-label="Instagram" class="nav-link"><i
                                class="fa fa-instagram" style="font-size: 24px; color: black;"></i></a>
                    </li>
                    <li class="nav-item inline-block">
                        <a href="https://www.linkedin.com/in/mehul-kothari-765868126/" aria-label="LinkedIn" class="nav-link"><i class="fa fa-linkedin" style="font-size: 24px; color: black;"></i></a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav> -->



<app-blog-header></app-blog-header>






<div class="row"style="display: flex; justify-content: center">
    <div class="col-xs-12 col-md-6 col-md-offset-3">
        <div class="alert alert-danger" *ngIf="error">
            <p>{{ error }}</p>
        </div>
        <div *ngIf="isLoading" style="text-align: center;" class="loading-text">
            <app-loading-spinner></app-loading-spinner>
        </div>
        <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">
            <div class="form-group">
                <label for="email">E-Mail</label>

                <input type="email" id="email" class="form-control" ngModel name="email" required email />
            </div><br>

            <div class="form-group">
                <button class="btn btn-primary" type="submit" [disabled]="!authForm.valid">
                    Reset Password
                </button>
                <a [routerLink]="['/auth']" class="btn btn-link">Login Page</a>

            </div>

        </form>
    </div>
</div><br>