import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatExpansionModule} from '@angular/material/expansion';
import{MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import{MatIconModule} from '@angular/material/icon';
import{MatListModule} from '@angular/material/list';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from "@angular/material/button";
import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts';




import { IndexComponent } from './core/components/index/index.component';
import {MasterPageComponent} from './core/components/master-page/master-page.component';
import {SwitcherComponent} from './shared/switcher/switcher.component';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';

import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import {AuthLoginThreeComponent} from './auth/auth-login-three/auth-login-three.component';
import {AuthSignupThreeComponent} from './auth/auth-signup-three/auth-signup-three.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';


import { FeatherModule } from 'angular-feather';

import { allIcons } from 'angular-feather/icons';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {FormsModule} from '@angular/forms';

import {AuthLoginComponent} from './auth/auth-login/auth-login.component';
import {LightboxModule} from 'ngx-lightbox';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {
  AngularFireStorageModule,
  AngularFireStorageReference,
  AngularFireUploadTask,

} from '@angular/fire/compat/storage';

import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { HomeComponent } from './core/components/Unauthenticated/home/home.component';
import { UCreatePostComponent } from './core/components/Unauthenticated/u-create-post/u-create-post.component';
import { UPostDetailComponent } from './core/components/Unauthenticated/upost-detail/upost-detail.component';

import { CrudService } from './core/components/Unauthenticated/shared/crud.service';
import { AuthComponentComponent } from './Authentication/auth-component/auth-component.component';
import { AuthService } from './Authentication/shared/auth.service';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { ACrudService } from './Authentication/shared/acrud.service';
import { UserPostComponent } from './core/components/user/user-post/user-post.component';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { UserEditComponent } from './core/components/user/user-edit/user-edit.component';
import { ProfileComponent } from './core/components/user/profile/profile.component';
import { ViewProfileComponent } from './core/components/user/view-profile/view-profile.component';

import { ViewOnlyPublicPostComponent } from './core/components/user/view-only-public-post/view-only-public-post.component';
import { AuthGuard } from './Authentication/shared/auth-guard.service';
import { LoadingSpinnerComponent } from './core/components/loading-spinner/loading-spinner.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ResetpasswordComponent } from './Authentication/resetpassword/resetpassword.component';
import { ToastrModule } from 'ngx-toastr';
import { VerifyMailComponent } from './Authentication/verify-mail/verify-mail.component';
import { NoSanitizePipe } from './Authentication/shared/no-sanitize.pipe';

import { BlogComponent } from './shared/blog/blog.component';
import { AutSpecDisorderComponent } from './content/aut-spec-disorder/aut-spec-disorder.component';
import { IdentAutismComponent } from './content/aut-spec-disorder/ident-autism/ident-autism.component';
import { CausePrevalComponent } from './content/aut-spec-disorder/cause-preval/cause-preval.component';
import { AssConditionComponent } from './content/aut-spec-disorder/ass-condition/ass-condition.component';
import { InterventionsComponent } from './content/aut-spec-disorder/interventions/interventions.component';
import { FaqComponent } from './content/aut-spec-disorder/faq/faq.component';

import { WhatAacComponent } from './content/comm-aids/what-aac/what-aac.component';
import { BenAacComponent } from './content/comm-aids/ben-aac/ben-aac.component';
import { TypesAacComponent } from './content/comm-aids/types-aac/types-aac.component';
import { UseAacComponent } from './content/comm-aids/use-aac/use-aac.component';
import { VisTeachingComponent } from './content/know-centre/vis-teaching/vis-teaching.component';
import { PlayLeisureComponent } from './content/know-centre/play-leisure/play-leisure.component';
import { SkillDevComponent } from './content/know-centre/skill-dev/skill-dev.component';
import { VocTrainingComponent } from './content/know-centre/voc-training/voc-training.component';
import { EmpReadComponent } from './content/know-centre/emp-read/emp-read.component';

import { DsmComponent } from './content/hyperlinks/dsm/dsm.component';
import { AppGuideComponent } from './content/vinca-app/app-guide/app-guide.component';
import { VideoTutComponent } from './content/vinca-app/video-tut/video-tut.component';
import { TipsAacComponent } from './content/vinca-app/tips-aac/tips-aac.component';
import { FaqVincaComponent } from './content/vinca-app/faq-vinca/faq-vinca.component';
import { FunctAssestComponent } from './content/hyperlinks/funct-assest/funct-assest.component';
import { NeuroTheoriesComponent } from './content/hyperlinks/neuro-theories/neuro-theories.component';
import { AboutUsComponent } from './content/about-us/about-us.component';
import { CommAidsComponent } from './content/comm-aids/comm-aids.component';
import { KnowCentreComponent } from './content/know-centre/know-centre.component';
import { VincaAppComponent } from './content/vinca-app/vinca-app.component';
import { SensoryDisorderComponent } from './content/hyperlinks/sensory-disorder/sensory-disorder.component';
import { LifeSkillsComponent } from './content/blog/life-skills/life-skills.component';
import { BehaveManagementComponent } from './content/blog/behave-management/behave-management.component';
import { TherapeuticsComponent } from './content/blog/therapeutics/therapeutics.component';
import { LegalRightsComponent } from './content/blog/legal-rights/legal-rights.component';
import { FinanPlanningComponent } from './content/blog/finan-planning/finan-planning.component';
import { OthersComponent } from './content/blog/others/others.component';
import { QuillModule } from 'ngx-quill';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import Quill from 'quill'
import 'quill-emoji/dist/quill-emoji.js';
import BlotFormatter from 'quill-blot-formatter/dist/BlotFormatter';
import { BlogHeaderComponent } from './Authentication/blog-header/blog-header.component';
import { IdentifyingAutismComponent } from './content/hyperlinks/identifying-autism/identifying-autism.component';

Quill.register('modules/blotFormatter', BlotFormatter);






const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

// @ts-ignore
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    MasterPageComponent,
   
    SwitcherComponent,
    HeaderComponent,
    FooterComponent,
    BlogComponent,
    AuthLoginComponent,
    
    AuthLoginThreeComponent,
    AuthSignupThreeComponent,
    HomeComponent,
    UCreatePostComponent,
    UPostDetailComponent,
    AuthComponentComponent,
    UserPostComponent,
    UserEditComponent,
    ProfileComponent,
    ViewProfileComponent,
    ViewOnlyPublicPostComponent,
    LoadingSpinnerComponent,
    ResetpasswordComponent,
    VerifyMailComponent,
    NoSanitizePipe,
    AutSpecDisorderComponent,
    IdentAutismComponent,
    CausePrevalComponent,
    AssConditionComponent,
    InterventionsComponent,
    FaqComponent,
    
    WhatAacComponent,
    BenAacComponent,
    TypesAacComponent,
    UseAacComponent,
    VisTeachingComponent,
    PlayLeisureComponent,
    SkillDevComponent,
    VocTrainingComponent,
    EmpReadComponent,
    DsmComponent,
    AppGuideComponent,
    VideoTutComponent,
    TipsAacComponent,
    FaqVincaComponent,
    FunctAssestComponent,
    NeuroTheoriesComponent,
    AboutUsComponent,
    CommAidsComponent,
    KnowCentreComponent,
    VincaAppComponent,
    SensoryDisorderComponent,
    LifeSkillsComponent,
    BehaveManagementComponent,
    TherapeuticsComponent,
    LegalRightsComponent,
    FinanPlanningComponent,
    OthersComponent,
    NoSanitizePipe,
    BlogHeaderComponent,
    IdentifyingAutismComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ScrollToModule.forRoot(),
    FeatherModule.pick(allIcons),
    NgxTypedJsModule,
    CarouselModule,
    FormsModule,
    NgbModule,
    MatSidenavModule,
    SwiperModule,
    LightboxModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    Ng2SearchPipeModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatToolbarModule,
    AccumulationChartModule,
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
  ],
  exports: [
    FeatherModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    CrudService,
    AuthService,
    ACrudService,
    AuthGuard,
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
