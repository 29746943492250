<section class="bg-half d-table w-100"></section>


<!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

           
        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav> -->

<app-blog-header></app-blog-header>



<div class="row">
    <div class="col-md-3 col-sm-12">
        <div class="list-group">
            <a class="list-group-item" [ngClass]="{ active: isAll == true }" [routerLink]="['/myposts', type[0]]" (click)="getAllPosts()">All Posts </a>

            <a class="list-group-item" [ngClass]="{ active: isPublic == true }" [routerLink]="['/myposts', type[1]]" (click)="getPublicPosts()">Public Posts</a>

            <a class="list-group-item" [ngClass]="{ active: isPrivate == true }" [routerLink]="['/myposts', type[2]]" (click)="getPriavtePosts()">Private Posts</a>
        </div>
    </div>

    <div class="col-md-7 col-sm-12 col-xs-12 post-cards">
        <div *ngIf="isAll && allpost?.length>0;else loading">


            <div class="card row" *ngFor="let i of allpost  ; let c = index">
                <a [routerLink]="[c]">
                    <div class="col-md-4 col-xs-12 img-col col-md-push-8">
                        <img src="{{ i.imgurl }} " alt="Avatar " style="width: 100%;" class="img-responsive" alt="img" />
                    </div>
                    <div class="col-md-8 col-xs-12 main-content col-md-pull-4">
                        <h2 class="title">
                            {{ i.title }}
                        </h2>

                        <p class="desc desc1" [innerHTML]="i.desc| noSanitize"></p>
                    </div>
                    <div class="author-date col-md-6 col-xs-12">
                        <p class="author-name">
                            By : {{ i.name }}
                        </p>
                        <p class="date">
                            On : {{ i.created_date | date }}
                        </p>
                    </div>
                </a>
            </div>
        </div>

    </div>




    <div *ngIf="isPublic  && public_post?.length>0;else loading2" class="col-md-7 col-sm-12 col-xs-12">
        <div class="card row" *ngFor="let i of public_post; let c = index">
            <a [routerLink]="[c]">
                <div class="col-md-4 col-xs-12 img-col col-md-push-8">
                    <img src="{{ i.imgurl }} " alt="Avatar " style="width: 100%;" class="img-responsive" alt="img" />
                </div>
                <div class="col-md-8 col-xs-12 main-content col-md-pull-4">
                    <h2 class="title">
                        {{ i.title }}
                    </h2>

                    <p class="desc desc1" [innerHTML]="i.desc| noSanitize"></p>
                </div>
                <div class="author-date col-md-6 col-xs-12">
                    <p class="author-name">
                        By : {{ i.name }}
                    </p>
                    <p class="date">
                        On : {{ i.created_date | date }}
                    </p>
                </div>
            </a>
        </div>
    </div>

    <div *ngIf="isPrivate  && private_post.length>0;else loading3" class="col-md-7 col-sm-12 col-xs-12">
        <div class="card row" *ngFor="let i of private_post; let c = index">
            <a [routerLink]="[c]">
                <div class="col-md-4 col-xs-12 img-col col-md-push-8">
                    <img src="{{ i.imgurl }} " alt="Avatar " style="width: 100%;" class="img-responsive" alt="img" />
                </div>
                <div class="col-md-8 col-xs-12 main-content col-md-pull-4">
                    <h2 class="title">
                        {{ i.title }}
                    </h2>

                    <p class="desc desc1" [innerHTML]="i.desc| noSanitize"></p>
                </div>
                <div class="author-date col-md-6 col-xs-12">
                    <p class="author-name">
                        By : {{ i.name }}
                    </p>
                    <p class="date">
                        On : {{ i.created_date | date }}
                    </p>
                </div>
            </a>
        </div>

    </div>


    <ng-template #loading>

        <div *ngIf="isAll && isFetching" class="loading-text">
            <app-loading-spinner></app-loading-spinner>
        </div>

        <div *ngIf="isAll &&  !isFetching" class="no-text">

            <h3>No Post Found</h3>
        </div>

    </ng-template>
    <br>

    <ng-template #loading2>

        <div *ngIf="isPublic && isFetching" class="loading-text">
            <app-loading-spinner></app-loading-spinner>
        </div>

        <div *ngIf="isPublic &&  !isFetching" class="no-text">

            <h3>No Public Post Found</h3>
        </div>

    </ng-template>

    <br>
    <ng-template #loading3>

        <div *ngIf="isPrivate &&  isFetching " class="loading-text">
            <app-loading-spinner></app-loading-spinner>
        </div>

        <div *ngIf="isPrivate &&  !isFetching" class="no-text">

            <h3>No Private Post Found</h3>
        </div>
    </ng-template>

     <div class="col-sm-4 col-xs-12">

     </div>
</div> 
<br>