import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vis-teaching',
  templateUrl: './vis-teaching.component.html',
  styleUrls: ['./vis-teaching.component.css']
})
export class VisTeachingComponent implements OnInit {
  panelOpenState = false;
   
  constructor() { 
   
     
  }

  ngOnInit(): void {
    
  }
}
