import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aut-spec-disorder',
  templateUrl: './aut-spec-disorder.component.html',
  styleUrls: ['./aut-spec-disorder.component.css']
})
export class AutSpecDisorderComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
