<!-- <div class="row">
    <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let data of blogData">
        <div class="card blog rounded border-0 shadow">
            <div class="position-relative">
                <img src="{{data.image}}" class="card-img-top rounded-top" alt="...">
                <div class="overlay rounded-top bg-dark"></div>
            </div>
            <div class="card-body content">
                <h5><a href="javascript:void(0)" class="card-title title text-dark">{{data.title}}</a>
                </h5>
                <div class="post-meta d-flex justify-content-between mt-3">
                    <ul class="list-unstyled mb-0">
                        <li class="list-inline-item me-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                                    class="uil uil-heart me-1"></i>{{data.like}}</a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                                    class="uil uil-comment me-1"></i>{{data.message}}</a></li>
                    </ul>
                    <a routerLink="/home" href="javascript:void(0);" class="text-muted readmore">Read More
                        <i class="uil uil-angle-right-b"></i></a>
                </div>
            </div>
            <div class="author">
                <small class="text-light user d-block"><i class="uil uil-user"></i> {{data.name}}</small>
                <small class="text-light date"><i class="uil uil-calendar-alt"></i> {{data.date}}</small>
            </div>
        </div>
    </div>
</div> -->











 <div class="row">
  <div *ngIf="sorted | filter: searchText as result">
    <div *ngIf="!isFetching && result?.length > 0">
      <div class="card row" *ngFor="let i of result.slice(0,4); let c = index">
        <a routerLink="/home">
          <div class="col-md-4 col-xs-12 img-col col-md-push-8">
            <img
              src="{{ i.imgurl }} "
              alt="Avatar "
              style="width: 100%"
              class="img-responsive"
              alt="img"
            />
          </div>
          <div class="col-md-8 col-xs-12 main-content col-md-pull-4">
            <h3 class="title">
              {{ i.title }}
            </h3>

            <p class="desc desc1" [innerHTML]="i.desc | noSanitize"></p>
          </div>

          <div class="author-date col-md-6 col-xs-12">
            <p class="author-name">By : {{ i.name }}</p>
            <p class="date">On : {{ i.created_date | date }}</p>
          </div>
        </a>

        <a
          routerLink="/home"
          href="javascript:void(0);"
          class="text-muted readmore"
          >Read More <i class="uil uil-angle-right-b"></i
        ></a>
      </div>
    </div>

    <div *ngIf="isFetching && !error" class="loading-text">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</div> 

















