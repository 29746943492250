<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
  <li>Functional Assessment </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Functional Assessment  
</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
  <div class="mycontent">
    <p>
      The development pattern of each individual with autism can be different than the development pattern of non-autistic individuals. Due to the uneven skill development, educational programs for the individual with autism must be based on the functional assessment carried out by professionals like Special educator or rehabilitation professionals.  

Functional assessment is conducted with a focus on real life situations. It can be carried out by systematic observation of the child and by interviewing the child's parents and teachers. It helps determine the existing skill set and future goals, based on which the individualized education program is prepared.  

Functional assessment is also an ongoing process which goes on throughout the intervention to evaluate the progress of the individual due to the interventions.  

The following points must be considered while carrying out functional assessment for a child with autism:  
    </p>

    <ul>

      <li>The variation in the learning styles,  </li> 

    <li>  Uneven skill development </li> 
        
    <li>   Interests and dislikes of the individual  </li>
        
     <li>  Sensory issues </li>  
        
    <li>  Attention span  </li>
        
      <li>  Reactions to changes and new experiences </li> 
        
      <li> Challenging behaviors  </li>
        
       <li>Generalization of skills in different environments, with different teaching materials.  </li>
    </ul>
<br>
    <div class="head2">
      <h3>Tools of Functional Assessment </h3>
    </div>
    <hr>
<b>Psychoeducational Profile Revised (PEP-R) </b>
    <p>
      The PEP-R offers a developmental approach to the assessment of children with autism or related developmental disorders and is designed to identify idiosyncratic learning patterns. The developmental scale consists of seven developmental areas: (a) imitation, (b) perception, (c) ﬁne motor activity, (d) gross motor activity, (e) eye-hand coordination, (f) cognitive performance, (g) cognitive verbal operations. 
    </p>

    <b>Adolescent and Adult Psychoeducational Profile (AAPEP) </b>
    <p>AAPEP is a tool for evaluating an adolescent's or adult's abilities and behaviors in relation to work tasks, work environments, and daily living or self-care skills. It comprised of three separate, but related sets of evaluations I.e., Direct Observation by an examiner, School/work evaluation by teacher or supervisor and home evaluation by parent or caregiver. </p>

    <b>TEACHH Transition Assessment Profile (TTAP) </b>
    <p>TTAP is revised Adolescent and Adult Psychoeducational Profile. It focuses on six functional / transition areas - Vocational skills, Vocational behaviors, independent functioning, Leisure skills, Functional communication, Interpersonal behaviors. </p>
    
    <b>Assessment of Basic Language and Learning Skills (ABLLS) </b>
    <p>ABLLS is an assessment, curriculum guide and skills tracking system for children with language delays. It reviews 544 skills from 25 skill areas including language, social interaction, self-help, academic and motor skills. </p>
  </div>