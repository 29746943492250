<!-- Hero Start -->
<section class="bg-half d-table w-100"></section>
<section
  class="bg-home rtl-personal-hero d-flex align-items-center test"
  id="home"
>




  <div class="container ">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-9">
        <div class="title-heading mt-4">
          <h1 class="display-3 fw-bold mb-3">
            Here I'm <br />
            <br />
            <ngx-typed-js
              [strings]="['Different', 'Imaginative', 'Mindful']"
              [shuffle]="true"
              [typeSpeed]="80"
              [loop]="true"
            >
              <span class="element typing text-primary"></span>
            </ngx-typed-js>
          </h1>
          <br />
          <p class="para-desc text-muted">
            Being a Parent itself is a responsibility which needs lot of
            attention care warmth discipline and much more.
          </p>
        </div>
      </div>

      <!-- <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <img src="assets/images/personal/mother-son.jpg" alt="">
      </div> -->
      <!--end col-->
    </div>
    <!--end row-->

    <!--end login button-->
  </div>

  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- About Start -->
<section class="section" >
  <div class="bg-info">
  <div class="container ">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="section-title">
          <h4 class="title">Learn About Autism</h4>
          <h6 class="text-primary mb-4">Optional Heading</h6>
          <p class="text-muted mb-0">
            We are family members of those on the spectrum and are sharing our
            experience including education, speech-language pathology, social
            work, psychology, special education administration, applied behavior
            analysis and early childhood education.
          </p>
        </div>
      </div>
      <!--end col-->

      <!--end col-->
    </div>
    <!--end row-->
    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body" style="background-color: pink">
                <p class="text-muted mt-4">
                  <i>
                    “The difference between high-functioning and low-functioning is that high-functioning means your deficits are ignored, and low- functioning means your assets are ignored. ”
                  </i>
                </p>
                <h6 class="text-primary">- Laura Tisoncik</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body" style="background-color: rgb(96, 214, 96)">
                <p class="text-muted mt-4">
                  <i
                    >" Autism doesn't have to define a person. Artists with
                    autism are like anyone else: They define themselves through
                    hard work and individuality. "
                  </i>
                </p>
                <h6 class="text-primary">- Adrienne Bailon</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div
                class="card-body"
                style="background-color: rgb(247, 226, 104)"
              >
                <p class="text-muted mt-4">
                  <i>
                    " It is paradoxical that many educators and parents still
                    differentiate between a time for learning and a time for
                    play without seeing the vital connection between them. "
                  </i>
                </p>
                <h6 class="text-primary">- Leo Buscaglia</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body" style="background-color: rgb(221, 81, 81)">
                <p class="text-muted mt-4">
                  <i>
                    “Who do you think made the first stone spears? The Asperger
                    guy. If you were to get rid of all the autism genetics,
                    there would be no more Silicon Valley.”
                  </i>
                </p>
                <h6 class="text-primary">- Dr. Temple Grandin</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body" style="background-color: rgb(207, 105, 233)">
                <p class="text-muted mt-4">
                  <i
                    >" When enough people care about autism or diabetes or
                    global warming, it helps everyone, even if only a tiny
                    fraction actively participate."
                  </i>
                </p>
                <h6 class="text-primary">- Seth Godin</h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card customer-testi text-center border rounded mx-2">
              <div class="card-body" style="background-color: rgb(0, 238, 255)">
                <p class="text-muted mt-4">
                  <i>
                    "I might hit developmental and societal milestones in a different order than my peers, but I am able to accomplish these small victories on my own time. "
                  </i>
                </p>
                <h6 class="text-primary">- Haley Moss</h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
      <!--end col-->
    </div>
  </div>
  <!--end container-->

  <!--end container-->
</div>
  <!--end container-->
</section>
<!--end section-->
<!-- About End -->

<!-- Blog Start -->
<section class="section " style="background-color:rgb(211, 255, 255) ;">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Blog</h4>
          <p class="text-muted para-desc mb-0">
            Please <span class="text-primary fw-bold">Unfold</span> your story
            and help others
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-blog ></app-blog>
  </div>
  <!--end container-->

  <!--end container-->
</section>
<!--end section-->
<!-- Blog End -->
<!-- Back to top -->
<a
  href="javascript: void(0);"
  class="btn btn-icon btn-primary back-to-top"
  id="back-to-top"
  [ngxScrollTo]="'#home'"
>
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
