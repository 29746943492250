import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-vinca',
  templateUrl: './faq-vinca.component.html',
  styleUrls: ['./faq-vinca.component.css']
})
export class FaqVincaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
