<section class="bg-half  d-table w-100" id="home"></section>

<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/know-centre">Knowledge Centre</a></li>
  <li>Skill Development </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
    <div class="hero_innercontainer" data-uw-styling-context="true">
      <div class="hero_content" data-uw-styling-context="true">
        <h2 class="hero_title" data-uw-styling-context="true">
          <span data-uw-styling-context="true">Skill Development</span>
        </h2>
      </div>
    </div>
  </div>
</div>



<div class="mycontent">

  <p>For the intellectual and social development of any child, the early childhood years are very crucial. Hence, early
    intervention programs can be extremely useful for children who are diagnosed with autism in early childhood. In
    these programs the focus is on pre-learning and pre-academic skills which are the foundation of a child’s academic
    learning. These skills are the basic skills that a child needs for future academic success and for independence in
    life as well.
    The following points should be remembered while working with a child with autism.
  </p>
  <ul>
    <li> Errorless Teaching</li>
    <li>
      Clear, short instructions</li>
    <li> Use of prompts and fading it gradually</li>
    <li> Minimum Verbal prompt</li>
    <li> Use of visuals and structures</li>
    <li> Keeping yourself calm while teaching </li>
    <li> Well organized material for work</li>
    <li> 80% achieved or nearly achieved work & 20% new work</li>
    <li> Generalize the learned skills in environment</li>
    <li> 4 E's while teaching the child are - Energy, Excitement, Enjoyment, and Enthusiasm</li>
    <li> REINFORCE…. ALWAYS
    </li>
  </ul>
  The connection and bonding of a child with teacher, parents or caregiver is most important as it motivates the child
  to learn from them. It gives them the message that they matter, that they are important, that they are loved, that
  they are worth your time. It also increases a child's self-esteem. If they are worth your time, then they have value.
  If they are worthy of your love, they are lovable.
  Always follow principles of teaching, that are
  <ul>
    <li> Simple to Complex</li>
    <li> Known to the unknown</li>
    <li> Concrete to abstract</li>
    <li> Whole to part</li>
  </ul>


  
        <div class="head3">
          Pre-learning Skills</div>
          <hr>
    <div class="mycontent">
      <div class="row">
        <div class="column">
          <p>Neurotypical children learn a lot from their immediate surroundings, by observation and as a natural part
            of growth and development. But in children with autism many of the skills required for learning do not
            develop naturally and they need to learn `how to learn` first. </p>
          <p>Pre-learning skills are core skills required for learning. Pre-learning skills are necessary to achieve
            before teaching the child academic skills. They are the foundation of child's academic learning and
            cognitive development. They are required for child's future academic success. Strong pre-academic skills
            lead to academic competence and independence in life as well.</p>
          <p>Children with autism struggle to communicate their basic needs to their parents and caregivers and such
            pre-learning skills need to be inculcated in them with lots of efforts. </p>
          Some of the pre-learning skills are:
          <ul>
            <li>Eye contact </li>
            <li> Attention & Joint attention</li>
            <li> Sitting behavior</li>
            <li> Following basic instructions</li>
            <li> Turn taking and waiting</li>
            <li> Cooperation</li>
            <li> On- task behavior</li>
            <li> Eye hand coordination</li>
            <li> Gross and fine motor skills</li>
          </ul>
        </div>
        <div class="column1">
          <img src="assets/images/content/mydown-img/image 27.jpg">

        </div>
      </div>
    </div>
  






  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="head3">
          Eye contact</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <p>
        One of the prominent symptoms of autism is lack of eye contact. People with autism are less likely to look
        directly at another person's eyes. There can be various reasons behind it such as:
      </p>
      <ul>
        <li> Lack of social motivation to communicate with others</li>
        <li> Difficulty in focusing on spoken language and making eye contact at a time</li>
        <li> Can be overwhelming sensory experience</li>
        <li>Changes in facial expression of the other person might be annoying and confusing
        </li>
      </ul>

      <p>So, if the person with autism is not making eye-contact, we should not compel them to look into the eyes as it
        can create anxiety in them. Focus of the therapist should be on development of general communication skills of
        the child apart from development of eye contact.</p>

      <b>How to build eye contact in home environment?</b>
      <ul>
        <li> <b>Mealtime</b> - Mealtime is a great time to practice eye contact. When you have a spoonful of food ready,
          get the baby's attention and raise the spoon to the line of vision between your eyes and baby's eyes. Reward
          the meeting of the eyes with smiles and coos while you direct the spoon into the mouth. With toddlers and
          preschoolers, wait for your eyes to meet before sharing food or handing them their cup of milk.
        </li>

        <li> <b> Play time</b> - With young children, use peek-a-boo game to practice getting and making eye contact.
          Use a smile, tickle, laugh, hug, squeeze, making funny faces, making funny sounds, or whatever your child
          likes, to reinforce when your eyes meet. Make eye contact with smile on your face showing that you enjoy, when
          you stand in front of a swing and push the swing, or while catching him at the bottom of the slide, or when
          you catch him as he jumps in a pool. When blowing bubbles, pause until your eyes meet. Play facial imitation
          games where you make a face, then your child imitates it and vice versa. Put stickers on their faces or wear
          funny hats, look in the mirror together to get eye contact instead of having the child look directly at your
          face.
        </li>

        <li><b> Conversations </b>- Talk to the child, as we talk with any toddler / neurotypical person. But before
          that get your child's attention, say his name, and wait until he looks at you. If he does not look at you
          because he is holding a toy in his hands, move closer to him and gently place your hands on top of his toy to
          get his attention. Then call his name again and wait for him to look up at you. Encourage and reinforce eye
          contact when you sing songs, recite nursery rhymes, and read books with him.
        </li>

        <li> <b>Requesting </b>- When your child asks you for a toy or food, wait for eye contact before handing off the
          toy or food item that your child wants. You can hold the item in front of your eyes and when he looks at the
          object and looks at you, then give him the toy.
        </li>

        <li> Stay connected with the child though you are working in kitchen or while speaking on the phone by just
          patting his back in between or giving him hugs just to show that you are connected.
        </li>

        <li>Reinforce every time he looks at you by addressing it by saying, “I love how you look at me when I talk to
          you,” “Thank you for looking,” “Great looking at me!”
        </li>

        <li> Whenever you are calling name of your child, it should be for certain purpose and not for just taking his
          eye contact. As the purposeless eye contact can be annoying for the child and may demotivate him from looking
          at you.
        </li>

        <li><b> Teaching time </b>- While teaching the child, sit at child's eye level. It is hard for kids to look up
          continuously at adults. Try looking up for a while and notice how tired your eyes get. Notice how much easier
          and how much more comfortable it is to look straight ahead. Stand in your child's line of sight. He
          effortlessly can see your face & those wonderful expressions on your face makes him motivated to learn from
          you.
        </li>

        <li> <b>Remember…..</b> it can be extremely hard for a child with autism to look at you and listen to you at the
          same time. It also may be hard for your child to look at you while you are holding or touching him because
          some children with autism cannot process multiple senses at the same time. So it might be that he will look at
          you once you have finished talking.

        </li>

      </ul>

    </div>
  </mat-expansion-panel>
<br>



  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="head3">
          Attention and Joint attention  </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">

      <p>Children with autism find it difficult to give attention while learning or doing a task. They may not be able to filter out distractions and give attention to more important activity being performed. E.g., even if you are trying to have the child participate in a very interesting game, he may be engaged in looking at the moving curtain. 
      </p>

      <p> Joint attention occurs when two people share interest in an object or event and there is understanding between them that they are both interested in the same object or event. It occurs in 2 ways i.e., the child responding to another person's bid for joint attention and the child initiating joint attention.
      </p> 

       <p>  Joint attention by the child is more important because it shows that the child is socially motivated. One of the core deficits of a person with autism is social motivation. Children with autism lack joint attention where typically kids follow the line of vision of other person to look at the thing that the other person is looking/doing. For them attending must be a learned skill. 
        </p>

        <strong>Attending skills can be improved by doing the following things:</strong>
        <ul>
        <li>
          Slowly increasing the time spent at an activity and pairing with preferred activities.</li>
<li>	Make the environment as little distracting as possible. This may require setting up a place with less furniture and display items or removing things that may distract the child. Even the colors and images on the walls may be distracting. </li>
<li>	Identify the activities or things that the child is most interested in. He may not be interested in typical toys but interested in things such as string, shaving foam, music, or bubbles. Pair the child’s preferred activities with other activities. </li>
<li>	Try to engage the child in an activity for some time and then change the activity if needed. </li>
<li>	Choose activities that have definite end points, clear guidelines, and goals</li>
<li>	The child may have different threshold levels for different activities depending on their interest. Initially, it could mean just doing the preferred activity of the child and then slowly transitioning him to other activities for a short period of time. </li>
<li>	If he wants to leave the activity midway, help him complete the activity with full assistance (like hand over hand prompt) so that he gets a message that he cannot leave any activity midway.</li> 
<li>	Develop familiar games and routines. </li>
<li>	Keep the length of activities short (maybe increasing later with time) to reduce frustration.

        </li>
      </ul>
      </div>
    </mat-expansion-panel>
  
<br>


    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="head3">
            Sitting behavior  </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mycontent1">

<p>Developing sitting behavior is essential for learning. To develop sitting behavior, start slow and gradually increase the sitting time. E.g., if a child can sit for 5 minutes, the first target can be to increase the time to 7 minutes.</p>
<ul>
  <li>	Identify a set of motivating things for the child. These are called 'primary reinforcers. E.g., chocolates, chips, bubbles, music, mobile. Out of these as well, there could be an item which is most motivating. That would be the highest reinforcer (e.g., out of chocolates, chips, bubbles, music, mobile; mobile may act as highest reinforcements).</li> 
    <li>	It is advised to prefer tangible reinforcers (which would finish on their own like food items) rather than non-tangible ones which are required to be taken away.</li>
  <li>	Primary reinforcers should be paired with social praise. </li>
<li>	Sit with the child along with a preferred activity. It could just be singing a song, reading a picture book, or sand play. 
    </li>

<li>	Reinforce the child's success in sitting for 5 minutes by praising him - 'good sitting' and giving him a reinforcer. And continue for another 2 minutes of sitting for an activity. At the end of the target time, give the highest reinforcer to the child.</li> 
  <li>	To encourage the sitting behavior, follow the child's lead and make the sitting time interesting by using motivating activities and changing activities as needed.</li> 
<li> Attending and sitting skills go hand in hand. If the child attends to a task, the sitting behavior improves. Before starting to work with the child, it is most important to build a positive rapport with the child and make the interaction fun and least stressful. You can just sit and play with the child for developing the sitting and attending behaviors.
  </li>


</ul>

      </div>
    </mat-expansion-panel>
  
<br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="head3">
            Following basic instructions </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mycontent1">

<p>If the child's receptive language is much delayed, teaching to follow	instructions may be difficult. Following instructions require a lot of steps that include paying attention, understanding, memory and ability for the task. Before starting with tabletop learning, it is important to develop an understanding of a few basic instructions which can help the child in responding to you. Instructions like 'touch,' 'give,' 'pick,' 'point' tells the child a way to respond. </p>

<ul>
  <li>	Before giving any instruction, get down to their eye level and make physical contact to help bring their attention to you.</li>
    <li>	Make instructions short and clear. Instead of saying 'give the car you are holding to me,' just say 'give car' and hold out one hand. </li>
  <li>	Repeat key words to help your child focus. For example, say 'Ball', 'Roll the ball', 'Catch the ball', 'Kick the ball'.</li>
<li>	Avoid repeating instructions as it may be reinforcing your child's non-compliance. It could also give the impression that you are not serious when you instruct him, which would make him less inclined to listen to you.</li>
<li>	Making sure you are giving an instruction, not a request - for example, 'Hold my hand while we cross the road,'' rather than 'Do you want to hold my hand?'</li>
<li>	Telling your child what you want them to do, rather than what you do not want them to do - for example, 'Rachel, walk when you're inside,' rather than 'Don't run, Rachel'</li>
<li>	After giving instructions wait for a few seconds and if the child does not respond, follow through the instructions by guiding him physically to give the car in your hand.</li>
    

<li>	Use visuals to support verbal instructions.</li>
  <li>	Following through on what you have asked.</li>
<li>	Give only those instructions which the child can do, or you can follow through. E.g., If you ask the child to jump, he should be able to jump on his own or you should be able to pick him up make him jump. But if the child is not able to jump and you are unable to pick him up to do the task, never give such instruction.</li>
<li>	You should reinforce your child with something he or she likes in addition to positive feedback.</li>
<li>You should remain calm and as neutral as possible when your child does not comply with instructions. Sometimes, children show noncompliance intentionally to provoke a reaction from you. Staying neutral can discourage such deliberate non-compliance.</li>
  Examples of basic instructions:
  <ul>
    <li>
  wipe table with cloth</li>
<li>	comb hair</li>
<li>	roll car</li>
<li>	join blocks</li>
<li>	wear cap</li>
<li>	wipe mouth with hanky</li>
<li>	Wave bye</li>
<li>	Touch head</li>
<li>	Raise hand</li>
<li>	Touch toes
  </li>
</ul>

</ul>
  </div>
    </mat-expansion-panel>

<br>






    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="head3">
            Turn taking and waiting   </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mycontent1">

<p>Taking turns is a social skill that requires patience, impulse control, and communication skills. It also requires the ability to understand why we share things with others, what to do while waiting for your turn, and knowing when it is time to take your turn. Waiting is an abstract concept and includes concept of time. The words like; “wait for some time, you will get it later, we will go there one day…all these are meaningless promises for autistic child.</p>
<ul>
  <li>
<b>	Playing with toys </b>- Play simple turn taking games like banging on a drum with a stick one by one.</li>
<li><b>	Visual cues </b>- Use turn taking and wait cards.</li> 
<li> <b>Clear language</b> - Use clear terms such as “My turn” and “Your turn” when building turn-taking skills.</li>
<li> <b>Model Turn-Taking</b>- You can model turn taking in general environment in front of your child e.g., while eating biscuits at tea-time.</li>
<li>	<b>Use a timer </b>- Timer is a very good auditory and visual cue.</li> 
<li>	Create opportunities during the day for the child to wait for something. E.g., when you are all set to go to park, before opening the home door for going out, you ask your child to wait for a minute so that you can bring water bottle. Here, waiting for a minute is introduced naturally and the child waits as he is interested in going to park.</li>
<li>	These skills can most effectively be practiced in day-to-day settings and interactions. </li>

</ul>

      </div>
    </mat-expansion-panel>
<br>





    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="head3">
            Cooperation   </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mycontent1">

<p>Cooperative behavior helps children succeed at school, in relationships with others and in extracurricular activities. It is also important for a happy and harmonious life. Cooperation involves several important skills like sharing, taking turns and following instructions from others. Children need these skills to communicate and get along with others in most social situations.</p>
<p>Through fun, engaging activities while at home, in school or out in the community, cooperation can be taught to children. Activities such as cooking, gardening, house cleaning can be turned into a small group or family activity where you can divide the work and have your child practice skills such as measuring grains, pouring liquids, potting seeds, planting flowers, digging holes and watering, dusting furniture, mopping floor etc.</p>
<p>Playing games lend themselves to determining teams, resolving conflicts, and being cooperative. </p>
<p>We can teach the children to cooperate in various situations by applying following strategies:</p>

<ol>
  <li><b>	Setting limits</b></li>
  <p>The setting limits defines boundaries and provides guidance for a child's actions, defining which actions are acceptable and which are unacceptable.  It sends a firm message about what your child can do and can't do. Children with autism have difficulties in understanding unwritten and invisible rules and boundaries. They require very concrete, clear, and consistent boundaries that provide predictability and will help in decreasing anxiety. </p>
  <ul>
<li>	Set an outer limit - Instead of restricting your child to very few things, allow your child to have some free range I.e., set an outer limit. For example, they may play within the society compound, but they are not allowed to go out of society compound without a family member.</li>
  <li>	Rules for everyone - Repetition and consistent efforts allows a child with autism to understand their limits and expectations. That means everyone in the home must follow the same standard and do so consistently.</li>
  <li>	Follow through the decisions - Any decision about the goals, may it be the amount of time the child can spend on his digital devices or every night sleep time, create a standard and follow through it for long-term. The child will be able to understand what his goals and expectations are. </li>
<li>E.g., bedtime on a school night is 9 pm. When you set limits, it is important to follow through on your expectations. This shows your child that you mean what you say. So, if bedtime is 9 pm, you need to stick to this.
  </li>
</ul>

<li>	<b>Offering choices</b></li>
<p>When children have choices, they learn to make decisions and think	for themselves. This is good for your child’s self-esteem as well as their ability to cooperate. </p>
<p>  An effective way to give your child choices is to offer a limited range of options – two are good. For example, ‘Setu, it is lunchtime. Would you like a cheese sandwich or a Vegemite sandwich?’ Or ‘Rani, it is time to get dressed. Would you like to wear this skirt or these jeans?’ You can give your child the opportunity to make choices every day – for what toys to play with, books to read, clothes to wear, snacks to eat, parks to play at, or projects to work on.
  </p>


  <li><b>	Changing the environment</b> </li>
  <p>Start by thinking about situations in which your child is consistently uncooperative. For example, your child is uncooperative if you go out to eat, but fine at home at mealtimes. This might be because your child is used to a particular routine at home or prefer to eat from their own special plate. Or your child is uncooperative in crowded, noisy environments, which might be because they are sensitive to sounds. There might be things you can do to help your child feel more comfortable and therefore more cooperative:</p>
  <ul>
  <li>	Use your child’s comfort objects in situations where they are uncooperative. For example, take a special plate from home if you go out to eat.</li>
    <li>	Compromise between what you need to do and your child’s sensitivities. For example, go to the shopping center at quieter times of day.
    </li>
  </ul>
</ol>

      </div>
    </mat-expansion-panel>
<br>


<mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div class="head3">
        	On- task behavior   </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <div class="mycontent1">

    <p>Children who can stay on task are able to complete activities independently or with minimal assistance.</p>
    <ul>
   
      <li>	Use schedules to offer predictability about the routines</li>
    <li>	Create structured environment</li>
  <li>	Be prepared with material required for completing the given task.</li>
<li>	Use of visuals for tasks such as work system or pictures of steps for completing the task</li>
    </ul>

  </div>
</mat-expansion-panel>
<br>



  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="head3">
       	Eye hand coordination  </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <p>Eye-hand coordination is an important perceptual-motor skill children develop during the early years. It is the ability to perform movements with the hands while being guided by the eyes. E.g. child taking an object from adults hand, catching the ball, writing etc. Reading and writing require well-developed visual tracking skills. It's important that these skills are developed at an early age and hence early stimulation is crucial. Following activities can help in exercising the brain's ability to coordinate the hands and eyes simultaneously.</p>
      <ol>
        <li>	Throwing and catching a Ball</li>
      <li>	Threading and lacing</li>
    <li> 	Wooden puzzles</li>
  <li>	Finger painting</li>
<li>	Building with blocks</li>
<li>	Pushing pegs into a pegboard</li>
<li> 	Drawing and painting on different surfaces</li>
<li> 	Cutting and pasting activities</li>
<li> 	Copying shapes, letters, and numbers</li>
<li> 	Threading and Lacing</li>
          
      </ol>


  </div>
</mat-expansion-panel>
  <br>


  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="head3">
      	Gross and fine motor skills   </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
<p>Gross motor skills are important to enable children for basic functions such as sitting, standing, walking, playground skills (running, skipping, climbing), sporting skills (catching, throwing, and hitting a ball with a bat) and crucial for everyday self-care skills like dressing, bathing etc. Gross motor abilities are required for coping with a full day of school (sitting upright at a desk, moving between classrooms, carrying your heavy school bag). Also, a child’s ability to maintain tabletop posture (upper body support) will affect their ability to participate in fine motor skills, e.g., writing, drawing, and cutting.</p>
<p> <b> Following things can be done to improve gross motor skills: </b></p>
<ul>
<li>	Strengthen the core i.e., large central muscles of the body through various exercises to provide better body stability.</li>
  <li>	Use backward chaining technique to teach specific physical skills. Then gradually add together all the steps to perform the entire skill. E.g., catching a ball - teach to catch a big ball from very short distance like 1 foot. As it gives sense of achievement to the child that he could catch the ball.</li>
<li>Gradually increase duration and intensity of activity to increase endurance. E.g., the distance of throwing the ball towards the child can be increased gradually.</li>
<li>	Improve sensory processing to ensure appropriate attention and arousal to attempt the tasks. Also ensuring the body is receiving and interpreting the correct messages from the muscles in terms of their position, their relationship to each other, and the speed at which they move and how much force they are using.</li>
<li>Multi-sensory approach to learn new skills will ensure a child has the best chance of learning appropriate strategies to respond to a physical demand or challenge.</li>
<li>	Cognitive planning strategies can be used to take the child through tasks (e.g., 'Always point to where you are aiming').
  </li>
</ul>
  <p> <b> Following activities can help improve gross motor skills:</b></p>
<ul>
<li>
  Hopscotch for hopping, or other games that encourage direct task/skill practice.</li>
<li>	Simon Says for body awareness and movement planning (praxis).</li>
<li>	Wheelbarrow walking for upper body strength and postural or trunk control.</li>
<li>	Walking/climbing over unstable surfaces as it requires a lot of effort and increases overall body strength.</li>
<li>Catching and balancing - Standing with one foot on a ball while catching another ball</li>
<li>		Begin catching with a large ball and when the skill is mastered, move to a smaller sized ball. </li>
<li>		Obstacle courses: to combine lots of gross motor skills together into one practice. </li>
<li>		Playground climbing and swinging</li>
<li>		Walking backward</li>
<li>		Walking sideways</li>
<li>	Target hit with ball</li>
<li>	Kick ball</li>
<li>	Hit static ball with bat</li>
<li>	One leg standing</li>
<li>	Jumping off the ground - forward, backward, at same place</li>
<li>	Swimming</li>
</ul>


<p>Fine motor skills are important for performing self-care activities such as dressing up, cleaning teeth, eating, drinking and academic skills such as pencil skills of drawing, writing, and coloring, as well as cutting and pasting. Without the ability to complete these tasks, a child is unable to develop appropriate independence in life skills and his academic performance is also compromised. </p>

<p><b>Following things can be done to improve fine motor skill:</b></p>
<ul>
  <li><b>	Hand dominance </b> - Determine the dominant hand of the child as per his preference to use the hand and reinforce its more frequent use in task performance.</li>
    <li>	<b>Bilateral integration -</b> Few tasks require use of both hands e.g., use the 'doing hand' to place the block and the 'helping' hand to hold the block construction steady.</li>
  <li> <b>Finger isolation -</b>  Practice tasks that use just one or two fingers. E.g., 'poking' games by using clay or play dough.</li>
<li> <b>Hand and finger strength -</b>  Enhance finger strength by using pegs or clips in play.</li>
<li>	Encourage enjoyment in activity participation instead of focusing on a 'successful' outcome (e.g. rewarding pencil to paper attempts, not whether the drawing actually looks like a car or a house). 
    </li>
</ul>

<p><b>Activities that can help improve fine motor skills:</b></p>
<ul>
<li>	Threading and lacing with a variety of laces and sizes of beads.</li> 
  <li>	Tongs or teabag squeezers to pick up objects (e.g., cotton balls or pebbles picked up by tong and put in the jar.</li>
<li>	Manipulation games such as 'Pick up Sticks' and 'Connect 4'.</li>
<li>	Play-dough - Using the fingers working with the Playdough up in the air, not flat on the table.</li>
<li>	Construction games that require pushing and pulling   with fingers e.g., Lego, building blocks etc.</li>
<li>	Storing play or stationary materials in jars with screw lids that need to be opened and closed.</li>
<li>	Art and craft activities can be done by using old boxes, egg cartons, wool, paper and sticky or masking tape.</li>
<li>	Beading on a vertical rod</li>
<li>	Pulling clothe peg from container & also fixing them</li>
<li>	Picking up objects of different thickness, size, and weight</li>
<li>	Threading bead on wire, shoelace</li>
<li>	Fixing pieces of jigsaw puzzle</li>
<li>	Scribbling with thick pen/crayon</li>
<li>	Coloring within boundary</li>
<li>	Folding paper on a line</li>
<li>	Using scissors to snip</li>
<li>	Cutting on a line</li>
<li>	Cutting different shapes</li>
<li>	Pasting shapes on an outlined picture</li>
<li>	Flipping pages of a book</li>
 
  </ul>

    </div>
  </mat-expansion-panel>


  <br>
  
        <div class="head3">
          Pre-academic skills
        </div>
      <hr>
    <div class="mycontent">
      <div class="row">
        <div class="column2">

          <img src="assets/images/content/mydown-img/image 28.jpg">
        </div>

        <div class="column3">
          <p>Pre-academic skills are an important part of a child's cognitive development which includes information
            processing, conceptual understanding, perceptual skill, and language learning. It helps a child to think
            about and understand the world around him. Pre-academic skills are the foundation of a child's academic
            learning. Strong pre-academic skills lead to academic competence and are also required for independence in
            life. Some of the pre-learning skills are:</p>
          <ul>
            <li>
              <b>Imitation skills -</b> These skills are pivotal aspect of skill development because they help in
              learning new things quickly and efficiently by watching other people in the surrounding. Imitation skills
              are important in learning activities of daily living, in communication and language development.
            </li>
            <li><b> Attending skills -</b> Attending skills are needed for learning, becoming more self-aware, and being
              able to participate with others in play or social situations.</li>
            <li> <b>Cognitive skills -</b> These skills include matching, basic cognitive concepts, number concepts,
              reading and writing skills.</li>
          </ul>
        </div>
      </div>
    </div>


  
    











    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="head3">
            Imitation skills  </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mycontent1">
<p>Imitation skills are fundamental aspect of all types of skill development such as activities of daily living, communication, and language development. Imitation skills help in learning new things quickly and efficiently by watching other people in the surrounding. Imitation skills are important in learning. Imitation involves copying body actions and actions with objects, sounds, and words. </p>
<p><b>Imitation with objects -</b> Children with autism need concrete things to learn any concept and hence, imitation of body movements can be taught in better manner by using objects. For that, you need to collect two sets of identical items of things (identical in shape, size, and color). For instance, two toy cars, two plastic spoons, two plastic cups, two red napkins. With the help of these objects you can teach body movement imitation to the children. </p>
<p> To start off with, you can do these imitations by sitting across the table with the child. To get object imitation going, you say, “Do this” and then you move an object. E.g. You roll the car and ask the child “do this” with his car. If you need to, you can help him by giving him physical prompt. If you have a prompter present to help the child, its good as he can prompt the child from behind so that the child gets the feeling that he is doing it himself. </p>
 <p> It's better if you plan some meaningful activities for imitation which will help the child in life skills as well. Following are few activities you can teach for object imitation where you will do the action first and ask the child to “Do this”. 
</p>
<ul>
  <li>	Wipe table with cloth</li>
    <li>	Comb hair</li>
  <li>	Roll car</li>
<li>	Join blocks</li>
<li>	Wear cap</li>
<li>	Wipe mouth with hanky
    </li>
</ul>
 
<p><b>Gross Motor Imitation -</b> Once the child learns object imitations, you can start teaching gross motor imitations involving body movement and actions. You can teach body parts in imitation</p>
<ul>
  <li>	Wave bye</li>
    <li>	Touch head</li>
  <li>	Raise hand</li>
<li>	Touch toes</li>
<li>	Clap hands</li>
<li>	Stomp feet
    </li>
</ul>


<p> <b> Fine Motor Imitation -</b> Fine motor imitation helps the child use his fine muscles functionally and perform various motor activity applications.</p>
<ul>
  <li>	Put coins in box</li>
    <li>	Touch table with any finger</li>
  <li>	Press dough with index finger</li>
<li>	Thumbs up</li>
<li>	Touch both index fingers together</li>
   
</ul>



<p> <b> Imitation of head and oro  -motor movements – </b>Head movements for saying yes, no and oral motor movements for saying different words are important for learning language and communication.</p>
<ul>
  <li>	Shake head Yes</li>
<li>	Bend head to the sides</li>
  <li>	Tongue out</li>
  <li>	Open mouth</li>
  <li>	Lips pout
    </li>
</ul>


<p> <b>Vocal Imitation -</b>
  Once your child can imitate body movements, try to add some sounds to the movements to teach imitation of sounds for speech.
  </p>
  <ul>
    <li>Blow your cheeks out and make a popping sound</li>
  <li>	Make circles on your tummy, rub and say “mm-mm”</li>
<li>	Wave your hand and say, “Bye-bye”</li>
<li>	Move a toy car and say “vroom-vroom”</li>
<li>	Move a toy dog along the table and say “woof-woof”.</li>
<li>	Imitation of animal sounds, alphabet sounds</li>
<li>	Imitation of child's reinforcers</li>
<li>	Imitation of familiar words like Papa, didi, and new words like office, school.</li>
<li>	Imitation of simple phrases like papa office, didi school</li>
<li>	Imitation of simple sentences like papa went to office</li> 
      
  </ul>


      </div>
    </mat-expansion-panel>

<br>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="head3">
            Attending skills  </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mycontent1">
<p>
  Attending skills are needed for learning, becoming more self-aware, and being able to participate with others in play or social situations. It is the ability to tune out background noises and distractions, being able to listen and understand the directions or what is being asked of them, being able to concentrate or focus on the activity or task for a certain amount of time. 


</p>

<b>·	Cognitive skills </b> - These skills include matching, basic cognitive concepts, number concepts, reading and writing skills.

<br>
<b>Teaching Reading & Writing</b> 
<br>
<b> Why to teach reading?</b>
<ol>
  <li><b> Primary – (For safety/survival) – </b>signage, directions, labels, survival signs</li>
	<li><b>For information & instructions –</b>	 Product information, labels, signboards, newspaper, reading instructions for cooking / for operating appliances</li>
<li> <b>For pleasure / leisure –</b> reading stories, comics, magazines</li>	
</ol>
<br>
<b>Prerequisites – </b>

<p>Classification/grouping skills (Matching & sorting) -> Awareness of similarities and differences between items -> Receptive (imitation, following instruction) -> Pre math concepts (color, shapes, big & small, less & more, same & different, in & out, back & front -> number skills</p>
<br>
OBJECT & PICTURE IDENTIFICATION is important.
<br>
<p>Ways of teaching reading</p>
<ol>
<li>Sight word / paired reading (whole word approach) – most extensively used way of teaching functional reading: - First learning to read & recognize the word as a whole & then learning to spell it. This can be done in 2 ways.</li>
<li>	Learning to identify and label the chosen target words from an array of other different words when instruction is given. Then matching learnt word with corresponding object/ picture to work on comprehension.</li>	

<b>1st method –</b>  Word cards
<br>
<b>2nd method –</b>  using matching skills
2.	Phonics based reading
</ol>
<p>
Start with simple, short commonly occurring nouns in the environment. Then teach common familiar action words, then adjectives & then the common joining words like (this, that, is, are, he, she, it etc.). Then move on to simple sentences. Always associate all of these with pictures. Keep on generalizing all these learnt skills across the environment. Then work on the picture composition. Build concepts of what, who, where, when questions. Work on picture comprehension, short passages & then short stories.</p>
<p>
When teaching letters, start with the phonograms & teach them the ones that are easiest to learn & that they can put to immediate use, like M, S, P & A. Teaching one concept at a time respects a child’s funnel & helps the learning stick. It also helps keep lessons short.</p>
<p>
Use multisensory techniques – 
Since all autistic children do not learn in the same way, it is important to teach every lesson using sight, sound & touch. Visual learners like to see what they are learning. Auditory learners prefer to hear oral instructions, & then discuss what they have learned to solidify the material. Hands-on learners absorb knowledge best when they can touch & manipulate objects.</p>



      </div>
    </mat-expansion-panel>




  
</div>