<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/know-centre">Knowledge Centre</a></li>
  <li>Employment Readiness </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Employment Readiness</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
  
<div class="mycontent">
  <!-- <div class="head2">
<h2><b>Employment readiness</b></h2>
  </div> -->
<p>As the awareness about various disabilities is growing, a greater number of employers are gearing up to hire people with disabilities, including those with autism. According to the abilities of the person, he can find employment through the following avenues:</p>
<ul>
  <li>	Open employment</li>
<li>	Sheltered employment</li>
<li>	Self-employment</li>
</ul>
  
  People with autism can be given job-specific training before placement.  Also, they can be provided with on-the-job training. But certain employment skills are required for doing any job which include
 <ul>
  <li>	communication and social skills</li>
<li>	self-management and time-management skills</li>
<li>	problem-solving or coping skills</li>
<li>	navigation skills.</li>
</ul>
</div>