import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal-rights',
  templateUrl: './legal-rights.component.html',
  styleUrls: ['./legal-rights.component.css']
})
export class LegalRightsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
