<section class="bg-half d-table w-100"></section>



<!-- <nav class="navbar navbar-default main-header">
    <div class="container header-main">
        <div class="navbar-header">
            <a routerLink="/home" class="navbar-brand">Blog Book</a>

        </div>

        <div class="collapse navbar-collapse" id="myHeader">
            <ul class="nav navbar-nav">

                <li routerLinkActive="active">
                    
                </li>
                <li routerLinkActive="active">
                </li>
                <li routerLinkActive="active">
                    <a *ngIf="allpostcount==0" routerLink="/create-post">Create post</a>
                    <a *ngIf="allpostcount>0" routerLink="/create-post">Add Post</a>
                </li>

                <li routerLinkActive="active" *ngIf="!isAuthenticated">
                    <a routerLink="/auth">Authenticate</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isemailverfied">
                    <a routerLink="/myposts">MyPosts</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && !isprofileSet ">
                    <a routerLink='/myprofile'>CreateProfile</a>
                </li>
                <li routerLinkActive="active" *ngIf="isAuthenticated && isprofileSet && isemailverfied">
                    <a [routerLink]="['/myprofile', username]">MyProfile</a>
                </li>

            </ul>
            <ul class="nav navbar-nav navbar-right">
                <li *ngIf="isAuthenticated">
                    <a style="cursor: pointer;" (click)="onLogout()">Logout</a>
                </li>

            </ul>
        </div>
    </div>
</nav> -->


<app-blog-header></app-blog-header>

<div class="row">
    <div class="col-md-3 col-sm-12">
        <div class="list-group">
            <a class="list-group-item" routerLinkActive="active" [routerLink]="['/myprofile',unameParam ]" [routerLinkActiveOptions]="{ exact: true }">Profile</a>

            <a class="list-group-item" routerLinkActive="active" [routerLink]="['/myprofile',unameParam ,'publicposts']" [routerLinkActiveOptions]="{ exact: true }">Public Posts
                <span class="count-post"> ({{pbcount}})</span></a>

            <a class="list-group-item disabled" routerLinkActive="active" [routerLink]="['']" [routerLinkActiveOptions]="{ exact: true }">Private Posts
                <span class="count-post"> ({{prcount}})</span></a>
            <a class="list-group-item disabled" routerLinkActive="active" [routerLink]="['']" [routerLinkActiveOptions]="{ exact: true }">All Posts
                <span class="count-post"> ({{allcount}})</span></a>
        </div>
    </div>

    <div class="col-md-7 col-sm-12 col-xs-12 post-cards">
        <div *ngIf="PublicPosts?.length>0 && !isFetching">
            <div *ngFor="let i of  PublicPosts;let c = index">
                <div class="card row">
                    <a [routerLink]="[c]">

                        <div class="col-md-4 col-xs-12 img-col col-md-push-8">
                            <img src="{{ i.imgurl }} " alt="Avatar " style="width: 100%;" class="img-responsive" />
                        </div>
                        <div class="col-md-8 col-xs-12 main-content col-md-pull-4">

                            <h2 class="title">
                                {{ i.title }}
                            </h2>

                            <p class="desc desc1" [innerHTML]="i.desc| noSanitize"></p>
                        </div>

                        <div class="author-date col-md-6 col-xs-12">
                            <p class="author-name">
                                By {{ i.name }}
                            </p>

                            <p class="date">
                                On {{ i.created_date | date }}
                            </p>
                        </div>

                    </a>
                </div>

            </div>



        </div>

        <div *ngIf="isFetching" class="loading-text">
            <app-loading-spinner></app-loading-spinner>

        </div>

        <div *ngIf="!isFetching && PublicPosts?.length<1" class="loading-text">
            <h3>No Post Found</h3>
        </div>
    </div>

</div>