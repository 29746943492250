import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-use-aac',
  templateUrl: './use-aac.component.html',
  styleUrls: ['./use-aac.component.css']
})
export class UseAacComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
