<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/know-centre">Knowledge Centre</a></li>
  <li>Play & Leisure Skills </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Play & Leisure Skills</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
  
<div class="mycontent">
  <p>
  Play and leisure skills are extremely important for the child’s personal well-being and his holistic development and to foster inclusion. Play boosts  imagination and intelligence, language, and motor abilities in the children. Play also stimulates interaction between a child and his parents or friends. A child develops his sense of own identity and his social awareness grows as he understands the other person's perspective. He learns various social skills like empathy, co-operation, and respect for others. </p>
  <p>
  Play skills of neurotypical child starts developing from solitary play where the child explores environment through manipulation of objects and toys. Then the child plays alongside other children in parallel play. Though at this stage, they are not playing together, it is the beginning of socialization. Gradually the children begin to play together in associative play and it develops awareness, friendships and preferences for playing with some but not all children. Children start using their imagination and develop language in pretend plays and role plays and then they play with shared aims of play with others in co-operative play where they learn skills like turn taking, waiting, sharing play materials and following rules of the play. </p>
  <p>
  Children with autism show unusual features in their play skills from an early age. This may be because many of them have a deficit in imagination, and so they are unable to think of things that they can do in their free time. They show little or no interest in toys or show focused interest in unusual objects such as strings, brooms, pebbles, wrappers, or any household objects or activities like scribbling, drawing or tearing paper.</p>
<p>
  They also have difficulties in accepting change in their routines or activities and so many of them spend their time doing the same activities, or playing with same toys or engage in simply running around or jumping about. We can observe that many children with autism are busy in lining up or stacking similar objects like pencils, blocks, toy cars, glasses, bottles, rolling cars back and forth in their play time. Some may use toys and other objects purely for sensory stimulation like banging toys to get sound and pressure, putting toys in the mouth to explore the texture, or keep looking at light that flickers for visual stimulation. 
<p>
  Though they are happily involved in their play activities in their own ways, if we want them to learn various social skills, play can be an initial point of entry in their world.
</p>
  <br>
  <mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Play skills through joint attention and connection building</div>
     </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row">
        <div class="column" >
    <p>Joint attention occurs when two people share interest in an object or event. Also, there is an understanding between them that they are both interested in the same object or event. 
      The following points should be considered:
      
</p>
<ul>
  <li>	working on eye contact,</li>
  <li> following the lead of the child and getting involved in the child's preferred activities, focusing on pointing skills, 
  <li> 	encouraging shift of attention from what he/she is playing with to what you have in mind.</li>
<li> 	creating situations for your child to initiate a request for you to look at something that interests him/her. 
    </li>
</ul>
</div>
<div class="column1" >
  <img src="assets/images/content/mydown-img/image 24.jpg"  >
  
</div>
</div>
</div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                      (closed)="panelOpenState = false">
   <mat-expansion-panel-header>
     <mat-panel-title><div class="head3">
      Leisure skills</div>
     </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row">
        <div class="column2" >
     
          <img src="assets/images/content/mydown-img/image 26.jpg">
        </div>
     
      <div class="column3" >
    <p>We need to consider the following points in developing leisure skills in a child:</p>
<ul> 
  <li>Always start with the child's interests e.g., Puzzles, Music, food, trains, cars, water</li>
  <li>	While involving the child in leisure activities, take only one or two activities for a short span of time.</li> 
<li>	Build activities around the child's interests.</li> 
<li>Use structure-physical, visual schedules, work systems, to provide clarity of expectations</li>
<li>	Use visual structure in activities to foster independence</li>
<li>	Always REINFORCE the child's efforts</li>
</ul>
</div>
      </div>
    </div>
</mat-expansion-panel>
</div>