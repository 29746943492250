<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb"> 
  <li><a href="#">Home</a></li> 
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
   <li>DSM-5</li> 
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Diagnostic and Statistical Manual, Fifth Edition (DSM-5) 
</span>
  </h2>
  </div>
  </div>
  </div>
  </div>

  <div class="mycontent">
   <p>
      The American Psychiatric Association's Diagnostic and Statistical Manual,
      Fifth Edition (DSM-5) provides standardized criteria to help diagnose ASD.
      To meet diagnostic criteria for ASD according to DSM-5, a child must have
      persistent deficits in each of three areas of social communication and
      interaction (see A.1. through A.3. below) plus at least two of four types
      of restricted, repetitive behaviors (see B.1. through B.4. below).
    </p>
    <ol class="alphanum">
      <li>
        Persistent deficits in social communication and social interaction
        across multiple contexts, as manifested by the following, currently or
        by history:
      </li>
      <br />
      <ol>
        <li>
          Deficits in social-emotional reciprocity, ranging, for example, from
          abnormal social approach and failure of normal back-and-forth
          conversation; to reduced sharing of interests, emotions, or affect; to
          failure to initiate or respond to social interactions.
        </li>
        <br />

        <li>
          Deficits in nonverbal communicative behaviors used for social
          interaction, ranging, for example, from poorly integrated verbal and
          nonverbal communication; to abnormalities in eye contact and body
          language or deficits in understanding and use of gestures; to a total
          lack of facial expressions and nonverbal communication.
        </li>
        <br />

        <li>
          Deficits in developing, maintaining, and understand relationships,
          ranging, for example, from difficulties adjusting behavior to suit
          various social contexts; to difficulties in sharing imaginative play
          or in making friends; to absence of interest in peers.
        </li>
        <br />
      </ol>

      <li>
        Restricted, repetitive patterns of behavior, interests, or activities,
        as manifested by at least two of the following, currently or by history
        (examples are illustrative, not exhaustive; see text):
      </li>
      <ol>
        <li>
          Stereotyped or repetitive motor movements, use of objects, or speech
          (e.g., simple motor stereotypes, lining up toys or flipping objects,
          echolalia, idiosyncratic phrases).
        </li>
        <br />

        <li>
          Insistence on sameness, inflexible adherence to routines, or
          ritualized patterns of verbal or nonverbal behavior (e.g., extreme
          distress at small changes, difficulties with transitions, rigid
          thinking patterns, greeting rituals, need to take same route or eat
          same food every day).
        </li>
        <br />
        <li>
          Highly restricted, fixated interests that are abnormal in intensity or
          focus (e.g., strong attachment to or preoccupation with unusual
          objects, excessively circumscribed or perseverative interests).
        </li>
        <br />
        <li>
          Hyper- or hypo reactivity to sensory input or unusual interest in
          sensory aspects of the environment (e.g., apparent indifference to
          pain/temperature, adverse response to specific sounds or textures,
          excessive smelling or touching of objects, visual fascination with
          lights or movement).
        </li>
      </ol>
      <br />
      <li>
        Symptoms must be present in the early developmental period (but may not
        become fully manifest until social demands exceed limited capacities or
        may be masked by learned strategies in later life).
      </li>
      <br />
      <li>
        Symptoms cause clinically significant impairment in social,
        occupational, or other important areas of current functioning.
      </li>
      <br />
      <li>
        These disturbances are not better explained by intellectual disability
        (intellectual developmental disorder) or global developmental delay.
        Intellectual disability and autism spectrum disorder frequently
        co-occur; to make comorbid diagnoses of autism spectrum disorder and
        intellectual disability, social communication should be less than
        expected for general developmental level.
      </li>
    </ol>

    <div class="mynote">
      <p>
        <b> Note:</b>
        Individuals with a well-established DSM-IV diagnosis of autistic
        disorder, Asperger's disorder, or pervasive developmental disorder not
        otherwise specified should be given the diagnosis of autism spectrum
        disorder. Individuals who have marked deficits in social communication,
        but whose symptoms do not otherwise meet criteria for autism spectrum
        disorder, should be evaluated for social (pragmatic) communication
        disorder. Specify if:
      </p>
    </div>
    <ul>
      <li>With or without accompanying intellectual impairment</li>
      <li>With or without accompanying language impairment</li>
      <li>
        Associated with a known medical or genetic condition or environmental
        factor
      </li>
      <li>
        Associated with another neurodevelopmental, mental, or behavioral
        disorder
      </li>
      <li>With catatonia</li>
    </ul>

    <table>
      <tr>
        <th>Severity Level for ASD</th>
        <th>Social Communication</th>
        <th>Restricted Interests & Repetitive Behaviors</th>
      </tr>
      <tr>
        <td>Level 3 - Requiring very substantial support</td>
        <td>
          Severe deficits in verbal and nonverbal social communication skills
          cause severe impairments in functioning, very limited initiation of
          social interactions and minimal response to social overtures from
          others.
        </td>
        <td>
          Preoccupations, fixated rituals and/or repetitive behaviors markedly
          interfere with functioning in all spheres. Marked distress when
          rituals or routines are interrupted; very difficult to redirect from
          fixated interest or returns to it quickly.
        </td>
      </tr>
      <tr>
        <td>Level 2 - Requiring substantial support</td>
        <td>
          Marked deficits in verbal and nonverbal social communication skills;
          social impairments apparent even with supports in place; limited
          initiation of social interactions and reduced or abnormal response to
          social overtures from others.
        </td>
        <td>
          Rituals and repetitive behaviors (RRBs) and/or preoccupations or
          fixated interests appear frequently enough to be obvious to the casual
          observer and interfere with functioning in a variety of contexts.
          Distress or frustration is apparent when RRB's are interrupted;
          difficult to redirect from fixated interest.
        </td>
      </tr>
      <tr>
        <td>Level 1 - Requiring support</td>
        <td>
          Without supports in place, deficits in social communication cause
          noticeable impairments. Has difficulty initiating social interactions
          and demonstrates clear examples of atypical or unsuccessful responses
          to social overtures of others. May appear to have decreased interest
          in social interactions.
        </td>
        <td>
          RRB's cause significant interference with functioning in one or more
          contexts. Resists attempts by others to interrupt RRB's or to be
          redirected from fixated interest.
        </td>
      </tr>
    </table>
    <div class="myref">
      References:
      <ol>
        <li>
          American Psychiatric Association. Diagnostic and statistical manual of
          mental disorders. 5th ed. Arlington, VA: American Psychiatric
          Association; 2013.
        </li>
        <li>
          <a
            href="https://www.cdc.gov/ncbddd/autism/hcp-dsm.html"
            target="_blank"
            >https://www.cdc.gov/ncbddd/autism/hcp-dsm.html
          </a>
        </li>
      </ol> 
    </div>
    
    
  </div>