<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/comm-aids">Communication Aids</a></li>
  <li>Types of AAC </li>
</ul>
<div class="container" data-uw-styling-context="true">
  <div
    class="hero hero--solid hero--secondary hero--titlefix"
    data-uw-styling-context="true"
  >
    <div class="hero_innercontainer" data-uw-styling-context="true">
      <div class="hero_content" data-uw-styling-context="true">
        <h2 class="hero_title" data-uw-styling-context="true">
          <span data-uw-styling-context="true">Types of AAC</span>
        </h2>
      </div>
    </div>
  </div>
</div>
<div class="mycontent">
  <p>
    AACs are created by using visual supports and symbols for receptive and
    expressive communication. The AAC system has been established as an
    evidence-based practice for improving communication abilities in individuals
    with ASD. AACs can be of two types i.e., low tech and high tech. Low-tech
    AAC aids are those that do not need batteries, electricity, or electronics
    to meet the user's communication needs. These are often very simple aids
    created by placing letters, words, phrases, pictures, objects, symbols on a
    board or in a book, which can be accessed easily by the user.</p>
    <br>
  <p> High-tech AAC aids are electronic devices that have the storage capacity and technology to
    retrieve messages and electronic speech output. These devices vary in size
    and weight, the amount of information they can store and the way it is stored. According
  to the skills and abilities of the communicator, the parent can choose the AAC
  for their child.</p>
  
  <p>The following are the types of all low and high tech AACs
  available for communication:</p>
  <ul>
    <li>Sign language </li>

    <li> Objects </li>
      
  <li>  Picture exchange communication system </li> 
      
<li>   Communication boards</li>  
      
<li>   Combining letters to make a word or sentence  </li> 
      
<li>  Art and craft   </li> 
      
    <li>  Electronic / speech generating device </li> 
      
    <li>  Communication Apps used on Tabs, iPad, and mobile phones. 
      
       </li>
  </ul>
  <br>

  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title><div class="head3">Sign Language</div> </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <p>
        Sign language involves the use of specific hand shapes, body movements
        and facial expression to communicate with others. Sign languages vary
        from country to country and there are also regional dialects within
        countries. For example, Indian Sign Language, Manual English, manually
        coded English.
      </p>
    </div>
  </mat-expansion-panel>
<br>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title><div class="head3">Objects</div> </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
     <p> The objects can be used to represent various daily activities, places,
      needs, emotions and by these objects people with autism can communicate
      with others. As objects are concrete and more relevant to the environment,
      their use can facilitate communication in an easier manner. E.g., a spoon
      can be used to communicate snack time, paper roll can represent toileting
      need, glass can be used to communicate demand for water.</p>
    </div>
  </mat-expansion-panel>
<br>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Picture Exchange Communication System (PECS)</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
    <p>  Picture Exchange Communication Systems (PECS) is built on the fact that non-verbal children with autism may attempt to spontaneously use objects to communicate. PECS can be used by people of all age groups. People with autism tend to be visual learners, and visual means of communication can help them to understand and use the process of communication.  PECS teaches that communication is an exchange of messages between two people. The act of exchanging objects or pictures in social settings encourages the child for social communication. PECS teaching relies on behavioural principles, particularly reinforcement techniques. Pictures of things used in an individual’s daily routine like food, drinks, toilet, clothes, places, family can be placed in picture book. The parent or therapist can teach the child how to use this picture book to communicate needs, thoughts, or emotions. The child can get immediate response by receiving desired thing which motivates him for more communication efforts. </p>
    </div>
  </mat-expansion-panel>
<br>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Communication Board</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
    <p> Communication board may contain symbols, pictures, or phrases that a child
      can pick from to communicate. Users can indicate items using their eye
      gaze, point, or a mouth/head stick. Communication boards can be used in
      home and school settings very easily as static displays. The user can be
      taught to select vocabulary to establish topics and construct messages.
      Multiple boards can be organized into communication books for easy access.</p>
      <br>
  
    <img src="assets/images/content/aut-img/comboard.png" alt="" class="mycontent-img"/>
      <br>
    </div>
  </mat-expansion-panel>
<br>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Combining letters to form words and sentences</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
    <p>  Text based communication is something where 26 letters are used in
      different combinations to form a word and sentences. The advantage of text
      based AAC is that any word or sentences could be formed through a
      combination of 26 letters. To use text based AAC, the user must be
      familiar with alphabets so that he can use them proficiently.</p>
    </div>
  </mat-expansion-panel>
<br>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title><div class="head3">Art and craft</div> </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
    <p>  Art and craft could be used as a medium of communication. One can express
      his or her feelings, desire and needs through art and craft. Also, art and
      craft stimulate the person's cognitive process and enhances his
      imagination power. Art doesn’t require words, so it helps children with
      communication and speech difficulties to speak their heart out through
      art.</p>
    </div>
  </mat-expansion-panel>
<br>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Electronic / speech generating device</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <div class="row">
        <div class="column" >
      <p>
        Electronic / speech generating devices generate printed and/or spoken
        text which helps in reaching the goal of independent and spontaneous
        communication.
      </p>
    </div>
      <div class="column1" >
      <img src="assets/images/content/aut-img/device2.png" alt="" />
      <br>
      <span class="square"> Speech generating device </span>
     <br>
    </div>
  </div>
</div>
  </mat-expansion-panel>
<br>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title
        ><div class="head3">Communication Apps</div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="mycontent1">
      <p>
        Various communication apps have been developed for using it on Tabs,
        iPads, and mobile phones as a medium of communication for people with
        autism. The app user can touch the picture symbol in the app or can type
        the message and through a voice output from the device can communicate
        with the outside world. We have designed <b>VINCA app</b> which is especially
        focused on:
      </p>
      <ul>
        <li>Communication needs of people with autism,</li>

        <li>
          Learning material such as various categories for identification,
        </li>

        <li>
          Visual support for teaching activities of daily living (ADL) like
          brushing, bathing, dressing up etc.
        </li>
      </ul>

      **Please note <b> VINCA app</b> can be customized as per the requirements of the
      user.
    </div>
  </mat-expansion-panel>
</div>
