import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { IndexComponent } from './core/components/index/index.component';
import { SwitcherComponent } from './shared/switcher/switcher.component';

// import {AuthLoginComponent} from './auth/auth-login/auth-login.component';

import { AuthLoginThreeComponent } from './auth/auth-login-three/auth-login-three.component';
import { AuthSignupThreeComponent } from './auth/auth-signup-three/auth-signup-three.component';
import { HomeComponent } from './core/components/Unauthenticated/home/home.component';
import { UPostDetailComponent } from './core/components/Unauthenticated/upost-detail/upost-detail.component';
import { AuthComponentComponent } from './Authentication/auth-component/auth-component.component';
import { ResetpasswordComponent } from './Authentication/resetpassword/resetpassword.component';
import { AuthGuard } from './Authentication/shared/auth-guard.service';
import { VerifyMailComponent } from './Authentication/verify-mail/verify-mail.component';
import { UCreatePostComponent } from './core/components/Unauthenticated/u-create-post/u-create-post.component';
import { ProfileComponent } from './core/components/user/profile/profile.component';
import { UserEditComponent } from './core/components/user/user-edit/user-edit.component';
import { UserPostComponent } from './core/components/user/user-post/user-post.component';
import { ViewOnlyPublicPostComponent } from './core/components/user/view-only-public-post/view-only-public-post.component';
import { ViewProfileComponent } from './core/components/user/view-profile/view-profile.component';
import { AutSpecDisorderComponent } from './content/aut-spec-disorder/aut-spec-disorder.component';
import { IdentAutismComponent } from './content/aut-spec-disorder/ident-autism/ident-autism.component';
import { CausePrevalComponent } from './content/aut-spec-disorder/cause-preval/cause-preval.component';
import { AssConditionComponent } from './content/aut-spec-disorder/ass-condition/ass-condition.component';
import { InterventionsComponent } from './content/aut-spec-disorder/interventions/interventions.component';
import { FaqComponent } from './content/aut-spec-disorder/faq/faq.component';
import { VisTeachingComponent } from './content/know-centre/vis-teaching/vis-teaching.component';
import { SkillDevComponent } from './content/know-centre/skill-dev/skill-dev.component';
import { PlayLeisureComponent } from './content/know-centre/play-leisure/play-leisure.component';
import { EmpReadComponent } from './content/know-centre/emp-read/emp-read.component';
import { VocTrainingComponent } from './content/know-centre/voc-training/voc-training.component';
import { BenAacComponent } from './content/comm-aids/ben-aac/ben-aac.component';
import { TypesAacComponent } from './content/comm-aids/types-aac/types-aac.component';
import { WhatAacComponent } from './content/comm-aids/what-aac/what-aac.component';
import { UseAacComponent } from './content/comm-aids/use-aac/use-aac.component';
import { DsmComponent } from './content/hyperlinks/dsm/dsm.component';
import { AppGuideComponent } from './content/vinca-app/app-guide/app-guide.component';
import { TipsAacComponent } from './content/vinca-app/tips-aac/tips-aac.component';
import { VideoTutComponent } from './content/vinca-app/video-tut/video-tut.component';
import { FaqVincaComponent } from './content/vinca-app/faq-vinca/faq-vinca.component';

import { FunctAssestComponent } from './content/hyperlinks/funct-assest/funct-assest.component';
import { NeuroTheoriesComponent } from './content/hyperlinks/neuro-theories/neuro-theories.component';
import { KnowCentreComponent } from './content/know-centre/know-centre.component';
import { CommAidsComponent } from './content/comm-aids/comm-aids.component';
import { VincaAppComponent } from './content/vinca-app/vinca-app.component';
import { SensoryDisorderComponent } from './content/hyperlinks/sensory-disorder/sensory-disorder.component';
import { BlogComponent } from './content/blog/blog.component';
import { LifeSkillsComponent } from './content/blog/life-skills/life-skills.component';
import { BehaveManagementComponent } from './content/blog/behave-management/behave-management.component';
import { FinanPlanningComponent } from './content/blog/finan-planning/finan-planning.component';
import { LegalRightsComponent } from './content/blog/legal-rights/legal-rights.component';
import { OthersComponent } from './content/blog/others/others.component';
import { TherapeuticsComponent } from './content/blog/therapeutics/therapeutics.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexComponent },
      { path: '', component: SwitcherComponent },
{ path: '', redirectTo: '/index', pathMatch: 'full' },
       
      { path: 'index', component: IndexComponent },
      {
        path: 'aut-spec-disorder',
        // component:AutSpecDisorderComponent,
        children: [
          { path: '', pathMatch: 'full', component: AutSpecDisorderComponent },
          { path: 'dsm', component: DsmComponent },
          { path: 'funct-assest', component: FunctAssestComponent },
          { path: 'neuro-theories', component: NeuroTheoriesComponent },
          { path: 'sensory-disorder',component:SensoryDisorderComponent},
        ],
      },

      { path: 'ass-condition', component: AssConditionComponent },
      { path: 'cause-preval', component: CausePrevalComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'ident-autism', component: IdentAutismComponent },
      { path: 'interventions', component: InterventionsComponent },



      {path:'know-centre',component:KnowCentreComponent },
      { path: 'vis-teaching', component: VisTeachingComponent },
      { path: 'skill-dev', component: SkillDevComponent },
      { path: 'play-leisure', component: PlayLeisureComponent },
      { path: 'voc-training', component: VocTrainingComponent },
      { path: 'emp-read', component: EmpReadComponent },

      {path:'comm-aids',component:CommAidsComponent},
      { path: 'ben-aac', component: BenAacComponent },
      { path: 'types-aac', component: TypesAacComponent },
      { path: 'what-aac', component: WhatAacComponent },
      { path: 'use-aac', component: UseAacComponent },

      {path:'vinca-app',component:VincaAppComponent},
      { path: 'app-guide', component: AppGuideComponent },
      { path: 'tips-aac', component: TipsAacComponent },
      { path: 'video-tut', component: VideoTutComponent },
      { path: 'faq-vinca', component: FaqVincaComponent },

      {path: "blog",component:BlogComponent},
      {path:'life-skills',component:LifeSkillsComponent},
      {path:'behave-management',component:BehaveManagementComponent},
      {path:'finan-planning',component:FinanPlanningComponent},
      {path:'legal-rights',component:LegalRightsComponent},
      {path:'others',component:OthersComponent},
      {path:'therapeutics',component:TherapeuticsComponent},
      
      { path: 'home', component: HomeComponent },
      { path: 'home/:id', component: UPostDetailComponent },
      { path: 'featured/:id', component: UPostDetailComponent ,pathMatch: 'full'},
      {
        path: 'myprofile',
        component: ProfileComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },

      {
        path: 'myprofile/:username',
        component: ViewProfileComponent,
        pathMatch: 'full',
      },
      {
        path: 'myprofile/:username/editProfile',
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'myprofile/:username/publicposts',
        component: ViewOnlyPublicPostComponent,
      },
      {
        path: 'myprofile/:username/publicposts/:id',
        component: UPostDetailComponent,
      },
      {
        path: 'create-post',
        component: UCreatePostComponent,
        canActivate: [AuthGuard],
      },
      { path: 'auth', component: AuthComponentComponent },
      { path: 'verify-mail', component: VerifyMailComponent },
      { path: 'reset-password', component: ResetpasswordComponent },
      { path: 'myposts', redirectTo: 'myposts/allpost', pathMatch: 'full' },
      {
        path: 'myposts/:type',
        component: UserPostComponent,
        pathMatch: 'full',
      },

      { path: 'myposts/:type/:id', component: UPostDetailComponent },
      { path: 'myposts/:type/:id/edit', component: UserEditComponent },
      { path: 'myposts/:type/:id/delete', component: UPostDetailComponent },

      { path: '**/undefined', redirectTo: '/home' },
    ],
  },
  { path: 'auth-signup-three', component: AuthSignupThreeComponent },
  { path: 'auth-login-three', component: AuthLoginThreeComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: 'enabled',
      useHash:false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
