import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identifying-autism',
  templateUrl: './identifying-autism.component.html',
  styleUrls: ['./identifying-autism.component.css']
})
export class IdentifyingAutismComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
