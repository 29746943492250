<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a href="/blog">Blog</a></li>
  <li>Life Skills</li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Life Skills</span>
  </h2>
  </div>
  </div>
  </div>
  </div>


<div class="mycontent">
<p>
  Life skills are sometimes referred to as independent living skills or daily living skills. Basic life skills include self-care activities, cooking, money management, shopping, room organization and transportation. These skills are learned over time, beginning at home at a very young age and developing further throughout adolescence and adulthood.</p>
  <p>
Learning a wide range of life skills that apply to many areas of life is critical. It is also important to include <a routerLink="">executive function skills</a>  or thinking skills such as organizing, planning, prioritizing and decision making related to each life skill being taught. Categories of life skills include: 

</p>

<ul>
  <li>Health and safety </li>
<li>	Career path and employment</li>
<li>	Self-determination/advocacy</li>
<li>	Peer relationships, socialization and social communication</li>
<li>	Community participation and personal finance</li>
<li>	Transportation</li>
<li>	Leisure/recreation</li>
<li>	Home living skills</li>
  
</ul>


<h4> How To Teach Life Skills? </h4> 
  <p>
Every person with autism is different, so the life skills that will be taught, and the pace that they are taught, will vary from person to person. For example, one young adult with autism may ultimately be able to live on his or her own with very little, if any, outside support, while another may require supports and services 24 hours a day, 7 days a week. Starting to develop life skills to the best of a child's ability at a young age will make a difference as they get older. </p>

<p>There are endless life skills to learn which will be taught and practiced at home, school, and in the community. Most people with autism benefit from clear, hands-on instruction in life skills that will help them to increase independence. </p> 

<p>Life Skills classes or independent living programs are common ways to learn these skills and are usually led by a teacher or therapist. Life skills training should occur in natural environments where the skills being taught relate directly to the type of environment the person is going to live and use them. This means learning cooking skills in a kitchen, or learning laundry skills in a laundromat. </p> 

Read more about the  <a routerLink=""> basic components of comprehensive Life Skill Programs. </a>

<h4>Teaching Life Skills At Home </h4>
<p>
You can try several strategies to teach life skills at home by following a general three-step approach:</p> 

<p>Assess the skills (i.e. figure out the person’s difficulties and strengths). Having a list of strengths and areas for improvement can help you clarify the goals you set and provide supportive feedback and encouragement along the way. </p>

<p>Teach new skills in a supportive way. The use of visual aids like charts and checklists can be a great way to provide support when working on new skills. </p>

<p>Practice these new skills. Remember to truly master a new skill you must practice in realistic settings. This may mean teaching money skills at home using real dollars and coins, but to practice you go out to a local store and make a purchase.  </p>

<h4>Assessing a Person's Current Skills </h4>

<p>When it comes to life skills, knowing a person's strengths and areas in need of support are important to continue learning. We have a tool you can use, the <a href="http://" target="_blank" > Community-Based Skills Assessment (CSA).</a> </p>

<p> This tool was developed by Virginia Commonwealth University's Rehabilitation Research and Training Center, to help parents and professionals assess the current skill levels and abilities of individuals with autism beginning at age 12 and continuing into adulthood in order to develop a comprehensive plan. </p>
  
<p> The CSA assess needs in the area of community-based living, from transportation to financial management to peer relationships and more. </p> 
  
<p> Explore the <a href="http://" target="_blank" > Community-Based Skills Assessment. </a></p>

<h4>Using Visual Supports for Life Skills </h4>
<p>Many people on the autism spectrum work best with visual cues and supports. There are several helpful options for teaching life skills. A great way to start is to create a visual aid that breaks up a complex daily routine into smaller tasks. For example, you can create a checklist for each part of the day, such as a 'Morning Checklist', 'Lunch Checklist', 'Bedtime Checklist'. This can help a person with autism get accustomed to the daily routine by checking off items on the list as the day goes on. See example below:  </p>

<img src="assets/images/content/aut-img/list.png" alt="">
<p>
  This daily checklist can help a person with autism complete all the tasks that make up a morning routine. </p>
<p>
If a person requires more supports, you can also break down each specific task into its own checklist, to make sure each task is completed correctly and efficiently. </p>

<p>Check out our <a href="">Task Analysis for Brushing Teeth</a> , an example of breaking down one of many morning tasks - brushing your teeth! 
</p>

<b>Ten Ways to Build Your Child’s Independence </b>

<ol>
  <li> <b> Strengthen Communication </b> </li>
<p>
    If your child struggles with spoken language, a critical step for increasing independence is strengthening his or her ability to communicate by building skills and providing tools to help express preferences, desires and feelings. Consider introducing Alternative/Augmentative Communication (AAC) and visual supports. Common types of AAC include picture exchange communication systems (PECS), speech output devices (such as DynaVox, iPad, etc.). </p>


<li> <b> Introduce a Visual Schedule </b> </li>
<p>
Using a visual schedule with your child can help the transition from activity to activity with less prompting. Review each item on the schedule with your child and then remind him or her to check the schedule before every transition. Over time, he or she will be able to complete this task with increasing independence, practice decision making and pursue the activities that interest him or her. </p>

<li> <b> Work on Self-Care Skills </b></li>
<p>
Introduce self-care activities into your child’s routine. Brushing teeth, combing hair and other activities of daily living (ADLs) are important life skills, and introducing them as early as possible can allow your child to master them down the line. Make sure to include these things on your child’s schedule so he or she gets used to having them as part of the daily routine. </p>

<li><b>Teach Your Child to Ask for a Break </b></li> 
<p>
Make sure your child has a way to request a break – add a “Break” button on his or her communication device, a picture in his or her PECS book, etc. Identify an area that is quiet where your child can go when feeling overwhelmed. Alternatively, consider offering headphones or other tools to help regulate sensory input. Although it may seem like a simple thing, knowing how to ask for a break can allow your child to regain control over him or herself and his or her environment. </p>

<li><b> Work on Household Chores </b></li>
<p>
Having children complete household chores can teach them responsibility, get them involved in family routines and impart useful skills to take with them as they get older. If you think your child may have trouble understanding how to complete a whole chore, you can consider using a task analysis. This is a method that involves breaking down large tasks into smaller steps. Be sure to model the steps yourself or provide prompts if your child has trouble at first! </p>

<li> <b> Practice Money Skills </b></li>
<p>
Learning how to use money is a very important skill that can help your child become independent when out and about in the community. No matter what abilities your child currently has, there are ways that he or she can begin to learn money skills. At school, consider adding money skills to your child’s IEP and when you are with your child in a store or supermarket, allow him and her to hand over the money to the cashier. Step by step, you can teach each part of this process. Your child can then begin using these skills in different settings in the community. </p>

<li> <b> Teach Community Safety Skills </b></li>
<p>
Safety is a big concern for many families, especially as children become more independent. Teach and practice travel training including pedestrian safety, identifying signs and other important safety markers; and becoming familiar with public transportation. The GET Going pocket guide has many useful tips to help individuals with autism navigate public transportation. Consider having your child carry an ID card which can be very helpful to provide his or her name, a brief explanation of his or her diagnosis, and a contact person. You can find examples of ID cards and other great safety materials. </p>

<li> <b> Build Leisure Skills </b></li>
<p>
Being able to engage in independent leisure and recreation is something that will serve your child well throughout his or her life. Many people with autism have special interests in one or two subjects; it can help to translate those interests into age appropriate recreational activities. The <a href="http://" target="_blank" > Autism Speaks Resource Guide </a>contains activities that your child can get involved with in your community; including team sports, swim lessons, martial arts, music groups and more. </p>

<li><b> Teach Self-Care during Adolescence </b></li>
<p>
Entering adolescence and beginning puberty can bring many changes for a teen with autism, so this is an important time to introduce many hygiene and self-care skills. Getting your teens into the habit of self-care will set them up for success and allow them to become much more independent as they approach adulthood. Visual aids can be useful to help your teen complete his or her personal hygiene routine each day. Consider making a checklist of activities to help your child keep track of what to do and post it in the bathroom. This can include items such as showering, washing face, putting on deodorant and brushing hair. To stay organized, you can put together a hygiene “kit” to keep everything your teen needs in one place. </p>

<li><b>Work on Vocational Skills </b></li> 
<p>
Starting at age 14, your child should have vocational skills included on his or her IEP as a part of an individualized transition plan. Make a list of his or her strengths, skills and interests and use them to guide the type of vocational activities that are included as objectives. Consider all the ways up to this point that you have been fostering your child’s independence: communication abilities, self-care, interests and activities and goals for the future. </p>
</ol>
</div>