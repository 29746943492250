<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/comm-aids">Communication Aids</a></li>
   <li>How to Use AAC </li>
</ul>
<div class="container" data-uw-styling-context="true">
  <div
    class="hero hero--solid hero--secondary hero--titlefix"
    data-uw-styling-context="true"
  >
    <div class="hero_innercontainer" data-uw-styling-context="true">
      <div class="hero_content" data-uw-styling-context="true">
        <h2 class="hero_title" data-uw-styling-context="true">
          <span data-uw-styling-context="true">How to use AAC? </span>
        </h2>
      </div>
    </div>
  </div>
</div>
<div class="mycontent">

  <div class="row">
    <div class="column" >
  <p>
    AAC can assist in a child's need for both receptive and expressive
    communications. As there are different types of AACs available, the
    following criteria can be used for selecting a suitable type of AAC for the
    user, -
  </p>

  <ul>
    <li>Age of the user</li>
    <li>Learning style</li>
    <li>Cognitive abilities</li>
    <li>Motor abilities</li>
    <li>Behaviors</li>
    <li>Family preferences</li>
  </ul>

  <p>
    After selecting the type of AAC, one needs to know how to use the AAC
    effectively so that the user gets independence in communication. In the
    process of communication, the sender sends message through some medium to
    the receiver and gets feedback for that message.
  </p>
  

  <p>
    AACs work as a medium of exchange of information between sender and
    receiver. People with autism can be taught to use objects, pictures, written
    words, or even communication apps like VINCA, to exchange all types of
    messages with the other person by following a systematic way. This
    systematic way is taught by following six phases of PECS (Picture Exchange
    Communication System). PECS works as base for using all the types of AAC and
    hence it should be understood thoroughly by the facilitator, parents,
    caregivers and all the people in the immediate surroundings of people with
    autism with whom they can get opportunities to communicate.
  </p>
</div>
<div class="column1" >
  
  <img src="assets/images/content/aut-img/comm.svg" alt="" /> 
  <br><br>

  <span class="square" >Communication Process</span>

</div>
  
</div>

<br>
<div class="head2">
  <h3>PECS Phases</h3>
    </div>
  <p>Before implementing PECS, a reinforcer sampling should be conducted to know the items such as eatables, toys, objects and activities that are of particular interest to the learner. These reinforcing items will help to increase student motivation as well as promote acquisition of effective communication skills. PECS training can take place across daily routines and activities. For teaching PECS ideally 3 people are required to be the part of teaching-learning process that includes:- </p>
    <ol>
    <li>	The learner</li> 
   <li> Communicative partner with whom the learner will learn to communicate</li> 
    <li> Facilitator or prompter who will provide prompt to the student whenever required</li>	
  </ol>
      <ol>
        <b><li>Teaching the physically assisted exchange</li></b>
        <ul>
          <li>	In this phase, the learner is taught to look at, reach for, pick up, and hand the picture/symbol of the preferred thing E.g., favorite toy, food, or activity, to his communication partner. (One picture at a time). </li>
        <li>	This is taught as a table-top activity or in an open environment where the learner and his communication partner are in proximity. </li>
      <li>	If required, a physical prompt is provided by the facilitator to direct the learner to pick up the picture/symbol and place it in the open hand of the communication partner. </li>
    <li>	It is important at this stage to use NO VERBAL PROMPTS, as this helps the student become more independent and less reliant upon others during communicative exchanges. </li>
  <li>	While giving the preferred item, the communication partner names the item. </li>
<li>	Once the exchange steps are established, the facilitator can fade physical assistance.</li>
</ul>
      <p>
        In this phase, the learner will learn that he will get what he wants, if he gives a picture of that thing to his communication partner. Communication partner can be any individual with whom the child interacts on regular basis including parents, siblings, family members, classroom or building staff, peers, and therapists.  </p>
      <p> In following picture, the learner is giving picture of car to his communication partner with the help of physical prompt by the facilitator to get the toy car. While giving the car, the communication partner will say, “Car” to correlate object with picture and labeling it at the same time.  </p>

    
        <img src="assets/images/content/aut-img/Picture1.jpg" alt="" class="responsive1" />
        <br />

        <b><li>Expanding Spontaneity</li></b>
        <p>
          Once the students have acquired the basic skills outlined in Phase 1, the next phase can be started. This phase includes following steps:-</p>
          <ul>
            <li>Opportunities are created for students to increase their ability to initiate the communicative interaction in a variety of natural environments (e.g., home, school, community). </li>
            <li>The learner is taught to gain the attention of the communication partner and being persistent in seeking the desired outcome. This increases the learner’s spontaneity and generalization of the picture exchange. </li>
            <li>The learner learns that he needs to communicate even if the communicative partner is some distance away. </li>
            <li>	Though the facilitator has faded the prompts, he remains available to assist whenever it is necessary.</li>
          </ul>
         
        
        <img src="assets/images/content/aut-img/Picture3.jpg" alt="" class="responsive1"/>

        <b><li>Picture Discrimination</li></b>
        <p>
          At this point, the learner will be given two or more pictures to
          choose from. So, he learns to discriminate between pictures to ask for
          preferred thing.
        </p>
        <img src="assets/images/content/aut-img/3.4.3.png" alt="" class="responsive1"/>

        <b><li>Sentence Structure</li></b>
        <p>
          In this phase, the user learns to spontaneously request using simple
          sentence structure. He is taught to place an “I want” symbol with one
          of the pictures already successfully used onto a sentence strip and
          then use it during the communicative exchange. There is also training
          on how to help a child use adjectives and other words to expand his
          sentences.
        </p>
        <img src="assets/images/content/aut-img/3.4.4.png" alt="" class="responsive1"/>

        <b><li>Answering Questions</li></b>
        <p>
          This phase teaches the child how to answer the question “what do you
          want” using the pictures.
        </p>
        <img src="assets/images/content/aut-img/3.4.5.png" alt=""class="responsive1" />

        <b><li>Commenting in Response to a Question</li></b>
        <p>
          In this phase, the focus is on the communicative function of
          requesting i.e., asking for and getting what you want. This initially
          involves listening and responding to another person's request for
          information such as What do you see? What do you hear? etc. And the
          answer is I see....I hear...
        </p>
        <img src="assets/images/content/aut-img/3.4.6.png" alt="" class="responsive1" />
        Reference: Pictures for PECS phases are sourced from
        <a href="https://pecsusa.com/pecs/" target="_blank"
          >https://pecsusa.com/pecs/</a
        >
      </ol>
    </div>
 

