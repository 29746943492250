import { Component, OnInit } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
@Component({
  selector: 'app-ass-condition',
  templateUrl: './ass-condition.component.html',
  styleUrls: ['./ass-condition.component.css']
})
export class AssConditionComponent implements OnInit {
  panelOpenState = false;
  constructor() {

   }

  ngOnInit(): void {
  }
  
}
