<section class="bg-half  d-table w-100" id="home"></section>

<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/comm-aids">Communication Aids</a></li>
  <li>What Is AAC </li>
</ul>
<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
    <div class="hero_innercontainer" data-uw-styling-context="true">
      <div class="hero_content" data-uw-styling-context="true">
        <h2 class="hero_title" data-uw-styling-context="true">
          <span data-uw-styling-context="true">What are AACs?</span>
        </h2>
      </div>
    </div>
  </div>
</div>
<div class="mycontent">
  <p>Augmentative and Alternative Communication (AAC) are devices, systems, strategies and tools of communication that
    can add on or support natural speech and used for social communication by the people who have difficulties in
    communication. AAC tools are augmentative that means they enhance or supplements existing speech of the person to
    make his message clearer to his listener. AACs also can be used as alternative means of communication for those with
    limited or no speech.
    <br>
    AAC devices can range from very simple (choice board with pictures, or picture book) to complex and technological
    (mobile apps or computer).
    <br>
    AACs include four primary components that are symbols, aids, strategies, and techniques. AACs are of great help to a
    person in his communication, if he cannot rely on speech due to any reason. AACs are customized according to the
    needs of the users and users can evolve from one type of AAC to another as per their communication needs. People
    with different communication difficulties, speech impediments and disorders can benefit from AAC.
  </p>

  <h4>Myths and Facts about AAC</h4>
  
    <b> Myth 1.</b><p> AAC is replacement to speech as it generates sounds.</p>
    <b> Fact</b>
    <p> AACs are of two types 1) low-tech AAC which do not need batteries, electricity, or electronics to meet the
      user's communication needs such as pictures, symbols, communication charts and the Picture Exchange Communication
      Systems (PECS); and high-tech 2) High-tech AACs which are electronic devices which have a voice-output system that
      speaks/produces text. </p>
    <p> AACs do not replace speech but it enhances the communication skills of the person having difficulties in
      communication due to limited or no speech. Speech is only the medium of communication and the sole dependency on
      speech can become hurdle in communication for person having speech difficulties.</p>

    <b> Myth 2</b><p> Only non-verbal person needs to use AACs.</p>
    <b> Fact </b>
    <p>Along with non-verbal people, AAC can also support those with limited speech – just as how even people who can
      speak very well may prefer to send text messages over making phone calls. It can be viewed as an additional tool
      to communicate with other people. It also helps in reducing the pressure around communication abilities of person
      having limited or no speech.</p>
    <b> Myth 3 </b> <p>AAC will prevent a nonverbal child from taking efforts for speaking or he will only depend on AAC for
    communication.</p>
    <b> Fact </b>
    <p>Research demonstrates that using AAC does not prevent a child from speaking. In fact, it often encourages them to
      begin speaking. It is recommended that AAC be implemented together with speech therapy. AAC helps to build
      successful communication as they can express themselves to other person and that also reduces their frustration.
    </p>
    <b> Myth 4 </b> <p>High-tech AAC is better than low-tech AAC</p>
    <b> Fact </b>
    <p>Type of AAC can be chosen depending upon the person’s abilities and preferences. A ‘Total Communication’ approach
      is strongly encouraged for children with autism having difficulties in speech. For example, a child can greet a
      teacher by waving his hand, point to toilet card for going to toilet, and ask for a toy using PECS.</p>
  <b> Myth 5</b><p> A young child is not ready for AAC.</p>
  <b> Fact</b>
  <p>Before learning to speak, even the neurotypical babies try to communicate through signs and gestures, as it is part
    of natural process of language development. Hence, for children with physical disabilities, cerebral palsy or those
    having difficulties in speech, it is best to introduce AAC as early as possible. To start off with, low-tech AACs
    such as objects or pictures can be used and then gradually can introduce communication devices depending on their
    abilities and needs. </p>



</div>