<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a href="/blog">Blog</a></li>
  <li>Financial Planning</li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Financial Planning</span>
  </h2>
  </div>
  </div>
  </div>
  </div>


<div class="mycontent">

<p>
  Financial planning has always been a major aspect of concern for the earning member of the family. And with a child having special needs, this becomes more of a long-term ongoing process. We are always working for the independence of our children and making lot of efforts on their skill developments. We are always hopeful that one day they will be able to live their life independently and respectfully. But till then and after that also, we need to provide for their financial requirements. Many a times along with planning for our own after retirement financial needs, we must plan for the financial needs of our special needs child for his whole life. 
</p>

<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Importance of budgeting 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">

    <p>
      The parents must start budgeting their income and expenses regularly for a period of minimum 6 months to understand inflows and outflows of money. We will first see the importance of budgeting and having a separate budget for special needs dependent. 
    </p>
<ol>
  <li>  <b> Identifying sources of income</b> </li>
  <p>Budgeting helps us to identify our present and future sources of income. This includes regular income that we receive from our salary or business, any future inheritance of property or money, retirement benefits from our investment such as insurance, annuity, government schemes and any other support system from extended family. </p>

  <li><b>To determine financial requirements… current and future </b></li>
  <p>Budgeting helps in identifying current as well as future financial requirements of our family. It also helps in controlling irrational expenses. Now a days many of us resort to the use of credit cards for various expenses which would have been out of budget otherwise and this habit can keep us in debt as we are spending more than what we should spend. </p>

  <li><b> management of money</b> </li>
  <p>Balancing limited resources of income and ever-increasing expenses can be done through efficient management of money. After analyzing income and expenses, we can work on curtailing unnecessary expenses and increasing savings. Budgeting helps in directing the income sources to the best of investment avenues. </p>

  <li> <b>Provision for any emergency</b> </li>
  <p>The pandemic situation in 2020 has taught us that emergency can arise anytime, and we should be prepared for any contingency financially with better financial management. It also gives us a sense of financial security that we have planned for our family. </p>

  <li><b>Planning for savings </b></li>
  <p>If we know how much we can save every month, then we can determine in which investment avenues we can invest to achieve our short-, medium-, and long-term financial goals.  </p>
</ol>
</div>
</mat-expansion-panel>

<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        How to start budgeting 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">

    <ul>
      <li>
      Track your income and expenses month on month or quarterly basis if you have fixed monthly income. </li>

    <li>Professionals or people with business can average out their income on a quarterly or 6-month basis. </li>

  <li>Determination of expenses such as wants, needs which are variable. These are our lifestyle expenses. </li> 

<li>Fixed Expenses such as EMI, tax payment, insurance, educational expenses etc.</li>  

<li>Expenses that occur once in few months can be averaged out.</li>  

<li>After tracking the expenses for a few months, you can identify expenses on variable things. As we cannot change fixed expenses, there is room to work on variable expenses. </li>

<li>Calculate the savings - It is advisable that we should decide how much we need to save every month and set aside that amount at the beginning of the month and then manage expenses in the leftover amount.</li> 

<li>Review and monitor the budgeting time to time. </li>

</ul>

<h4><b>Separate budget for dependent with special needs </b></h4>
<p>
It is better to have a separate budget for special need dependent as it is difficult to identify their expenses separately on household things such as food, clothing, medical expenses. While planning for the future, this separate budgeting gives us an idea about their future financial requirements.</p>  

<p>Review of budget, to accommodate changes in financial requirements as per their life stages such as minor, adult, adults living at home, adult living in residential care, is necessary.  </p>

<p>The expenses can include expenses on personal needs, day to day care, education, and employment costs, particular type of furniture, recreational expenses on hoteling, vacation, special equipment, health expenses such as doctor visit, therapies, medicines, travelling expenses, insurance premiums, residential care in future, caretaker charges, guardian, or trust expenses etc.</p>
   </div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Effective financial planning 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">
    <p>
      When you know how much money you can save, then you can choose from the best investment options available. It is important to know the purpose of investment before selecting any investment avenue. </p> 

      <p>In traditional financial planning, one plans for short term goals of having luxurious items like car, house modifications, foreign trips, medium term goals such as buying house or property and long-term goals such as children education, their marriage and own retirement.</p> 

      <p>But with special need dependent, the financial requirements are recurring and lifelong. The parents must plan for their future financial needs even when they will not be there.  This planning includes financial requirements of their other children, needs after retirement and for the special needs children's whole life. That is why financial planning for children with special needs is called planning for two generations.  
    </p>

    Financial planning for special needs children includes: 
    <ol>
      <li>Immediate goals such as their day-to-day expenses, therapies, education, medications, any equipment, or furniture required for catering their disability.</li> 

    <li>   Medium term goals such as expenses on vocational training, investment for self-employment. </li> 
        
  <li>   Long term goals such as expenses on opting for residential care, for hiring caretaker, for guardian and trust related expenses, lifelong care etc. </li> 
        
<li>   It also includes traditional goals like expenses on vacations, luxurious items and entertainment. </li> 
        
<li>   Also, the inflation factor must be considered while planning as it decreases value of money in long time.</li>   
        
<li>   Tax on investment - There are investments which are taxable on maturity or with taxable interest. In that case, we are again losing our hard earn money to taxes.</li>  
        
<li>     Liquidity - Understanding complexities of investments such as real estate, shares, mutual fund, bank deposits, provident funds, gold etc. is a challenge for even a layman like us. It involves a lot of paperwork at the time of investment and withdrawal. As taking financial decisions is very difficult for special needs dependents, the liquidity aspect or the availability of funds in need is important. </li>
    </ol>
    </div>
    </mat-expansion-panel>

    <br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Investment strategy 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">
<ul>
    <li>Start early. </li>

  <li>  Diversify the investment portfolio.</li> 
      
<li>  Asset allocation should be done as per the financial goals.</li> 
      
<li>  Stay invested in sound investment products. </li>
      
<li>    Investment in Mutual Fund, SIPs for long time. </li>
      
<li>    Risk and return must be considered while making investments.</li>  
      
  <li>    Liquidity of funds is utmost important and while making investments in non-movable assets such as real estate, the selling ability of that property should be considered. </li>
      
<li>  Adequate insurance policy is the need of the day. Earning members must invest in term insurance along with medical insurance. (With term insurance, you can get a large amount of life cover (i.e. sum assured) at a relatively low premium rate.) </li>
      
<li>  Discuss and share details of the investments made with the immediate family members like spouse and children. </li>
      
<li>    Make a note in writing or digital documents, of all the details about investments such as investment maturity date, amount, and withdrawal procedure. </li>
      
<li>    Now a days we use online banking a lot. So, it is important to keep the passwords and login Id handy for the spouse. </li>
      
<li>    Nomination in all the investments should be done.</li> 
      
<li>    Timely updating of change in address, phone number, PAN card and Aadhar card number or any change in name should be done in all documents.</li> 
      
<li>    Also plan about legal will and formation of trust for the future along with provision of Guardian. </li>
</ul>
   </div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Financial provisions for special needs dependent (Indian Context) 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">
<p>
  Though there are various financial products available in the market, choosing the right products is a tedious job. So before investing, you must determine your financial goal and accordingly study various products with minimum cost and maximum and safe returns. Different options for investment are: 
</p>
<ul>
  <li>Insurance </li>

 <li>  Investments with safe and fixed returns such as Public provident fund, National pension scheme, fixed deposits in bank, small saving schemes in post </li> 
    
<li>   Investments with variable and high returns but with high risk such as mutual funds, stocks, bonds, gold and property. </li>
</ul>

<p>
  Following are the income tax provisions in India for Persons with Disabilities: 
</p>

<ol>
  <li><b>Deductions under Section 80 DD </b></li>

<p>
  Section 80 DD allows individuals to claim deductions for the expenses incurred 	on the medical treatment, training, or rehabilitation of a disabled dependent. The 	individual who incurs costs on medical expenses of a dependent who has a 	minimum of 40% of a certain disability, can claim a maximum deduction of 	Rs.75,000 and for dependents who have severe disability (a minimum of 80% of 	any disability) a deduction up to Rs.1.25 lakh. 
</p>

<li><b> Deductions under Section 80U	-	</b></li>
<p>
Section 80U provides tax deductions for Indian residents who have at least 40% 	disability. In case of severe disability, the deductions are available for Rs.1.25 	lakhs and for people with disabilities deductions are available for Rs.75,000. </p>

<li><b>Clubbing of Income –  </b></li> 
<p>
If you invest in the name of your spouse or minor child, the income from the investment will be clubbed with your income under Sec 64 and taxed accordingly. However, if the child is disabled, the income from investments made in his name will not be clubbed with the income of parents. Parents can use this provision to invest in taxable instruments like fixed deposits and debt funds.  
</p>
</ol>
   </div>
</mat-expansion-panel>

<br>


<mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      
      <mat-panel-title><div class="head3">
        Insurance for People with Autism 
      </div> </mat-panel-title>
    
    </mat-expansion-panel-header>
   <div class="mycontent1">

    <b>Niramaya Scheme </b>

    <p>
      The government of India has floated the Niramaya scheme to provide affordable health insurance to persons with Autism, Cerebral Palsy, Mental Retardation and Multiple Disabilities under National Trust Act 1999. </p> 
      <p>
This scheme offers insurance cover of up to ₹1 Lakh. It covers OPD treatment including the medicines, pathology, diagnostic tests, etc. It also covers regular medical checkup for non-ailing disabled, Dental Preventive Dentistry, Surgery to prevent further aggravation of disability, Non- Surgical/ Hospitalization, Corrective Surgeries for existing Disability including congenital disability, Ongoing Therapies to reduce impact of disability and disability related complications and expenses for alternative medicine. Treatment can be taken from any hospital. </p>
<p>
The annual premium for families below poverty line (BPL) is ₹250 and for non-BPL is ₹500 and it’s free of cost for person with disability with Legal Guardian (Other than natural parents). </p>

<p> More details about the scheme are available on the following link: </p>

<a href="http://thenationaltrust.gov.in/content/scheme/niramaya.php" target="_blank" >NIRAMAYA | Ministry of Social Justice and Empowerment (MSJE) (thenationaltrust.gov.in) 
</a>

<p>Star Special Care from Star Health Insurance </p>

<p>
It is a policy specifically designed for children diagnosed with Autism Spectrum Disorder. Entry age is between 3 years and 25 years. It includes a regular indemnity health cover, and all daycare procedures are covered. It also covers behavioural therapy, physiotherapy, occupational therapy and speech therapy under OPD. 

 
    </p>


   </div>
</mat-expansion-panel>