import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-guide',
  templateUrl: './app-guide.component.html',
  styleUrls: ['./app-guide.component.css']
})
export class AppGuideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
