import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ident-autism',
  templateUrl: './ident-autism.component.html',
  styleUrls: ['./ident-autism.component.css']
})
export class IdentAutismComponent implements OnInit {
  panelOpenState = false;
  constructor() { }

  ngOnInit(): void {
  }

}
