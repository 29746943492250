<section class="bg-half  d-table w-100" id="home"></section>

<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li>Communication Aids</li>
  
  
</ul>
<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Communication Aids</span>
  </h2>
  </div>
  </div>
  </div>
  </div>
<div class="mycontent">
  <div class="row">
    <div class="column" >
  <p>Communication is the process of sending and receiving various types of information between minimum of two people through verbal or nonverbal means, such as by speaking, using gestures, writing, or through some other medium. We like to communicate our needs, desires, emotions, information, thoughts, ideas etc. with other people. Communication has an important role in all walks of our life and has a significant place in the teaching-learning processes.
    <br><br>
  Most commonly, we think of communication occurring through speech. But those having speech or language disorders or disruptions in communication development, can have difficulties in communication along with impact on acquiring new knowledge and participating fully in society. 
  <br><br>
  Communication difficulties are quite common with autism. Many of the persons on spectrum may not speak and those who have speech, they may not be able to use speech for effective communication. Studies have shown that people with autism find it difficult to replace speech with non-verbal communication like gestures or facial expressions. They may have difficulties in writing or typing their messages due to limited motor abilities or intellectual abilities. It can be confusing and frustrating when messages cannot be given effectively. And hence, for parents and caregivers, communication is often a guess work as to what their child with autism wants to eat, where it hurts him or where he wants to go and if the guess goes wrong then there can be meltdowns. Hence, there is immense need for other options for communication such as Augmentative and Alternative Communication Systems (AAC). 
  </p>

</div>
<div class="column1" >
  
   <img src="assets/images/content/mydown-img/mother-son2.jpg"  >
</div>
</div>
</div>

