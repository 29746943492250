<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a routerLink="/aut-spec-disorder">Autism Spectrum Disorder</a></li>
  <li>Sensory Processing Disorder </li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Sensory Processing Disorder</span>
  </h2>
  </div>
  </div>
  </div>
  </div>


<div class="mycontent">
  
<p>
  Sensory processing or sensory integration refers to the ability of the nervous system of taking in all the information from our surroundings through our external senses of touch, sight, sound, taste and smell and internal sense of vestibular (movement and balance) and proprioception (body positioning and muscle control) and organizing that information so that we can infer meaning from it and act accordingly. The development of sensory systems begins in the womb and continues throughout our lives. The first few years of childhood are considered the sensory-motor years as the nervous system is in hyper-development and sensory integration gets stronger through childhood activities. </p> 

<p> Sensory integration is the basis for learning. Learning happens when we take input from new information, compare it with previous experiences and after assessment decide how to proceed with the new information. E.g., when you hear a cat mewing, your ears take in the information and your brain attaches meaning to it, such as identifying it as an animal, not a dog but a cat, determining how close it is, and deciding whether it sounds like a big cat or a small cat. Then the brain matches that information with past experiences that have been stored as memory. If you have ever played with a cat, you may want to play with it again but if you were harmed by a cat, you may run to get away when you hear the mewing. </p> 

<p> Sensory Processing Disorder is the difficulty that some people’s nervous systems have in making use of and integrating sensory information. The brain is not receiving messages, or the messages that are received are inconsistent, or the sensory information is consistent but does not integrate properly with other sensory information from the other related sensory systems. Everyone has a unique sensory processing profile in terms of how one regulates sensory experiences. But some people have sensory processing differences that are extreme enough to interfere with daily functioning. These differences can cause problems in their learning, development, and behavior. </p>

  <img src="assets/images/content/aut-img/sensoryimg.png" alt="" class="mycontent-img" >
  <br>
  <ol>
    <li><b>Sensory Over responsivity – </b>It refers to a condition of the nervous system which is over responsive to any stimuli. Person who is sensory over responsive to various senses can react differently such as  </li>
    <ul>
      <li>Touch – He may try to avoid touching or being touched by objects and people. He may react instantly to getting dirty, to certain textures of clothing and food, and even to light, unexpected touch.</li>

    <li> Sight – He may cover eyes, may have poor eye contact, may overreact to bright light, or may get overwhelmed due to surrounding crowded with people, toys, things or even lot of words on paper. </li>
        
  <li> Sound – He covers ears to close out sounds or voices, may complain about noises, such as vacuum cleaners, honking, pressure cooker whistle or even sound from fan.</li> 
        
<li> Smell – He can get uncomfortable due to odors from food, shoes, garbage or even ripe banana. </li>
        
<li>Taste – He avoids eating certain textures and temperature of foods or may frequently gag while eating. </li>
        
<li>Movement and balance – He may avoid moving or being unexpectedly moved and is insecure and anxious about falling or being off balance. He tries to keep feet on the ground. He may have motion/travel sickness. </li>
        
<li> Body Position and Muscle Control – He may be rigid and uncoordinated in body posture. He may avoid playground activities that bring strong sensory input to muscles.</li> 
    </ul>
        
     <li> <b>  Sensory Under responsivity – </b>It refers to a condition of the nervous system which is less than typical sensory responsive to stimuli. Person who is sensory under responsive to various senses can react differently such as </li> 
     <ul>
        
    <li> Touch – He may be unaware of messy face, hands, or clothes, and may not know whether he has been touched. He may not notice how things feel and often drops items or lacks interest in handling toys. </li>
        
  <li>  Sight – He may respond slowly to approaching objects or may not turn away from bright light or stares at and looks right through faces and objects.</li> 
  <li>Sound – He may ignore ordinary sounds and voices but may ‘turn on’ to exaggerated musical beats or extremely loud, close, or sudden sounds. </li>

<li> Smell – He may be unaware of unpleasant odors and unable to smell food.</li> 
    
<li> Taste – He may be able to eat very spicy food without reaction </li>
    
<li>  Movement and balance – He may not notice being moved to different place or is unaware of falling and can’t protect self. He may use swings for a long time without getting dizzy.</li> 
    
<li> Body Position and Muscle Control – He may not want to move for play and becomes more alert after actively pushing, pulling, lifting, and carrying heavy loads. </li>
</ul>

<li><b> Sensory Discrimination Disorder - </b>

  Sensory Discrimination Disorder is the inability to distinguish and   	categorize various attributes about the physical environment. The child may not be able to differentiate between hot and cold things or fully filled or empty things.</li> 
  
<li><b>	Postural Disorder - </b>
  
  Postural disorder is characterized by children who have difficulties maintaining appropriate body postures for any given motor task, moving, and sedentary activities. The child exhibits low muscle tone and will get tired easily. A child with postural disorder may be able to plan how to do an activity but will not be able to maintain postural control to carry out the activity.</li> 
  
<li><b>	Dyspraxia - </b></li>
  
  Children with dyspraxia may have the following characteristics:
  <ul>
  
  <li> Difficulties with fine motor skills for arts and crafts, scissor activities, and handwriting,</li> 
  
<li> Difficulties with gross motor skills, such as throwing, kicking, or catching a ball, or jumping with both feet off the ground </li>
  
<li>Difficulties with activities that require bilateral coordination (the use of both hands together for one activity)</li> 
  
<li> Always reluctant or refuses to try a new activity </li>
  
<li> Insists on being last for familiar or new motor activities </li>
  
<li> Acts like the class clown who is always falling or tripping </li>
  
<li> Has difficulty imitating movements for games such as “Simon says” </li>
  
<li>  Does not follow verbal directions for motor movements well </li>
  
<li> Often lacks ideas for play </li>
</ul> 
  </ol>




</div>


