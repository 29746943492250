<section class="bg-half  d-table w-100" id="home"></section>
<ul class="breadcrumb">
  <li><a href="#">Home</a></li>
  <li><a href="/blog">Blog</a></li>
  <li>Therapeutics</li>
</ul>

<div class="container" data-uw-styling-context="true">
  <div class="hero hero--solid hero--secondary hero--titlefix" data-uw-styling-context="true">
  <div class="hero_innercontainer" data-uw-styling-context="true">
  <div class="hero_content" data-uw-styling-context="true">
  <h2 class="hero_title" data-uw-styling-context="true">
    <span data-uw-styling-context="true">Therapeutics</span>
  </h2>
  </div>
  </div>
  </div>
  </div>


<div class="mycontent">
  <p>
    Autism is a “spectrum” disorder because of the wide variation in the type and severity of symptoms people with autism experience. Each person with autism has different strengths, challenges and therefore the ways of their learning, thinking and problem-solving can range from highly skilled to severely challenged. Also it is observed that different skills develop at different pace in people with autism. Hence, functional assessment must be done for knowing current skills level of the person based on which customized Individualized Educational Program and interventions can be planned and implemented. Interventions should focus on the core areas of deficits in people with autism such as socialization, adaptive skills, language and communication, academic learning, and challenging behaviors, to maximize functional independence, improve quality of life, and reduce family stress. 
  </p>
  <p>
    It is observed that early and individualized interventions can show significant development even in the core areas of impairment. The earlier the intervention is started, the outcomes are more promising.</p>

<p>Though learning style of each individual with autism can be different, following are few common features which can help in planning interventions for them.
  </p>

<ul>
  <li> People with autism are visual learners.  </li>

 <li>They learn well in a structured environment.  </li>

<li>They find it difficult to generalize concepts learned in tabletop activities in their daily life. </li>

<li>
Too many new concepts introduced at the same time can be confusing for them and hence, proper co-ordination between various therapists who plan the goals for person with autism is must. The goals planned by them must be balanced and brought in harmony with the individual needs of each child. </li>
</ul>
<p>
Due to combined efforts of professionals and parents, various effective interventions have been invented to maximize cognitive abilities, productivity, and happiness of people with autism. According to areas of skills development, several types of interventions are available for people with autism such as:  </p>

<ol>
  <li>Educational intervention </li>

  <li>Behavioral intervention </li>  
    
  <li>  Communication intervention </li> 
    
  <li> Sensory integration therapy </li> 
    
  <li> Occupational therapy </li> 
    
  <li>  Physical therapy </li> 
    
  <li>Speech and language therapy </li>  
    
  <li> Medical intervention</li>
</ol>


<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Educational intervention
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1">


<p>
  The most effective intervention for all children with autism is an
  appropriate educational training program that is customized according to
  the child's specific needs. These interventions focus on socialization,
  adaptive skills, language and communication, academic learning, and
  reduction of challenging behaviors, to maximize functional independence,
  improve quality of life and reduce family stress.
  <br/>
  Educational
  interventions are given in a classroom setting after understanding the
  learning styles of autistic children. While determining the educational
  program, the following things should be remembered:
</p>
<ul>
  <li>
    Teachers and parents need to be very clear about their goals through an
    Individualized educational plan (IEP).
  </li>
  <li>
    Achievements will be more if expectations are practical and realistic.
  </li>
  <li>
    Be completely prepared with teaching material before you start teaching
    the child.
  </li>
  <li>Use nonsocial/concrete reinforcers if required.</li>
  <li>
    Make yourself reinforcing so that the learner wants to learn more from
    you.
  </li>
  <li>Use the child's interest to start teaching any concept.</li>
  <li>
    Determine length of session as per the sitting tolerance of the child.
  </li>
  <li>Analyze the task and plan accordingly.</li>
  <li>
    Work on well acquired skills of the learner, then introduce new skills
    and concepts gradually.
  </li>
  <li>Introduce the work with a lot of excitement.</li>
  <li> Use Strategies to cope within classroom</li>
  <li>
    Use of visual schedules for better predictability about the whole day.
  </li>
  <li>Clear and appropriate instructions</li>
  <li>Sensory accommodation for better sitting tolerance in classroom.</li>
  <li>Use of social stories</li>
  <li>Use of token systems</li>
  <li>Use of turn taking charts, work systems for better workability</li>
</ul>

<h5>
  
  <b> TEACCH (Treatment and Education of Autistic and related Communication
    handicapped Children)</b>
  
</h5>
<p>
  The TEACCH approach includes a focus on the person with autism,
  understanding autism and adopting appropriate adaptations. An educator
  following TEACCH methodology, develops a program building on existing
  skills, emerging capabilities, and the individual person’s unique needs.
  Structured teaching is the base of TEACCH which addresses behavioral
  antecedents by organizing the physical environment, developing schedules
  and work systems, making expectations clear, explicit, and visual. It
  helps in effective ways of developing skills and allowing people with
  autism to use these skills independently.
</p>
</div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Behavioral intervention
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1">
<p>
  Autistic children and teenagers might have challenging behaviors due to
  communication difficulties and lack of social motivation which can affect
  their learning abilities. Behavior interventions help in improving
  learning by reducing challenging behavior. Behavioral approaches focus on
  changing behaviors by understanding what happens before (antecedent) and
  after the behavior (consequence).
</p>
<ul>
  <h5><li>Applied Behavior Analysis (ABA)</li></h5>
  <p>
    ABA therapy is based on application of the principles of learning theory to modify behaviors. After doing functional analysis of individual’s behavior, ABA therapy seeks to develop socially acceptable alternatives for aberrant behaviors. It emphasizes on one-to-one sessions in discrete trial training (DTT) to develop cognitive, social, behavioral, fine motor, play, social and self-help skills. 
    The ABA methods are used to support people with autism:</p>
    <ul>
      <li>To increase helpful behaviors,</li>
      <li>To teach new skills,</li>
      <li>To increase helpful behaviors,</li>
      <li>To generalize those behaviors in daily life situations. </li>
    </ul>
   <p>
    The technique involves task analysis in which the whole task is presented by breaking it down into small sub-skills, and then teaching each sub-skill intensely, one at a time. These skills are taught through the use of chaining (forward chaining, backward chaining) and structured presentation of tasks to the child. It requires repeated practices with provision of prompts wherever required and fading of prompts gradually to ensure independence. In ABA, rewards or reinforcement s are used for shaping and maintaining desired behaviours and skills.</p>
    
  <h5><li>Verbal Behavior Analysis (VBA)</li></h5>
  <p>
    VBA therapy is based on the principles of ABA and the theories of behaviorist B.F.Skinner. It focuses on development of communication and language in people with autism so that they can learn why to use words and how words are useful in making requests and communicating ideas. Verbal behaviour therapy focuses on four word types:
  </p>
  <ol>
    <li> <b> Mand -</b> A request, such as saying “chocolate,” to ask for a chocolate.</li>
  <li>	<b>Tact – </b>This is a comment used to share an experience or draw attention, such as “car” to point out a car on the road.</li>
<li>	<b>Intraverbal – </b>This is a word used to respond or answer a question, such as “How are you?” which can be answered as “Fine” or “Good”.</li>
<li>	<b>Echoic -</b> A repeated, or echoed, word, such as “water? Water!” This is important as echoing will help the student learn
      </li>
  </ol>
  <h5><li>Pivotal Response Training (PRT)</li></h5>
  <p>
    PRT is a naturalistic intervention model derived from the principles of
    ABA to focus on 'pivotal behaviors,'such as motivation and
    self-management. It is play-based and initiated by the child. The goals
    of this approach include development of communication and language
    skills, increasing positive social behaviors and relief from disruptive
    self-stimulatory behaviors.
  </p>
</ul>
</div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Communication intervention
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1">

<p>
  Communication therapy includes use of various communication systems to allow the
  person to express needs, feelings, and thoughts and share information in
  different settings. It is training or learning for both the communicator
  and the partner. It aims to improve verbal, nonverbal and listening
  aspects of communication. It also helps in using alternate means of
  communication like PECS, apps etc.
</p>
</div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Sensory Integration Therapy (SI)
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1">
<div class="row">
  <div class="column" >

<p>
  (SI) therapy aims to help kids with sensory processing issues. Therapy
  sessions are play-oriented and may include using equipment such as swings,
  trampolines, and slides and uses therapies such as deep pressure,
  brushing, weighted vests etc. An individualized program for the child is
  made to match sensory stimulation with physical movement to improve how
  the brain processes and organizes sensory information
</p>
  </div>

<div class="column1" >
<img src="assets/images/content/mydown-img/image 8.jpg"  >

</div>
</div>
</div>
</mat-expansion-panel>

<br>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Occupational Therapy (OT)
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1">
<div class="row">
  <div class="column2" >
    <img src="assets/images/content/mydown-img/image 9.jpg"  >

</div>

<div class="column3" >
<p>
OT brings together cognitive, physical, and motor skills with the aim of
enabling the individual to gain independence and participate more fully in
life. For a student with autism, the focus may be on appropriate play,
fine motor, basic social and life skills such as handwriting, independent
dressing, feeding, grooming and use of the toilet. The OT can recommend
strategies and tactics for learning key tasks to practice in various
settings.
</p>
</div>
</div>
</div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Physical Therapy (PT)
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1"> 
<div class="row">
  <div class="column" >
 
<p>
  PT intervention focuses on problems with movement that cause functional
  limitations. It addresses a person's challenges with motor skills such as
  sitting, walking, running, and jumping, and works on poor muscle tone,
  balance, and coordination. After assessment of a person's abilities and
  developmental level, activities or support are designed to target areas of
  need.
</p>
</div>

<div class="column1" >
<img src="assets/images/content/mydown-img/image 10.jpg"  >

</div>
</div>
</div>
</mat-expansion-panel>

<br>

<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Speech-Language Therapy (SLT)
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1">    

<p>
  SLT helps in assessment and treatment of communication problems and speech
  disorders. It is used to improve communication by working on articulation
  disorder, fluency disorder, receptive and expressive language disorder,
  cognitive-communication disorders. SLT includes training in other forms of
  communication (AACs), oral exercises to promote better Oro-motor skills,teaching
  communication, and language skills. SLT therapy may be conducted
  one-on-one, in a small group or in classroom or natural settings.
</p>
</div>
</mat-expansion-panel>
<br>
<mat-expansion-panel (opened)="panelOpenState = true"
(closed)="panelOpenState = false" >
<mat-expansion-panel-header>
<mat-panel-title><div class="head3">
Medical intervention
</div>
</mat-panel-title>
</mat-expansion-panel-header>
<div class="mycontent1">    
<p>
  To this date, there have been no medications that treat the core symptoms
  of autism. But some medications treat co-occurring conditions that can
  help people with autism function better. For example, medication might
  help manage inability to focus, or self-harming behavior, such as head
  banging or hand biting. Medication can also help manage co-occurring
  psychological conditions, such as anxiety or depression, in addition to
  medical conditions such as seizures, sleep problems, or other
  gastrointestinal problems.</p>
 
</div>

</mat-expansion-panel>

<p>
  There are other therapies available which may not address core areas of communication and social understanding, but these therapies appear to help by creating an overall sense of well-being, such as yoga, animal therapy, music therapy, art therapy, and so on. 
</p>

</div>
